import React from "react";
import { User } from "types";
import { Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

export const SupportUserInfo = ({
  user,
  supportUser,
}: SupportUserInfoProps) => {
  const logoutFromUser = () => {
    localStorage.removeItem("support-user-token");
    localStorage.removeItem("user");
    localStorage.removeItem("supportUser");
    window.location.reload();
  };

  return (
    <Alert severity="info">
      Support User - {supportUser.email}, Logged in as - {user.email} (
      {user.currentOrganization.name})
      <Button
        variant="outlined"
        style={{ marginLeft: 30 }}
        onClick={logoutFromUser}
      >
        Logout from Customer
      </Button>
    </Alert>
  );
};

interface SupportUserInfoProps {
  user: User;
  supportUser: User;
}
