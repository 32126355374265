import React, { useEffect, useState } from "react";
import { AllTagsForMetrics } from "types";
import { formatFilter } from "utils";
import { ExplorerSelector } from "./ExplorerSelectorComponent";

export const FilterSelectorComponent = ({
  allTagsForMetrics,
  handleFilterSelections,
  loading,
  selectedFilters,
}: FilterSelectorComponentProps) => {
  const [checkboxSelections, setCheckboxSelections] = useState<string[]>([]);

  useEffect(() => {
    handleFilterSelections("filters", checkboxSelections);
  }, [JSON.stringify(checkboxSelections)]);

  useEffect(() => {
    if (selectedFilters.length > 0) {
      setCheckboxSelections(selectedFilters.split(","));
    } else {
      setCheckboxSelections([]);
    }
  }, [selectedFilters]);

  const handleChange = (event) => {
    if (event.target.checked) {
      setCheckboxSelections([...checkboxSelections, event.target.value]);
    } else {
      const newSelections = checkboxSelections.filter(
        (elem) => elem !== event.target.value
      );
      setCheckboxSelections(newSelections);
    }
  };

  const keys = Object.keys(allTagsForMetrics.tags);
  const filters: string[] = [];
  keys.forEach((key) => {
    const vals = allTagsForMetrics.tags[key];
    vals.forEach((val) => {
      filters.push(`${key}:${val}`);
    });
  });
  checkboxSelections.forEach((sel) => {
    for (let i = 0; i < filters.length; i += 1) {
      if (filters[i] === sel) {
        break;
      } else if (filters[i] > sel) {
        filters.splice(i, 0, sel);
        break;
      }
    }
  });

  const groups = {};
  filters.forEach((filter) => {
    const key = formatFilter(filter.split(":")[0]);
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(filter);
  });

  return (
    <ExplorerSelector
      title="Filters"
      checkboxSelections={checkboxSelections}
      handleChange={handleChange}
      groups={groups}
      formatter={(key) => formatFilter(key.split(":")[1])}
      chipFormatter={formatFilter}
      loading={loading}
      isCheckbox
      explorerViewSelection=""
    />
  );
};

interface FilterSelectorComponentProps {
  allTagsForMetrics: AllTagsForMetrics;
  handleFilterSelections: (key: string, filters: string[]) => void;
  loading: boolean;
  selectedFilters: string;
}
