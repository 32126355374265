import React, { useState } from "react";
import { TextField, Button, Container } from "@material-ui/core";
import { connect } from "react-redux";
import { OnboardIntegrationButton } from "components/atoms/buttons/OnboardIntegrationButton";
import { selectOrganization } from "store/auth/selectors";
import { Organization } from "types";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const KlaviyoFormBase = ({
  callback,
  isOnboarding,
  disabled,
  organization,
}: KlaviyoFormBaseProps) => {
  const [accountName, setAccountName] = useState(organization.name);
  const [apiKey, setApiKey] = useState("");
  const handleSubmit = () => {
    const response = { apiKey, accountName };
    callback(response);
  };

  return (
    <Container
      style={{
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        paddingLeft: "0px",
        paddingRight: "0px",
      }}
    >
      <TextField
        required
        margin="dense"
        id="account_name"
        label="Account Name"
        helperText="Change if multiple Klaviyo accounts"
        type="standard"
        size="small"
        onChange={({ target: { value } }) => setAccountName(value)}
        value={accountName}
        style={{ marginRight: 10 }}
      />
      <TextField
        required
        margin="dense"
        id="api_key"
        label="Api Key"
        helperText={
          <a
            href="https://www.klaviyo.com/create-private-api-key"
            target="_blank"
            rel="noreferrer"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              Create Read-Only API Key
              <OpenInNewIcon
                style={{
                  width: 12,
                  height: 12,
                  marginLeft: "2px",
                }}
              />
            </div>
          </a>
        }
        type="password"
        size="small"
        onChange={({ target: { value } }) => setApiKey(value)}
        value={apiKey}
        style={{ marginRight: 10 }}
      />
      {!isOnboarding ? (
        <Button
          onClick={handleSubmit}
          // disabled={storeName === ""}
          variant="text"
          // style={
          //   storeName !== ""
          //     ? { color: "#177EC2", fontWeight: 400, display: "flex" }
          //     : { display: "flex" }
          // }
        >
          Connect
        </Button>
      ) : (
        <OnboardIntegrationButton
          onClick={handleSubmit}
          disabled={disabled || accountName === "" || apiKey === ""}
        />
      )}
    </Container>
  );
};

interface KlaviyoFormBaseProps {
  callback: (response: any) => void;
  isOnboarding?: boolean;
  disabled?: boolean;
  organization: Organization;
}

const mapStateToProps = (state) => ({
  organization: selectOrganization(state),
});

const mapDispatchToProps = {};

export const KlaviyoForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(KlaviyoFormBase);
