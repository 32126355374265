import React from "react";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { getTimeBreakdownOptions } from "utils";

const styles = makeStyles(() => ({
  toggleButton: {
    height: "44px",
    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: "#E3E3E3 !important",
      color: "#27282C",
    },
    "& .MuiToggleButton-root": {
      backgroundColor: "#FFFFFF",
      borderColor: "#E3E3E3 !important",
      color: "#656576",
      fontWeight: 500,
      fontFamily: "Work Sans",
      fontSize: "14px",
      fontStyle: "normal",
      letterSpacing: "-0.02em",
    },
  },
}));

export const TimeBreakdownSelectorComponent = ({
  timeframe,
  customStartDate,
  customEndDate,
  timeSelection,
  handleChange,
  metricSelected,
}: TimeBreakdownSelectorComponentProps) => {
  const classes = styles();

  const timeBreakdownOptions = getTimeBreakdownOptions(
    timeframe,
    customStartDate,
    customEndDate
  );
  const toggleButtons = [] as any;
  timeBreakdownOptions.forEach((option) => {
    toggleButtons.push(
      <ToggleButton
        value={option.value}
        key={option.value}
        style={{ textTransform: "none", width: "75px" }}
      >
        {option.label}
      </ToggleButton>
    );
  });

  return (
    <ToggleButtonGroup
      size="small"
      color="primary"
      value={timeSelection}
      exclusive
      onChange={handleChange}
      className={classes.toggleButton}
      disabled={!metricSelected}
    >
      {toggleButtons}
    </ToggleButtonGroup>
  );
};

interface TimeBreakdownSelectorComponentProps {
  // need span of time selection
  timeframe: any;
  customStartDate: any;
  customEndDate: any;
  timeSelection: string;
  handleChange: (
    event: React.MouseEvent<HTMLElement>,
    breakdown: string
  ) => void;
  metricSelected: boolean;
}
