import React from "react";
import { SVGS } from "utils";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export interface SvgProps {
  size: number;
  name: string;
  style?: object;
  onClick?: () => void;
}

export const SvgIcon = ({ name, size, style, onClick }: SvgProps) => {
  const getSrc = (key) => {
    switch (key) {
      case "amazon_sp":
      case "amazon_advertising":
      case "amazon":
        return SVGS.Amazon;
      case "calendar":
        return SVGS.Calendar;
      case "checkmark":
        return SVGS.Checkmark;
      case "clock":
        return SVGS.Clock;
      case "close":
        return SVGS.Close;
      case "email":
        return SVGS.Email;
      case "facebook":
        return SVGS.Facebook;
      case "google":
      case "google_ads":
        return SVGS.Google;
      case "google_analytics":
        return SVGS.GoogleAnalytics;
      case "instagram":
        return SVGS.Instagram;
      case "integration":
        return SVGS.Integration;
      case "klaviyo":
        return SVGS.Klaviyo;
      case "live":
        return SVGS.Live;
      case "more":
        return SVGS.More;
      case "shopify":
        return SVGS.Shopify;
      case "slack":
        return SVGS.Slack;
      case "tiktok":
        return SVGS.TikTok;
      case "woocommerce":
        return SVGS.WooCommerce;
      case "thick":
        return SVGS.Thick;
      case "thin":
        return SVGS.Thin;
      case "TrackstarLogoHero":
        return SVGS.TrackstarLogoHero;
      case "TrackstarLogoInverse":
        return SVGS.TrackstarLogoInverse;
      case "recharge":
        return SVGS.Recharge;
      default:
        return SVGS.TrackstarLogoHero;
    }
  };
  return (
    <Container onClick={onClick} style={{ justifyContent: "flex-start" }}>
      <img
        src={getSrc(name)}
        width={size}
        height={size}
        alt={SVGS.TrackstarLogoHero}
        style={style}
      />
    </Container>
  );
};
