import { OnboardIntegrationButton } from "components/atoms/buttons/OnboardIntegrationButton";
import React from "react";
import { useGoogleLogin } from "@react-oauth/google";

export const GoogleLogin = ({ disabled, onSuccess }: GoogleLoginProps) => {
  const handleClick = useGoogleLogin({
    onSuccess: (response) => {
      onSuccess(response);
    },
    onError: (err) => console.log("fail", err),
    scope:
      "https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/analytics.readonly",
    flow: "auth-code",
  });

  return (
    <OnboardIntegrationButton
      onClick={() => handleClick()}
      disabled={disabled}
    />
  );
};

interface GoogleLoginProps {
  onSuccess: (response: any) => void;
  disabled?: boolean;
}
