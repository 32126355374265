import React from "react";
import {
  CustomContainer,
  AmazonOAuth,
  ShopifyForm,
  KlaviyoForm,
  SlackLogin,
  AmazonAdvertisingLogin,
  TikTokLogin,
  WooCommerceForm,
} from "components";
import { InstallIntegrationRequestParams } from "types";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { OnboardIntegrationButton } from "components/atoms/buttons/OnboardIntegrationButton";
import { RechargeForm } from "components/containers/RechargeForm";
import { GoogleLogin } from "components/containers/GoogleLogin";

interface IntegrationButtonProps {
  name: string;
  onInstallIntegration: (params: InstallIntegrationRequestParams) => void;
  isOnboarding?: boolean;
  disabled?: boolean;
}
export const IntegrationButton = ({
  name,
  onInstallIntegration,
  isOnboarding,
  disabled,
}: IntegrationButtonProps) => {
  const responseGoogle = (response) => {
    onInstallIntegration({
      data: {
        integration_name: "google",
        payload: {
          code: response.code,
        },
      },
    });
  };

  const responseFacebook = (response) => {
    console.log("responseFacebook", response);
    onInstallIntegration({
      data: {
        integration_name: "facebook",
        payload: {
          access_token: response.accessToken,
          user_id: response.userID,
          user_email: response.email,
        },
      },
    });
  };

  const responseShopifySuccess = (response) => {
    onInstallIntegration({
      data: {
        integration_name: "shopify",
        payload: {
          store_name: response.shop,
          code: response.authCode,
          qs: response.qs,
        },
      },
    });
  };

  const responseShopifyFailure = (response) => {
    console.log(response);
  };

  const responseKlaviyo = (response) => {
    onInstallIntegration({
      data: {
        integration_name: "klaviyo",
        payload: {
          account_name: response.accountName,
          api_key: response.apiKey,
        },
      },
    });
  };

  const responseSlackSuccess = (code) => {
    onInstallIntegration({
      data: {
        integration_name: "slack",
        payload: { code },
      },
    });
  };

  const responseSlackFailure = (response) => {
    console.log(response);
  };

  const responseAmazonAdvertisingSuccess = (code) => {
    onInstallIntegration({
      data: {
        integration_name: "amazon_advertising",
        payload: { code },
      },
    });
  };

  const responseAmazonAdvertisingFailure = (response) => {
    console.log(response);
  };

  const responseAmazonSPSuccess = (response) => {
    onInstallIntegration({
      data: {
        integration_name: "amazon_sp",
        payload: {
          spapi_oauth_code: response.authCode,
          state: response.state,
          selling_partner_id: response.sellingPartnerId,
        },
      },
    });
  };

  const responseAmazonSPFailure = (response) => {
    console.log(response);
  };

  const responseTikTokSuccess = (code) => {
    onInstallIntegration({
      data: {
        integration_name: "tiktok",
        payload: { code },
      },
    });
  };

  const responseTikTokFailure = (response) => {
    console.log(response);
  };

  const responseRecharge = (response) => {
    onInstallIntegration({
      data: {
        integration_name: "recharge",
        payload: {
          api_key: response.apiKey,
        },
      },
    });
  };

  const appId = process.env.REACT_APP_FACEBOOK_APP_ID;
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const getButton = (value) => {
    switch (value) {
      case "amazon_advertising":
        return (
          <AmazonAdvertisingLogin
            onSuccess={responseAmazonAdvertisingSuccess}
            onFailure={responseAmazonAdvertisingFailure}
            disabled={disabled}
          />
        );
      case "amazon_sp":
        return (
          <AmazonOAuth
            onSuccess={responseAmazonSPSuccess}
            onFailure={responseAmazonSPFailure}
            disabled={disabled}
          />
        );
      case "facebook":
        return (
          <FacebookLogin
            appId={appId}
            autoLoad={false}
            fields="name,email"
            version="14.0"
            scope="ads_read,email,public_profile,business_management,ads_management"
            callback={responseFacebook}
            render={(renderProps) => (
              // add isOnboarding check here
              <OnboardIntegrationButton
                onClick={renderProps.onClick}
                disabled={disabled}
              />
            )}
          />
        );
      case "google":
      case "google_ads":
      case "google_analytics":
        return (
          <GoogleOAuthProvider clientId={clientId}>
            <GoogleLogin onSuccess={responseGoogle} disabled={disabled} />
          </GoogleOAuthProvider>
        );
      case "klaviyo":
        return (
          <KlaviyoForm
            callback={responseKlaviyo}
            isOnboarding={isOnboarding}
            disabled={disabled}
          />
        );
      case "slack":
        return (
          <SlackLogin
            onSuccess={responseSlackSuccess}
            onFailure={responseSlackFailure}
            isOnboarding={isOnboarding}
            disabled={disabled}
          />
        );
      case "shopify":
        return (
          <ShopifyForm
            onSuccess={responseShopifySuccess}
            onFailure={responseShopifyFailure}
            isOnboarding={isOnboarding}
            disabled={disabled}
          />
        );
      case "tiktok":
        return (
          <TikTokLogin
            onSuccess={responseTikTokSuccess}
            onFailure={responseTikTokFailure}
            disabled={disabled}
          />
        );
      case "woocommerce":
        return <WooCommerceForm />;
      case "recharge":
        return (
          <RechargeForm
            callback={responseRecharge}
            isOnboarding={isOnboarding}
            disabled={disabled}
          />
        );
      default:
        return "";
    }
  };

  return <CustomContainer>{getButton(name)}</CustomContainer>;
};
