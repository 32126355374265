import React, { useEffect, useState } from "react";
import { AllTagsForMetrics } from "types";
import { formatMetricName } from "utils";
import { ExplorerSelector } from "./ExplorerSelectorComponent";

export const AttributeSelectorComponent = ({
  allTagsForMetrics,
  handleAttributeSelections,
  loading,
  selectedAttributes,
}: AttributeSelectorComponentProps) => {
  const [checkboxSelections, setCheckboxSelections] = useState<string[]>([]);

  useEffect(() => {
    handleAttributeSelections("attributes", checkboxSelections);
  }, [JSON.stringify(checkboxSelections)]);

  useEffect(() => {
    if (selectedAttributes.length > 0) {
      setCheckboxSelections(selectedAttributes.split(","));
    } else {
      setCheckboxSelections([]);
    }
  }, [selectedAttributes]);

  const handleChange = (event) => {
    if (event.target.checked) {
      setCheckboxSelections([...checkboxSelections, event.target.value]);
    } else {
      const newSelections = checkboxSelections.filter(
        (elem) => elem !== event.target.value
      );
      setCheckboxSelections(newSelections);
    }
  };
  const keys = Object.keys(allTagsForMetrics.tags);
  checkboxSelections.forEach((attribute) => {
    for (let i = 0; i < keys.length; i += 1) {
      if (keys[i] === attribute) {
        break;
      } else if (keys[i] > attribute) {
        keys.splice(i, 0, attribute);
        break;
      }
    }
  });
  const groups = keys.length !== 0 ? { all: keys } : {};

  return (
    <ExplorerSelector
      title="Attributes"
      checkboxSelections={checkboxSelections}
      handleChange={handleChange}
      groups={groups}
      formatter={formatMetricName}
      loading={loading}
      isCheckbox
      explorerViewSelection=""
    />
  );
};

interface AttributeSelectorComponentProps {
  allTagsForMetrics: AllTagsForMetrics;
  handleAttributeSelections: (key: string, attributes: string[]) => void;
  loading: boolean;
  selectedAttributes: string;
}
