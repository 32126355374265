import React, { useState } from "react";
import { TextField, Button, Container } from "@material-ui/core";
import { connect } from "react-redux";
import { OnboardIntegrationButton } from "components/atoms/buttons/OnboardIntegrationButton";

const RechargeFormBase = ({
  callback,
  isOnboarding,
  disabled,
}: RechargeFormBaseProps) => {
  const [apiKey, setApiKey] = useState("");
  const handleSubmit = () => {
    const response = { apiKey };
    callback(response);
  };

  return (
    <Container
      style={{
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        paddingLeft: "0px",
        paddingRight: "0px",
      }}
    >
      <TextField
        required
        margin="dense"
        id="api_key"
        label="Api Key"
        helperText="Apps > API Tokens > Create API Token > Read Access"
        type="password"
        size="small"
        onChange={({ target: { value } }) => setApiKey(value)}
        value={apiKey}
        style={{ marginRight: 10 }}
      />
      {!isOnboarding ? (
        <Button onClick={handleSubmit} variant="text">
          Connect
        </Button>
      ) : (
        <OnboardIntegrationButton
          onClick={handleSubmit}
          disabled={disabled || apiKey === ""}
        />
      )}
    </Container>
  );
};

interface RechargeFormBaseProps {
  callback: (response: any) => void;
  isOnboarding?: boolean;
  disabled?: boolean;
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export const RechargeForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(RechargeFormBase);
