import { defaultDict } from "../utils";
export const OAUTH_AMAZON_SP = "oauth_amazon_sp";

export const DOLLAR_SIGN_PREFIX_LIST = [
  "advertising.spend",
  "advertising.conversions.amount",
  "advertising.blended_cac",
  "advertising.cpc",
  "orders.gross_sales",
  "orders.net_sales",
  "orders.new_customers.gross_sales",
  "orders.recurring_customers.gross_sales",
  "orders.cancelled.amount",
  "orders.discounts.amount",
  "orders.refunds.amount",
  "orders.aov",
  "marketing.email_conversions.amount",
  "site.abandoned_checkouts.amount",
];

export const PERCENT_METRICS = [
  "advertising.ctr",
  "marketing.email_click_rate",
  "marketing.email_open_rate",
  "orders.refund_rate",
  "orders.discount_rate",
  "site.abandoned_checkout_rate",
  "site.conversion_rate",
];

export const INTEGRATIONS = [
  "shopify",
  "amazon_sp",
  "google",
  "google_analytics",
  "slack",
  "amazon_advertising",
  "facebook",
  "klaviyo",
  "tiktok",
  "woocommerce",
  "recharge",
];

export const INTEGRATION_METRIC_CATEGORIES = {
  advertising: ["amazon_advertising", "google_ads", "facebook", "tiktok"],
  inventory: ["shopify", "amazon_sp", "woocommerce"],
  orders: ["shopify", "amazon_sp", "woocommerce", "recharge"],
  marketing: ["klaviyo"],
  site: ["google_analytics"],
  store_operations: ["shopify", "amazon_sp", "woocommerce", "recharge"],
};

export const ISSUE_CATEGORIES = [
  "advertising",
  "inventory",
  "storefront",
  "marketing",
];

export const INTEGRATION_ISSUE_TYPE_MAP = {
  shopify: [
    "inventory_out_of_stock",
    "low_inventory",
    "leaked_discount_code",
    "performance_issue",
    "shopify_checkout_flow_failed",
  ],
  amazon_sp: [
    "inventory_out_of_stock",
    "low_inventory",
    "account_not_ok",
    "amazon_listing_not_ok",
    "buy_box_lost",
  ],
  google: [
    "account_not_ok",
    "advertising_issue",
    "ad_broken_link",
    "ad_for_out_of_stock_product",
  ],
  amazon_advertising: ["account_not_ok", "advertising_issue", "ad_broken_link"],
  facebook: [
    "account_not_ok",
    "advertising_issue",
    "ad_broken_link",
    "ad_for_out_of_stock_product",
  ],
  tiktok: [
    "account_not_ok",
    "advertising_issue",
    "ad_broken_link",
    "ad_for_out_of_stock_product",
  ],
  woocommerce: ["inventory_out_of_stock", "low_inventory"],
  klaviyo: ["email_broken_link", "no_emails_sent"],
  recharge: ["charge_error"],
  google_analytics: ["broken_landing_page"],
};

const ISSUE_TYPE_DESCRIPTION_VALUES = {
  inventory_out_of_stock: {
    title: "Out of Stock Product",
    description: "A SKU has run out of inventory",
    projected_impact_calculation:
      "Equals weekly sales for the SKU or ASIN based on the weekly average sales over the last month",
    editable: false,
  },
  low_inventory: {
    title: "Low Inventory for Product",
    description:
      "A SKU has less than some number of days of inventory remaining (default 60)",
    projected_impact_calculation:
      "Equals weekly sales for the SKU or ASIN based on the weekly average sales over the last month",
    editable: true,
    custom_threshold_type: "days_remaining_threshold",
    custom_threshold_description: [
      "Create an issue when a product has fewer than or equal to",
      "days remaining",
    ],
    custom_threshold_default_value: "60",
    custom_threshold_default_description: "60 days",
  },
  advertising_issue: {
    title: "Advertising Issue",
    description:
      "A campaign, adgroup, or ad is not running properly or the ad platform prevented it from running",
    projected_impact_calculation:
      "Equals the greater of: 1) average weekly spend over the last month, and 2) average weekly conversion $s over the last month",
    editable: false,
  },
  account_not_ok: {
    title: "Account Configuration Issue",
    description:
      "An account has a settings or billing issue that is preventing the account from working properly",
    projected_impact_calculation:
      "Equals weekly average spend / sales / conversions over the last month depending on the account type",
    editable: false,
  },
  ad_broken_link: {
    title: "Ad Directing to a Broken Landing Page",
    description: "A running ad is directing users to a broken link/broken URL",
    projected_impact_calculation:
      "Equals the greater of: 1) average weekly ad spend over the last month, and 2) average weekly ad conversion $s over the last month",
    editable: false,
  },
  ad_for_out_of_stock_product: {
    title: "Ad Directing to Out of Stock Product",
    description:
      "A running ad is directing customers to a product that is out of stock",
    projected_impact_calculation:
      "Equals the average weekly ad spend over the last month",
    editable: false,
  },
  amazon_listing_not_ok: {
    title: "Amazon Listing Issue",
    description:
      "Amazon has prevented users from purchasing and/or discovering your product on their site",
    projected_impact_calculation:
      "Equals the average weekly sales for the Amazon ASIN over the last month",
    editable: false,
  },
  broken_landing_page: {
    title: "Broken Landing Page",
    description: "Users are being directed to a broken link/broken URL",
    projected_impact_calculation:
      "Equals 1% (assumed conversion rate) times AOV times sessions over the last month",
    editable: false,
  },
  buy_box_lost: {
    title: "Amazon Buy Box Lost",
    description: "An Amazon listing has lost the Buy Box",
    projected_impact_calculation:
      "Equals the average weekly sales for the Amazon ASIN over the last month",
    editable: false,
  },
  email_broken_link: {
    title: "Scheduled Email Contains a Broken Link",
    description: "An email scheduled to be sent contains a broken link/URL",
    projected_impact_calculation:
      "Equals the average $s of revenue per email sent multiplied by the number of recipients for the scheduled email",
    editable: false,
  },
  no_emails_sent: {
    title: "No Email Campaigns Sent",
    description: "No email campaigns have been sent within the last 7 days",
    projected_impact_calculation:
      "Equals the average $s of revenue per email sent multiplied by average number of emails sent per week",
    editable: false,
  },
  shopify_checkout_flow_failed: {
    title: "Shopify Checkout Flow Broken",
    description:
      "Customers are unable to add items to their cart or initiate the checkout process on your Shopify store",
    projected_impact_calculation:
      "Equals the average weekly sales for the SKU over the last month",
    editable: false,
  },
  leaked_discount_code: {
    title: "Discount Code Leaked",
    description:
      "An active discount code has been found on a third party service (Honey, RetailMeNot, or Cap1 Shopping)",
    projected_impact_calculation:
      "Equals the average weekly amount of money saved by customers using the code over the last month",
    editable: true,
    custom_threshold_type: "leaked_discount_code_percentage_threshold",
    custom_threshold_description: [
      "Create an issue when a code greater than or equal to",
      "% leaks",
    ],
    custom_threshold_default_value: "15",
    custom_threshold_default_description: "15%",
  },
  performance_issue: {
    title: "Site Performance Issue",
    description:
      "Your site is not optimized for speed and is causing customers to abandon their carts",
    projected_impact_calculation:
      "Equals the weekly sales multiplied by a 2% drop in conversion for every additional second of load time",
    editable: false,
  },
  charge_error: {
    title: "Recharge Charge Error",
    description:
      "A charge error has occurred in the Recharge subscription service",
    projected_impact_calculation:
      "Equals the average weekly cost of the subscription with the charge error",
    editable: false,
  },
};

const DEFAULT_ISSUE_TYPE = {
  title: "Other",
  description: "Other Issue Type",
  projected_impact_calculation: "N/A",
  editable: false,
};

export const ISSUE_TYPE_DESCRIPTION = defaultDict(
  ISSUE_TYPE_DESCRIPTION_VALUES,
  DEFAULT_ISSUE_TYPE
);

export const ACRONYMS = [
  "id",
  "cac",
  "ctr",
  "roas",
  "cpc",
  "cpm",
  "aov",
  "asin",
  "sku",
  "mer",
];

export const TIMEFRAMES = [
  { key: 1, label: "Today", value: "curr_1d" },
  { key: 2, label: "Yesterday", value: "yd" },
  { key: 3, label: "Week to Date", value: "curr_1w" },
  { key: 4, label: "Month to Date", value: "curr_1mo" },
  { key: 5, label: "Year to Date", value: "curr_1y" },
  { key: 6, label: "Last 24 hours", value: "-1d" },
  { key: 7, label: "Last 7 Days", value: "-1w" },
  { key: 8, label: "Last 30 Days", value: "-1mo" },
  { key: 9, label: "Last 12 Months", value: "-1y" },
  { key: 10, label: "Custom", value: "custom" },
];

export const NON_SUMMABLE_METRICS = [
  "inventory.days_remaining",
  "inventory.daily_inventory_sell_through",
];
