import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Grid,
  makeStyles,
  Typography,
  Container,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { StyledTooltip, Spinner } from "components";
import { IssueType, User } from "types";
import { ISSUE_TYPE_DESCRIPTION } from "constants/index";
import { toggleDismissIssue, updateUserActions } from "api";
import { selectSupportUser, selectUser } from "store/auth/selectors";
import { connect } from "react-redux";
import { selectNotifications } from "store/app/selectors";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { SvgIcon } from "../../components/atoms/commons/SvgIcon";
import { IssueSidebarCard } from "./IssueSidebarCard";

const useStyles = makeStyles(() => ({
  cardList: {
    width: 700,
    paddingTop: "16px",
  },
}));

export const IssuesSidebarBase = ({
  loading,
  issues,
  issueFilter,
  availableFilters,
  pivotSelection,
  setDismissIssue,
  setSidebarClosed,
  supportUser,
}: IssuesSidebarComponentProps) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [filteredIssues, setFilteredIssues] = useState<IssueType[]>([]);
  const [sortCondition, setSortCondition] = useState("");
  const sidePanelTitle =
    issueFilter in ISSUE_TYPE_DESCRIPTION
      ? ISSUE_TYPE_DESCRIPTION[issueFilter].title
      : pivotSelection === "Category"
      ? "Category".concat(
          ": ",
          issueFilter.charAt(0).toUpperCase() + issueFilter.slice(1)
        )
      : "Integration".concat(
          ": ",
          issueFilter
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
        );

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
    setSidebarClosed(!open);
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    if (issueFilter !== "") {
      setIsOpen(true);
      setSidebarClosed(false);
    } else {
      setIsOpen(false);
      setSidebarClosed(true);
    }
    let issueKey = "issue_type";
    if (pivotSelection === "Category") {
      issueKey = "category";
    } else if (pivotSelection === "Integration") {
      issueKey = "platform";
    }
    let issuesToSet = issues.filter(
      (issue) =>
        (issue[issueKey].toLowerCase() === issueFilter.toLowerCase() ||
          (issue[issueKey].toLowerCase() === "instagram" &&
            issueFilter.toLowerCase() === "facebook")) &&
        issue.projected_daily_impact > 0
    );
    if (pivotSelection === "Integration") {
      if (issueFilter === "Other") {
        issuesToSet = issues.filter(
          (issue) =>
            !availableFilters.includes(issue[issueKey].toLowerCase()) &&
            !(issue[issueKey].toLowerCase() === "instagram")
        );
      }
    }
    setFilteredIssues(issuesToSet);
  }, [issueFilter, loading]);

  const totalImpact =
    7 *
    filteredIssues.reduce(
      (acc, issue) => acc + issue.projected_daily_impact,
      0
    );

  const sendUserActions = (issue_ids: string[]) => {
    const params = { issue_ids };
    if (!supportUser) {
      updateUserActions(params);
    }
  };

  const sortConditions = [
    { value: "impact high", label: "Impact (High to Low)" },
    { value: "impact low", label: "Impact (Low to High)" },
    { value: "time recent", label: "Time (Most Recent First)" },
    { value: "time old", label: "Time (Oldest First)" },
  ];
  const sortFn = (a, b) => {
    if (sortCondition === "time recent") {
      return (
        new Date(b.triggered_at).getTime() - new Date(a.triggered_at).getTime()
      );
    }
    if (sortCondition === "time old") {
      return (
        new Date(a.triggered_at).getTime() - new Date(b.triggered_at).getTime()
      );
    }
    if (sortCondition === "impact low") {
      return a.projected_daily_impact - b.projected_daily_impact;
    }
    // sortCondition === "impact high" or not specified
    return b.projected_daily_impact - a.projected_daily_impact;
  };

  const tooltip = () => {
    if (pivotSelection !== "Issue Type") {
      return;
    }
    const title =
      issueFilter in ISSUE_TYPE_DESCRIPTION
        ? ISSUE_TYPE_DESCRIPTION[issueFilter].projected_impact_calculation
        : "";
    return (
      <StyledTooltip title={title} placement="top">
        <div
          style={{
            cursor: "pointer",
            height: "16px",
          }}
        >
          <InfoOutlinedIcon sx={{ fontSize: "medium" }} />
        </div>
      </StyledTooltip>
    );
  };

  const header = () => (
    <Container style={{ marginTop: 20 }}>
      <Grid container direction="row">
        <Grid item xs={4}>
          <Typography variant="h6">{filteredIssues.length}</Typography>
          <Typography> Unresolved Issues</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">
            ${totalImpact.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} /
            week
          </Typography>
          <Container
            style={{
              display: "flex",
              alignItems: "center",
              padding: 0,
            }}
          >
            <Typography> Total Projected Impact</Typography>
            {tooltip()}
          </Container>
        </Grid>
        <Grid item xs={4}>
          <TextField
            select
            variant="outlined"
            label="Sort By"
            style={{ height: 40, width: "100%" }}
            onChange={(e) => {
              if (typeof e.target.value === "string") {
                setSortCondition(e.target.value);
              }
            }}
            value={sortCondition}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                getContentAnchorEl: null,
                transformOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                PaperProps: {
                  style: {
                    border: "1px solid #c4c4c4",
                  },
                },
              },
            }}
          >
            {sortConditions.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Container>
  );

  const dismissIssue = (issueId: string) => {
    const params = { id: issueId };
    toggleDismissIssue(params);
    setFilteredIssues(filteredIssues.filter((issue) => issue.id !== params.id));
    setDismissIssue(true);
  };

  const list = () => (
    <Container
      className={classes.cardList}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "0 20px 0px 20px",
      }}
    >
      {filteredIssues
        .sort((a, b) => sortFn(a, b))
        .map((issue) => (
          <IssueSidebarCard
            key={issue.id}
            issue={issue}
            dismissIssue={dismissIssue}
            sendUserActions={sendUserActions}
          />
        ))}
    </Container>
  );

  return (
    <div>
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
        <Container style={{ marginTop: 24 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography style={{ fontSize: 24, fontWeight: 500 }}>
                {sidePanelTitle}
              </Typography>
            </Grid>
            <Grid item>
              <Button onClick={toggleDrawer(false)}>
                <SvgIcon name="close" size={17.5} />
              </Button>
            </Grid>
          </Grid>
        </Container>
        {loading ? (
          <Spinner center />
        ) : (
          <>
            {header()}
            <Divider style={{ marginBottom: "20px", marginTop: "20px" }} />
            {list()}
          </>
        )}
      </Drawer>
    </div>
  );
};

interface IssuesSidebarComponentProps {
  loading: boolean;
  issues: IssueType[];
  issueFilter: string;
  availableFilters: string[];
  pivotSelection: string;
  setDismissIssue: (issue: any) => void;
  setSidebarClosed: (closed: boolean) => void;
  supportUser?: User;
}

const mapStateToProps = (state) => ({
  user: selectUser(state),
  notifications: selectNotifications(state),
  supportUser: selectSupportUser(state),
});

export const IssuesSidebar = connect(mapStateToProps)(IssuesSidebarBase);
