import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Container,
  Grid,
} from "@material-ui/core";
import { Button } from "components/atoms";

export function DeleteIntegrationModal({
  open,
  accounts,
  selectedAccounts,
  setSelectedAccounts,
  handleCancel,
  handleClose,
}: DeleteIntegrationModalProps) {
  const groups = {};
  accounts.forEach((account) => {
    if (!groups[account.user_id]) {
      groups[account.user_id] = [];
    }
    groups[account.user_id].push(account);
  });
  const keys = Object.keys(groups);
  const checked = (key) => selectedAccounts.includes(key);
  const handleChange = (event) => {
    const gotChecked = event.target.checked;
    const key = event.target.name;
    if (gotChecked) {
      setSelectedAccounts([...selectedAccounts, key]);
    }
    if (!checked) {
      setSelectedAccounts(selectedAccounts.filter((item) => item !== key));
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
        fullWidth
        style={{ minHeight: "400px" }}
      >
        <DialogTitle id="form-dialog-title">Uninstall Accounts</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              {keys.map((key) => (
                <Container
                  key={key}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 0,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked(key)}
                        onChange={handleChange}
                        name={key}
                      />
                    }
                    label={"User ID: ".concat(key)}
                  />
                  <Container>
                    <Grid container spacing={1}>
                      {groups[key].map((account) => (
                        <Grid item xs={12} key={account.account_name}>
                          {account.account_name} (
                          {account.enabled ? "Enabled" : "Disabled"})
                        </Grid>
                      ))}
                    </Grid>
                  </Container>
                </Container>
              ))}
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button destructive onClick={handleClose} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

interface DeleteIntegrationModalProps {
  open: boolean;
  accounts: any[];
  selectedAccounts: any[];
  setSelectedAccounts: (accounts: any[]) => void;
  handleCancel: () => void;
  handleClose: () => void;
}
