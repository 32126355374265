import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { Spinner } from "components";
import { HeaderBoxTitle } from "./HeaderBoxTitle";
import { HeaderBoxContent } from "./HeaderBoxContent";

const useStyles = makeStyles(() => ({
  headerBox: {
    height: "110px",
    borderRadius: 5,
    border: "1px solid #E3E3E3",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    flexShrink: 1.25,
  },
  headerBoxContents: {
    flexShrink: 1.4,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "20px",
    height: "100%",
    paddingRight: "15px",
    paddingLeft: "15px",
    justifyContent: "center",
    marginTop: "10px",
    // needed to prevent header title moving when spinner is shown
    maxHeight: "25px",
  },
}));

interface IssueHeaderPaneProps {
  title: string;
  loading: boolean;
  value: string;
  changeValue?: number;
  changeString?: string;
  tooltipText?: string;
  headerSuffix?: string;
  valueSuffix?: string;
  negativeChangeGood?: boolean;
}

export const IssueHeaderPane = ({
  loading,
  title,
  value,
  valueSuffix,
  tooltipText,
  headerSuffix,
  changeValue,
  changeString,
  negativeChangeGood,
}: IssueHeaderPaneProps) => {
  const classes = useStyles();
  let goodChange = true;

  if (
    (negativeChangeGood && changeValue && changeValue > 0) ||
    (!negativeChangeGood && changeValue && changeValue < 0)
  ) {
    goodChange = false;
  }

  return (
    <Container className={classes.headerBoxContents}>
      {loading ? (
        <Spinner center />
      ) : (
        <HeaderBoxContent
          value={value}
          valueSuffix={valueSuffix}
          changeValue={changeValue}
          changeString={changeString}
          goodChange={goodChange}
        />
      )}
      <HeaderBoxTitle
        title={title}
        titleSuffix={headerSuffix}
        tooltipText={tooltipText}
      />
    </Container>
  );
};
