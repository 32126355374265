import React from "react";
import { connect } from "react-redux";
import { ExplorerViewType, DeleteExplorerViewRequestParams } from "types";
import { deleteExplorerView } from "store/app/actions";
import { ExplorerSelector } from "./ExplorerSelectorComponent";

const SavedViewSelectorComponent = ({
  explorerViews,
  handleExplorerViewSelection,
  onDeleteExplorerView,
  selectedView,
  setSelectedView,
  loading,
}: SavedViewSelectorComponentProps) => {
  const handleChange = (id) => {
    if (id !== selectedView) {
      handleExplorerViewSelection(id);
      setSelectedView(id);
    }
  };
  const handleDelete = (id: string) => {
    onDeleteExplorerView({ id });
  };

  const groups = explorerViews.length !== 0 ? { all: explorerViews } : {};
  // sort saved views alphabetically
  if (groups.all) {
    const sortedGroups = [...groups.all];
    groups.all = sortedGroups.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  return (
    <ExplorerSelector
      title="Views"
      checkboxSelections={[]}
      handleChange={handleChange}
      handleDelete={handleDelete}
      groups={groups}
      formatter={(str) => str}
      loading={loading}
      isCheckbox={false}
      explorerViewSelection={selectedView}
    />
  );
};

interface SavedViewSelectorComponentProps {
  explorerViews: ExplorerViewType[];
  handleExplorerViewSelection: (key: string) => void;
  onDeleteExplorerView: (params: DeleteExplorerViewRequestParams) => void;
  selectedView: any;
  setSelectedView: (id: string) => void;
  loading: boolean;
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  onDeleteExplorerView: deleteExplorerView,
};

export const SavedViewSelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(SavedViewSelectorComponent);
