import {
  ACRONYMS,
  DOLLAR_SIGN_PREFIX_LIST,
  PERCENT_METRICS,
} from "constants/index";
import { useEffect, useRef } from "react";
import moment from "moment";
import SVGS from "./svgs";
export { SVGS };

export const formatTimestampIntoDatestring = (timestamp) => {
  const date = new Date(0);
  date.setUTCSeconds(timestamp);
  const day = date.toLocaleString("default", { day: "2-digit" });
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.toLocaleString("default", { year: "numeric" });
  const time = date.toLocaleString("en-US", {
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });
  return `${month} ${day}, ${year} ${time}`;
};
export const formatMetricValue = (params) => {
  let { value } = params;
  if (value === null || value === undefined) {
    value = 0;
  }
  const metric = params.field;
  if (DOLLAR_SIGN_PREFIX_LIST.includes(metric)) {
    value = value.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return `$${value}`;
  }
  if (PERCENT_METRICS.includes(metric)) {
    value = (value * 100).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return `${value}%`;
  }
  if (metric === "advertising.roas" || metric === "advertising.mer") {
    value = value.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return value;
  }
  value = value.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return value;
};

export const formatMetricNameWithoutHeader = (metric) => {
  const metricArray = metric
    .replace(/\./g, " ")
    .replace(/_/g, " ")
    .toLowerCase()
    .split(" ");
  metricArray.shift();
  return metricArray
    .map((word) =>
      ACRONYMS.includes(word)
        ? word.toUpperCase()
        : word.charAt(0).toUpperCase() + word.substring(1)
    )
    .join(" ");
};

export const formatMetricName = (metric) => {
  const metricArray = metric
    .replace(/\./g, " ")
    .replace(/_/g, " ")
    .toLowerCase()
    .split(" ");
  return metricArray
    .map((word) =>
      ACRONYMS.includes(word)
        ? word.toUpperCase()
        : word.charAt(0).toUpperCase() + word.substring(1)
    )
    .join(" ");
};

export const formatFilter = (filter) => {
  const metricArray = filter
    .replace(/\./g, " ")
    .replace(/_/g, " ")
    .replace(/:/g, ": ")
    .toLowerCase()
    .split(" ");
  return metricArray
    .map((word) =>
      ACRONYMS.includes(word)
        ? word.toUpperCase()
        : word.charAt(0).toUpperCase() + word.substring(1)
    )
    .join(" ");
};

export const getDescription = (key) => {
  switch (key) {
    case "amazon_advertising":
      return "Track issues across your Amazon Advertising campaigns";
    case "amazon_sp":
    case "amazon":
      return "Track issues across your Amazon Seller Central accounts";
    case "facebook":
      return "Track issues from Facebook Ads Manager";
    case "google":
      return "Track issues from Google Ad Manager";
    case "google_ads":
      return "Track issues from Google Ad Manager";
    case "google_analytics":
      return "Collect real-time metrics from Google Analytics";
    case "klaviyo":
      return "Track issues across your Klaviyo campaigns & flows";
    case "shopify":
      return "Track issues across your Shopify stores";
    case "slack":
      return "Receive notifications in your Slack workspace";
    case "tiktok":
      return "Track issues from TikTok Ads Manager";
    case "woocommerce":
      return "Track issues across your WooCommerce stores";
    case "recharge":
      return "Track charge errors from your Recharge subscriptions";
    default:
      return "";
  }
};

export const getTitle = (key) => {
  switch (key) {
    case "amazon_advertising":
      return "Amazon Advertising";
    case "amazon_sp":
    case "amazon":
      return "Amazon";
    case "calendar":
      return "Calendar";
    case "checkmark":
      return "Checkmark";
    case "clock":
      return "Clock";
    case "close":
      return "Close";
    case "email":
      return "Email";
    case "facebook":
      return "Facebook & Instagram";
    case "google":
      return "Google";
    case "google_ads":
      return "Google Ads";
    case "google_analytics":
      return "Google Analytics";
    case "instagram":
      return "Instagram";
    case "klaviyo":
      return "Klaviyo";
    case "live":
      return "Live";
    case "more":
      return "More";
    case "recharge":
      return "Recharge";
    case "shopify":
      return "Shopify";
    case "slack":
      return "Slack";
    case "tiktok":
      return "TikTok";
    case "woocommerce":
      return "WooCommerce";
    case "thick":
      return "Thick";
    case "thin":
      return "Thin";
    default:
      return "Amazon";
  }
};

export function useEffectAfterInitialRender(func, dependencies) {
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      func();
    }
  }, dependencies);
}

export const formatTimeColumn = (value, timeBreakdown, start, stop) => {
  const startOfQuery = moment.unix(start);
  const endOfQuery = moment.unix(stop);
  if (timeBreakdown.toLowerCase() === "1h") {
    if (!moment(value).isSame(moment(endOfQuery))) {
      const hourStart = moment(value).subtract(1, "hour").format("ha");
      const hourEnd = moment(value).format("ha");
      return `${hourStart} - ${hourEnd}`;
    }
    return `${moment(value).format("ha")} - now`;
  }
  if (timeBreakdown.toLowerCase() === "1d") {
    if (!moment(value).isSame(moment(endOfQuery))) {
      return moment(value).subtract(1, "day").format("MMM DD");
    }
    return moment(value).format("MMM DD");
  }
  if (timeBreakdown.toLowerCase() === "7d") {
    if (!moment(value).isSame(moment(endOfQuery))) {
      const weekStart = moment(value).subtract(7, "days").format("MMM DD");
      const weekEnd = moment(value).subtract(1, "days").format("MMM DD");
      return `${weekStart} - ${weekEnd}`;
    }
    const diff = endOfQuery.diff(startOfQuery, "days") % 7;
    const weekStart = moment(value).subtract(diff, "days").format("MMM DD");
    const weekEnd = moment(value).format("MMM DD");
    return `${weekStart} - ${weekEnd}`;
  }
  if (timeBreakdown.toLowerCase() === "1mo") {
    if (!moment(value).isSame(moment(endOfQuery))) {
      return moment(value).subtract(1, "month").format("MMM YYYY");
    }
    return moment(value).format("MMM YYYY");
  }
  if (timeBreakdown.toLowerCase() === "1y") {
    if (!moment(value).isSame(moment(endOfQuery))) {
      return moment(value).subtract(1, "year").format("YYYY");
    }
    return moment(value).format("YYYY");
  }
  return moment(value).format("MMM DD YYYY");
};

const mappings = {
  Hourly: "1h",
  Daily: "1d",
  Weekly: "7d",
  Monthly: "1mo",
  Yearly: "1y",
};

export const getTimeBreakdownOptions = (
  timeframe,
  customStartDate,
  customEndDate
) => {
  const timeBreakdownOptions = ["Hourly"];
  if (timeframe.endsWith("w")) {
    timeBreakdownOptions.push("Daily");
    timeBreakdownOptions.splice(0, 1);
  } else if (timeframe.endsWith("mo")) {
    timeBreakdownOptions.push(...["Daily", "Weekly"]);
    timeBreakdownOptions.splice(0, 1);
  } else if (timeframe.endsWith("y")) {
    timeBreakdownOptions.push(...["Daily", "Weekly", "Monthly"]);
    timeBreakdownOptions.splice(0, 1);
  } else if (timeframe === "custom") {
    const customSelectionLength = customEndDate.diff(customStartDate, "days");
    if (customSelectionLength >= 1) {
      timeBreakdownOptions.push(...["Daily"]);
      timeBreakdownOptions.splice(0, 1);
    }
    if (customSelectionLength > 7) timeBreakdownOptions.push(...["Weekly"]);
    if (customSelectionLength > 30) timeBreakdownOptions.push(...["Monthly"]);
    if (customSelectionLength > 365) timeBreakdownOptions.push(...["Yearly"]);
  }
  const mappedTimeBreakdownOptions = timeBreakdownOptions.map((option) => ({
    value: mappings[option],
    label: option,
  }));
  return mappedTimeBreakdownOptions;
};

export const getWeeklyDollarImpact = (openIssues) => {
  const dailyDollarImpact = openIssues.reduce(
    (acc, issue) => acc + issue.projected_daily_impact,
    0
  );
  const weeklyDollarImpact = Math.round(dailyDollarImpact * 7);
  return weeklyDollarImpact;
};

export const getOpenIssuesWoW = (issues, openIssues) => {
  const today = Math.round(Date.now() / 1000);
  const weekAgo = today - 60 * 60 * 24 * 7;
  const twoWeeksAgo = weekAgo - 60 * 60 * 24 * 7;

  const openIssuesLastWeek = issues.filter(
    (issue) =>
      issue.triggered_at >= twoWeeksAgo &&
      issue.triggered_at < weekAgo &&
      (!issue.resolved_at || issue.resolved_at <= weekAgo)
  );

  return openIssuesLastWeek.length
    ? Math.round(
        ((openIssues.length - openIssuesLastWeek.length) /
          openIssuesLastWeek.length) *
          100
      )
    : 100;
};

export const getMoneySaved = (issues) => {
  const resolvedWithinWeek = issues.filter(
    (issue) => issue.projected_daily_impact * 7 - issue.actual_dollar_impact > 0
  );
  return Math.round(
    resolvedWithinWeek.reduce(
      (acc, issue) =>
        acc + (issue.projected_daily_impact * 7 - issue.actual_dollar_impact),
      0
    )
  );
};

export const getResolutionTimeWow = (issues, avgResolutionTimeThisWeek) => {
  const today = Math.round(Date.now() / 1000);
  const weekAgo = today - 60 * 60 * 24 * 7;
  const twoWeeksAgo = weekAgo - 60 * 60 * 24 * 7;
  const issuesResolvedLastWeek = issues.filter(
    (issue) => issue.resolved_at <= weekAgo && issue.resolved_at >= twoWeeksAgo
  );
  let avgResolutionTimeLastWeek = 0;
  if (issuesResolvedLastWeek.length > 0) {
    const totalResolutionTimeLastWeek = issuesResolvedLastWeek.reduce(
      (acc, issue) => acc + issue.resolved_at - issue.triggered_at,
      0
    );
    avgResolutionTimeLastWeek =
      totalResolutionTimeLastWeek / issuesResolvedLastWeek.length / 60;
  }
  return avgResolutionTimeLastWeek
    ? Math.round(
        ((avgResolutionTimeThisWeek - avgResolutionTimeLastWeek) /
          avgResolutionTimeLastWeek) *
          100
      )
    : -999;
};

export function defaultDict(values, defaultValue) {
  return new Proxy(values, {
    get(storage, property) {
      if (!(property in storage)) {
        return defaultValue;
      }
      return storage[property];
    },
  });
}
