import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "components/atoms";

export function DeleteModalComponent({
  title,
  open,
  handleCancel,
  handleClose,
}: DeleteModalComponentProps) {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
        fullWidth
        style={{ minHeight: "400px" }}
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button destructive onClick={handleClose} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

interface DeleteModalComponentProps {
  title: string;
  open: boolean;
  handleCancel: () => void;
  handleClose: () => void;
}
export const DeleteModal = DeleteModalComponent;
