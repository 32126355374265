import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { styled, Typography } from "@material-ui/core";
import { SVGS } from "utils";
import { Grid } from "@mui/material";
import { theme } from "config/theme";
import { useWindowDimensions } from "App";
import { ExplorerWidgetComponent } from "./ExplorerWidget";

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "white",
    border: "1px solid #e0e0e0",
    borderRadius: "5px",
  },
}));

const StyledGridOverlay = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundImage: `url(${SVGS.Watermark2})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  textAlign: "center",
  "& .emptyMessageText": {
    fontSize: 32,
    fontWeight: 600,
    lineHeight: "48px",
  },
  width: "100%",
});

const StateOverlay = (height: any, text: any) => (
  <StyledGridOverlay>
    <Typography className="emptyMessageText"> {text} </Typography>
  </StyledGridOverlay>
);

export const ExplorerDashboardComponent = ({
  selectedMetrics,
  explorerData,
  isExplorerLoading,
  timeBreakdown,
  isExplorerSavedViewLoading,
}: ExplorerDashboardComponentProps) => {
  const renderWidgets = () =>
    explorerData.map((metric, index) => (
      <Grid item xs={6} key={index}>
        <ExplorerWidgetComponent
          data={metric}
          metric_name={metric.metric_name}
          group_by_tags={metric.group_by_tags}
          time_breakdown={timeBreakdown}
          isLoading={isExplorerLoading}
        />
      </Grid>
    ));
  const { height } = useWindowDimensions();
  const classes = useStyles(theme);
  return (
    <Grid
      container
      className={classes.container}
      direction="row"
      style={{ minHeight: height * 0.76 }}
    >
      {isExplorerSavedViewLoading ||
      (isExplorerLoading && explorerData.length === 0)
        ? StateOverlay(height, "Loading your Dashboard...")
        : selectedMetrics.length === 0 && !isExplorerLoading
        ? StateOverlay(height, "Select a Metric or View to Get Started")
        : selectedMetrics.length > 0 &&
          explorerData.length === 0 &&
          !isExplorerLoading &&
          !isExplorerSavedViewLoading
        ? StateOverlay(height, "There is no data for the metric you selected")
        : renderWidgets()}
    </Grid>
  );
};

interface ExplorerDashboardComponentProps {
  selectedMetrics: string[];
  explorerData: any;
  isExplorerLoading: boolean;
  timeBreakdown: string;
  isExplorerSavedViewLoading: boolean;
}
