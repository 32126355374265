import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SelectDate, TextH2 } from "components";
import Button from "@mui/material/Button";
import moment from "moment";
import { connect } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import {
  GetAllTagsForMetricsRequestParams,
  AllTagsForMetrics,
  PostBatchQueryRequestParams,
  ExplorerViewType,
} from "types";
import Grid from "@material-ui/core/Grid";
import {
  selectAvailableMetrics,
  selectAllTagsForMetrics,
  selectExplorerData,
  selectExplorerDataLoading,
  selectExplorerViews,
  selectExplorerView,
  selectExplorerViewsLoading,
  selectSavingExplorerView,
  selectExplorerTotalData,
  selectMetricsAllTagsLoading,
} from "store/app/selectors";
import {
  getAvailableMetrics,
  getAllTagsForMetrics,
  postBatchExplorerData,
  getExplorerViews,
  setExplorerView,
} from "store/app/actions";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import { useEffectAfterInitialRender, getTimeBreakdownOptions } from "utils";
import { TextField, Typography } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { debounce } from "@material-ui/core";
import { AnalyticsContext } from "index";
import { MetricSelectorComponent } from "./MetricSelector";
import { AttributeSelectorComponent } from "./AttributeSelector";
import { FilterSelectorComponent } from "./FilterSelector";
import { ExplorerGridComponent } from "./ExplorerGrid";
import { SaveExplorer } from "./SaveExplorer";
import { SavedViewSelector } from "./SavedViewSelector";
import { TimeBreakdownSelectorComponent } from "./TimeBreakdownSelector";
import { ExplorerDashboardComponent } from "./ExplorerDashboard";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
const useStyles = makeStyles((themes) => ({
  container: {
    paddingTop: themes.spacing(2),
    paddingBottom: themes.spacing(2),
  },
  topContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    marginBottom: 10,
    maxWidth: "none",
  },
  saveContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 0,
  },
  right: {
    marginLeft: "auto",
  },
  tabs: {
    height: "44px !important",
    width: "fit-content",
    minHeight: "44px !important",
    border: "1px solid #E3E3E3",
    borderRadius: "5px",
    "& .MuiButtonBase-root.MuiTab-root": {
      textTransform: "none",
      padding: "0px",
      color: "#656576",
      fontSize: "12px",
      fontWeight: 600,
      fontStyle: "normal",
      fontFamiliy: "Work Sans",
      background: "#FFFFFF",
    },
    "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
      zIndex: 1,
      color: "black",
      background: "#E3E3E3",
    },
  },
  tab: {
    height: "44px !important",
    minHeight: "44px !important",
  },
  button: {
    height: "44px",
    borderRadius: "5px !important",
    marginRight: "3px !important",
    backgroundColor: "#177EC2".concat(" !important"),
    "&:disabled": {
      backgroundColor: "#177ec252 !important",
      color: "white !important",
    },
  },
}));

const ExplorerPageComponent = ({
  onFetchAvailableMetrics,
  availableMetrics,
  onGetAllTagsForMetrics,
  metricsAllTagsLoading,
  allTagsForMetrics,
  onGetExplorerData,
  explorerDataLoading,
  explorerData,
  explorerTotalData,
  onGetExplorerViews,
  explorerViewsLoading,
  explorerViews,
  onSetExplorerView,
  explorerView,
  savingExplorerView,
}: ExplorerPageProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [firstLoad, setFirstLoad] = useState<boolean>(false);
  const [timeframe, setTimeframe] = useState<any>("-1mo");
  const [customStartDate, setCustomStartDate] = useState<any>(
    moment().subtract(7, "days")
  );
  const [timeBreakdown, setTimeBreakdown] = useState<string>("");
  const [customEndDate, setCustomEndDate] = useState<any>(moment());
  const [selectedMetrics, setSelectedMetrics] = useState<string[]>([]);
  const [selectedAttributes, setSelectedAttributes] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [filterType, setFilterType] = useState<string>("or");
  const [explorerLoading, setExplorerLoading] = useState<boolean>(false);
  const [explorerGridData, setExplorerGridData] = useState<any>(explorerData);
  const { search } = useLocation();
  const urlParam = new URLSearchParams(search).get("view");
  const [selectedViewId, setSelectedViewId] = useState<string>(urlParam || "");
  const [tabValue, setTabValue] = useState<number>(0);
  const [quickFilterSearchValue, setQuickFilterSearchValue] =
    React.useState("");
  const [gridState, setGridState] = React.useState<any>({});
  const { analytics } = useContext(AnalyticsContext);

  const gridApiRef = useGridApiRef();

  const paramToFunctMap = {
    metrics: (val) => setSelectedMetrics(val.split(",")),
    attributes: setSelectedAttributes,
    filters: setSelectedFilters,
    timeframe: setTimeframe,
    timeBreakdown: setTimeBreakdown,
    gridState: (val) => setGridState(JSON.parse(val)),
    filter_type: setFilterType,
    // multiply by 1000 to convert to milliseconds bc that's what moment uses
    start: (val) => setCustomStartDate(moment(parseInt(val, 10) * 1000)),
    stop: (val) => setCustomEndDate(moment(parseInt(val, 10) * 1000)),
    view: setSelectedViewId,
  };

  const updateQueryParams = (paramsObj) => {
    const urlParams = new URLSearchParams(search);
    Object.keys(paramsObj).forEach((key) => {
      if (paramsObj[key] === "") {
        urlParams.delete(key);
      } else {
        urlParams.set(key, paramsObj[key]);
      }
    });
    navigate(`/explorer?${urlParams.toString()}`);
  };

  useEffect(() => {
    setFirstLoad(true);
    if (!firstLoad) return;
    analytics.page("Explorer");
    onFetchAvailableMetrics();
    onGetExplorerViews();
    // apply url params to state
    const urlParams = new URLSearchParams(search);
    urlParams.forEach((value, key) => {
      // if the key is not in the map, don't do anything
      if (!paramToFunctMap[key]) return;
      paramToFunctMap[key](value);
    });
  }, [firstLoad]);

  const selectedView = explorerViews.find((view) => view.id === selectedViewId);

  useEffectAfterInitialRender(() => {
    if (selectedViewId !== "" && selectedView) {
      onSetExplorerView(selectedView);
    } else {
      onSetExplorerView({
        id: "",
        name: "",
        selected_filters: "",
        selected_metrics: "",
        selected_attributes: "",
        grid_state: "",
        time_breakdown: "",
        timeframe: "",
        start_time: "",
        end_time: "",
      });
      navigate(`/explorer`);
    }
  }, [selectedViewId]);

  useEffectAfterInitialRender(() => {
    const savedMetrics = explorerView.selected_metrics
      ? JSON.parse(explorerView.selected_metrics)
      : [];
    const savedFilters = explorerView.selected_filters
      ? explorerView.selected_filters
      : "";
    const savedAttributes = explorerView.selected_attributes
      ? explorerView.selected_attributes
      : "";
    const savedTimeBreakdown = explorerView.time_breakdown
      ? explorerView.time_breakdown
      : "";
    const savedTimeframe = explorerView.timeframe
      ? explorerView.timeframe
      : "-1mo";
    const startTime = explorerView.start_time ? explorerView.start_time : "";
    const endTime = explorerView.end_time ? explorerView.end_time : "";
    setSelectedMetrics(savedMetrics);
    setSelectedFilters(savedFilters);
    setSelectedAttributes(savedAttributes);
    setTimeBreakdown(savedTimeBreakdown);
    setTimeframe(savedTimeframe);
    if (savedTimeframe === "custom") {
      setCustomStartDate(moment(parseInt(startTime, 10) * 1000));
      setCustomEndDate(moment(parseInt(endTime, 10) * 1000));
    }
    // when selected view changes we set explorerGridData to empty, and then re-populate once new data is fetched (line 227)
    // this captures edge case where 2 saved views are identical, and new data isn't fetched
    if (
      JSON.stringify(savedMetrics) === JSON.stringify(selectedMetrics) &&
      savedFilters === selectedFilters &&
      savedAttributes === selectedAttributes &&
      savedTimeBreakdown === timeBreakdown &&
      savedTimeframe === timeframe &&
      explorerGridData.length === 0
    ) {
      setExplorerGridData(explorerData);
    }
    if (gridApiRef.current) {
      const savedGridState = explorerView.grid_state
        ? JSON.parse(explorerView.grid_state)
        : [];
      setGridState(savedGridState);
    }
    setSelectedViewId(explorerView.id ? explorerView.id : "");
    const selectedMetricsString = savedMetrics.join(",");
    const updatedParams = {
      metrics: selectedMetricsString,
      filters: savedFilters,
      attributes: savedAttributes,
      timeBreakdown: savedTimeBreakdown,
      timeframe: savedTimeframe,
      view: explorerView.id,
      start: startTime,
      stop: endTime,
    };
    updateQueryParams(updatedParams);
  }, [explorerView]);

  useEffectAfterInitialRender(() => {
    setExplorerLoading(true);
    const start = parseInt(customStartDate.format("X"), 10);
    const stop = parseInt(customEndDate.format("X"), 10);
    onGetAllTagsForMetrics({
      metrics: selectedMetrics,
      timeframe,
      start,
      stop,
    });
    const params = {
      timeframe,
      queries: selectedMetrics.map((metric) => ({
        id: metric,
        metric_name: metric,
        filter_tags: selectedFilters,
        group_by_tags: selectedAttributes,
        graph_type: timeBreakdown ? "line_chart" : "highlight",
        every: timeBreakdown,
        filter_type: filterType,
      })),
      start,
      stop,
    };
    if (gridState && gridState.columns) {
      const newMetric = selectedMetrics.find(
        (metric) =>
          !(
            gridState.columns.orderedFields &&
            gridState.columns.orderedFields.find((col) => col === metric)
          )
      );
      if (newMetric && gridState.columns.orderedFields) {
        // add newMetric to end of gridState.columns.orderedFields
        gridState.columns.orderedFields.push(newMetric);
        setGridState(gridState);
      }
    }
    const selectedMetricsString = selectedMetrics.join(",");
    const urlParams = new URLSearchParams(search);
    urlParams.set("metrics", selectedMetricsString);
    if (selectedMetrics.length === 0) {
      urlParams.delete("metrics");
    }
    navigate(`/explorer?${urlParams.toString()}`);
    onGetExplorerData(params);
    if (selectedMetrics.length === 0) {
      setTimeBreakdown("");
    }
  }, [JSON.stringify(selectedMetrics)]);

  useEffectAfterInitialRender(() => {
    setExplorerLoading(true);
    const start = parseInt(customStartDate.format("X"), 10);
    const stop = parseInt(customEndDate.format("X"), 10);
    onGetAllTagsForMetrics({
      metrics: selectedMetrics,
      timeframe,
      start,
      stop,
    });
    const params = {
      timeframe,
      queries: selectedMetrics.map((metric) => ({
        id: metric,
        metric_name: metric,
        filter_tags: selectedFilters,
        group_by_tags: selectedAttributes,
        graph_type: timeBreakdown ? "line_chart" : "highlight",
        every: timeBreakdown,
        filter_type: filterType,
      })),
      start,
      stop,
    };
    if (gridState && gridState.columns) {
      // find if it's a newly selected attribute
      const newAttribute = selectedAttributes
        .split(",")
        .find(
          (attribute) =>
            !(
              gridState.columns.orderedFields &&
              gridState.columns.orderedFields.find((col) => col === attribute)
            )
        );
      if (newAttribute && gridState.columns.orderedFields) {
        // put as last attribute in gridState.columns.orderedFields.
        // Subtract 2 b/c the current attribute isn't in the gridState yet
        // ASSUMES that attributes always precede metrics in gridState.columns.orderedFields
        const lastAttributeIndex = selectedAttributes.split(",").length - 2;
        gridState.columns.orderedFields.splice(
          lastAttributeIndex + 1,
          0,
          newAttribute
        );
        setGridState(gridState);
      }
    }
    const updatedQueryParams = {
      attributes: selectedAttributes,
      filters: selectedFilters,
      timeframe,
      timeBreakdown,
      start: customStartDate.format("X"),
      stop: customEndDate.format("X"),
    };
    if (timeframe === "custom") {
      params.start = parseInt(customStartDate.format("X"), 10);
      params.stop = parseInt(customEndDate.format("X"), 10);
    } else {
      updatedQueryParams.stop = "";
      updatedQueryParams.start = "";
    }
    updateQueryParams(updatedQueryParams);
    onGetExplorerData(params);
  }, [
    selectedAttributes,
    selectedFilters,
    timeframe,
    timeBreakdown,
    customStartDate,
    customEndDate,
  ]);

  useEffectAfterInitialRender(() => {
    if (
      (!explorerDataLoading && explorerData.length !== 0) ||
      selectedMetrics.length === 0
    ) {
      setExplorerLoading(false);
      setExplorerGridData(explorerData);
    }
  }, [explorerDataLoading, JSON.stringify(explorerData)]);

  useEffectAfterInitialRender(() => {
    if (!savingExplorerView) {
      onGetExplorerViews();
    }
  }, [savingExplorerView]);

  const updateTimeframe = (event) => {
    setTimeBreakdown("");
    setTimeframe(event.target.value);
  };

  const updateCustomDate = (newStartDate, newEndDate) => {
    setCustomStartDate(newStartDate);
    setCustomEndDate(newEndDate);
  };

  const handleMetricSelections = (metrics) => {
    setSelectedMetrics(metrics);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 1) {
      if (!timeBreakdown && selectedMetrics.length > 0) {
        const timeBreakdownOptions = getTimeBreakdownOptions(
          timeframe,
          customStartDate,
          customEndDate
        );
        setTimeBreakdown(
          timeBreakdownOptions[timeBreakdownOptions.length - 1].value
        );
      }
      setQuickFilterSearchValue("");
    }
  };

  const handleChange = (key, vals) => {
    let newValue = "";
    vals.forEach((val, index) => {
      if (vals.length - 1 === index) {
        newValue += `${val}`;
      } else {
        newValue += `${val},`;
      }
    });
    switch (key) {
      case "filters":
        setSelectedFilters(newValue);
        break;
      case "attributes":
        setSelectedAttributes(newValue);
        break;
      default:
        break;
    }
  };

  const handleTimeBreakdownChange = (
    event: React.MouseEvent<HTMLElement>,
    newSelection: string
  ) => {
    setTimeBreakdown(newSelection || "");
  };

  const updateSearchValue = debounce((newValue) => {
    gridApiRef.current.setQuickFilterValues(
      newValue.split(" ").filter((word) => word !== "")
    );
  }, 500);

  const handleQuickFilterSearchChange = (event) => {
    setQuickFilterSearchValue(event.target.value);
    updateSearchValue(event.target.value);
  };

  useEffect(() => {
    if (gridApiRef.current && gridState && Object.keys(gridState).length > 0) {
      // set saved state once grid data is loaded
      gridApiRef.current.restoreState(gridState);
    }
  }, [explorerGridData]);

  useEffect(() => {
    if (gridApiRef.current && Object.keys(gridState).length > 0) {
      const state = gridApiRef.current.exportState();
      const urlParams = new URLSearchParams(search);
      urlParams.set("gridState", JSON.stringify(state));
      navigate(`/explorer?${urlParams.toString()}`);
    }
  }, [gridState]);

  // eslint-disable-next-line arrow-body-style
  const ExplorerGridMemoized: any = useMemo(() => {
    return (
      <ExplorerGridComponent
        selectedMetrics={selectedMetrics}
        selectedAttributes={selectedAttributes}
        explorerData={explorerGridData}
        isExplorerLoading={explorerLoading}
        timeBreakdown={timeBreakdown}
        isExplorerSavedViewLoading={explorerViewsLoading || false}
        explorerTotalData={explorerTotalData}
        apiRef={gridApiRef}
        setGridState={setGridState}
      />
    );
  }, [explorerGridData, explorerLoading, explorerViewsLoading]);

  // eslint-disable-next-line arrow-body-style
  const ExplorerDashboardMemoized: any = useMemo(() => {
    return (
      <ExplorerDashboardComponent
        selectedMetrics={selectedMetrics}
        explorerData={explorerGridData}
        isExplorerLoading={explorerLoading}
        timeBreakdown={timeBreakdown}
        isExplorerSavedViewLoading={explorerViewsLoading || false}
      />
    );
  }, [explorerGridData, explorerLoading, explorerViewsLoading]);

  return (
    <Container maxWidth={false} className={classes.container}>
      <Container maxWidth="xl" className={classes.topContainer}>
        <Container style={{ padding: "0px" }}>
          <TextH2>
            Data Explorer{explorerView.id && ": ".concat(explorerView.name)}
          </TextH2>
        </Container>
        <Container maxWidth="lg" className={classes.saveContainer}>
          <SaveExplorer
            selectedMetrics={selectedMetrics}
            selectedAttributes={selectedAttributes}
            selectedFilters={selectedFilters}
            explorerViews={explorerViews}
            selectedView={selectedViewId}
            startTime={customStartDate.format("X")}
            endTime={customEndDate.format("X")}
            timeframe={timeframe}
            timeBreakdown={timeBreakdown}
            gridApi={gridApiRef}
            disabled={tabValue === 1}
          />
          <SavedViewSelector
            explorerViews={explorerViews}
            handleExplorerViewSelection={(selectedExplorerView) => {
              analytics.track("Explorer View Selected", {
                selectedExplorerView,
              });
              setExplorerGridData([]);
              setSelectedViewId(selectedExplorerView);
            }}
            selectedView={selectedViewId}
            setSelectedView={setSelectedViewId}
            loading={explorerViewsLoading}
          />
        </Container>
      </Container>
      <Grid
        container
        alignItems="flex-end"
        spacing={1}
        style={{ paddingBottom: 12 }}
      >
        <Grid item>
          <SelectDate
            start={customStartDate}
            end={customEndDate}
            select={timeframe}
            onChange={updateTimeframe}
            onCustomDateChange={updateCustomDate}
          />
        </Grid>
        <Grid item>
          <MetricSelectorComponent
            availableMetrics={availableMetrics}
            selectedMetrics={selectedMetrics}
            handleMetricSelections={handleMetricSelections}
          />
        </Grid>
        <Grid item>
          <AttributeSelectorComponent
            allTagsForMetrics={allTagsForMetrics}
            handleAttributeSelections={handleChange}
            loading={metricsAllTagsLoading}
            selectedAttributes={selectedAttributes}
          />
        </Grid>
        <Grid item>
          <FilterSelectorComponent
            allTagsForMetrics={allTagsForMetrics}
            handleFilterSelections={handleChange}
            loading={metricsAllTagsLoading}
            selectedFilters={selectedFilters}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        style={{ paddingBottom: 8 }}
      >
        <Grid item style={{ display: "flex", gap: "10px", height: "40px" }}>
          <TextField
            value={quickFilterSearchValue}
            onChange={handleQuickFilterSearchChange}
            label="Search"
            disabled={explorerLoading || tabValue === 1}
            style={
              explorerLoading || tabValue === 1
                ? { width: "200px" }
                : { background: "white", width: "200px" }
            }
            size="small"
          />
          <Button
            onClick={() => {
              analytics.track("Explorer Exported to CSV", {
                selectedMetrics,
                selectedAttributes,
                selectedFilters,
              });
              gridApiRef.current.exportDataAsCsv();
            }}
            disabled={explorerLoading || tabValue === 1}
            variant="contained"
            className={classes.button}
            style={{ textTransform: "none", height: "100%" }}
          >
            Export to CSV
          </Button>
        </Grid>
        <Grid style={{ display: "flex", gap: "25px" }}>
          <Grid item>
            <Grid container alignItems="center">
              <Typography
                style={{
                  fontFamily: "Work Sans",
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#656576",
                  paddingRight: 5,
                }}
              >
                Interval
              </Typography>
              <TimeBreakdownSelectorComponent
                timeframe={timeframe}
                customStartDate={customStartDate}
                customEndDate={customEndDate}
                timeSelection={timeBreakdown}
                handleChange={handleTimeBreakdownChange}
                metricSelected={selectedMetrics.length !== 0}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              className={classes.tabs}
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
            >
              <Tab
                disableRipple
                label="Table"
                className={classes.tab}
                icon={<BackupTableIcon />}
                iconPosition="start"
              />
              <Tab
                disableRipple
                label="Charts"
                className={classes.tab}
                icon={<AddchartOutlinedIcon />}
                iconPosition="start"
              />
            </Tabs>
          </Grid>
        </Grid>
      </Grid>
      <TabPanel value={tabValue} index={0}>
        {ExplorerGridMemoized}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {ExplorerDashboardMemoized}
      </TabPanel>
    </Container>
  );
};

interface ExplorerPageProps {
  onFetchAvailableMetrics: () => void;
  availableMetrics: string[];
  onGetAllTagsForMetrics: (params: GetAllTagsForMetricsRequestParams) => void;
  metricsAllTagsLoading: boolean;
  allTagsForMetrics: AllTagsForMetrics;
  onGetExplorerData: (params: PostBatchQueryRequestParams) => void;
  explorerDataLoading: boolean;
  explorerData: any;
  explorerTotalData: any;
  onGetExplorerViews: () => void;
  explorerViewsLoading: boolean;
  explorerViews: ExplorerViewType[];
  onSetExplorerView: (explorerView: ExplorerViewType) => void;
  explorerView: ExplorerViewType;
  savingExplorerView: boolean;
}

const mapStateToProps = (state) => ({
  availableMetrics: selectAvailableMetrics(state),
  allTagsForMetrics: selectAllTagsForMetrics(state),
  explorerData: selectExplorerData(state),
  explorerDataLoading: selectExplorerDataLoading(state),
  explorerViewsLoading: selectExplorerViewsLoading(state),
  savingExplorerView: selectSavingExplorerView(state),
  explorerViews: selectExplorerViews(state),
  explorerView: selectExplorerView(state),
  explorerTotalData: selectExplorerTotalData(state),
  metricsAllTagsLoading: selectMetricsAllTagsLoading(state),
});

const mapDispatchToProps = {
  onFetchAvailableMetrics: getAvailableMetrics,
  onGetAllTagsForMetrics: getAllTagsForMetrics,
  onGetExplorerData: postBatchExplorerData,
  onGetExplorerViews: getExplorerViews,
  onSetExplorerView: setExplorerView,
};

export const Explorer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExplorerPageComponent);
