import React, { useContext, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Spinner, StyledTooltip } from "components";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Alert, Container } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import {
  GetIntegrationParams,
  InstallIntegrationRequestParams,
  SetIntegrationParams,
  UpdateIntegrationParams,
} from "types";
import {
  selectInstallingIntegration,
  selectIntegration,
  selectIntegrationInstallMessage,
  selectIsUpdatingIntegration,
} from "store/app/selectors";
import {
  installIntegration,
  getIntegration,
  setIntegration,
  setInstallIntegrationMessage,
  updateIntegration,
  setIsOnboardingInstall,
} from "store/app/actions";
import { connect } from "react-redux";
import { AnalyticsContext } from "index";
import { IntegrationButton } from "../../../pages/integrations/components/IntegrationButton";
import { SvgIcon } from "../commons/SvgIcon";
import { OnboardingSelectingAccountsComponent } from "./OnboardingSelectAccounts";

const useStyles = makeStyles(() => ({
  dialogBox: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "800px",
      height: "550px",
      borderRadius: "5px",
    },
    "& .MuiDialog-paperScrollPaper": {
      justifyContent: "center",
    },
  },
  container: {
    padding: "5px",
    margin: "0px",
  },
  enabledConnect: {
    color: "#177EC2",
    cursor: "pointer",
  },
  disabledConnect: {
    color: "#9e9e9e",
    cursor: "not-allowed",
  },
}));

// some way to set active step
const steps = [
  {
    label: "Stores",
    title: "Connect to stores",
    rows: [
      { svg: "shopify", title: "Shopify", integrationName: "shopify" },
      { svg: "amazon", title: "Amazon", integrationName: "amazon_sp" },
    ],
  },
  {
    label: "Ad platforms",
    title: "Connect to ad platforms",
    rows: [
      { svg: "google", title: "Google Ads", integrationName: "google" },
      { svg: "facebook", title: "Facebook", integrationName: "facebook" },
      {
        svg: "amazon_advertising",
        title: "Amazon Ads",
        integrationName: "amazon_advertising",
      },
      { svg: "tiktok", title: "TikTok", integrationName: "tiktok" },
    ],
  },
  {
    label: "Other tools",
    title: "Connect to other tools",
    rows: [
      {
        svg: "google_analytics",
        title: "Google Analytics",
        integrationName: "google_analytics",
      },
      { svg: "klaviyo", title: "Klaviyo", integrationName: "klaviyo" },
      { svg: "recharge", title: "Recharge", integrationName: "recharge" },
    ],
  },
  {
    label: "Slack",
    title: "Connect to Slack",
    rows: [{ svg: "slack", title: "Slack", integrationName: "slack" }],
  },
];

export function OnboardingModalComponent({
  open,
  handleCancel,
  handleFinish,
  onInstallIntegration,
  onGetIntegration,
  integration,
  installingIntegration,
  onSetIntegration,
  installMessage,
  onSetInstallIntegrationMessage,
  onUpdateIntegration,
  isUpdatingIntegration,
  onSetIsOnboardingInstall,
}: OnboardingModalComponentProps) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [integrationToConnect, setIntegrationToConnect] =
    React.useState<any>("");
  const [linkedAccounts, setLinkedAccounts] = React.useState<any>({});
  const [selectingIntegrationAccounts, setSelectingIntegrationAccounts] =
    React.useState(false);
  const [accountsEnabledState, setAccountsEnabledState] = React.useState<any>(
    {}
  );
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [tooltipMessage, setTooltipMessage] = React.useState("");

  const [installStatusText, setInstallStatusText] = React.useState("");
  const { analytics } = useContext(AnalyticsContext);
  const classes = useStyles();

  useEffect(() => {
    onSetInstallIntegrationMessage("");
  }, []);

  useEffect(() => {
    // TODO: set integration to empty
    if (!installingIntegration && integrationToConnect) {
      onSetIntegration({ integration_name: "", data: [] });
      if (
        (integrationToConnect === "facebook" ||
          integrationToConnect === "amazon_advertising" ||
          integrationToConnect === "tiktok") &&
        installMessage?.includes("success")
      ) {
        setSelectingIntegrationAccounts(true);
        // add to google analytics in here?
      } else if (
        integrationToConnect === "google" &&
        (installMessage?.includes("success") ||
          installMessage?.includes("Google Analytics"))
      ) {
        setSelectingIntegrationAccounts(true);
      }
      onGetIntegration({ name: integrationToConnect });
    }
  }, [installingIntegration]);

  useEffect(() => {
    if (installMessage && installMessage.length > 0) {
      const message =
        installMessage?.includes("success") ||
        // cover edge case where google ad installed but user doesn't have google analytics
        (installMessage?.includes("Google Analytics") &&
          integrationToConnect === "google")
          ? `Successfully installed ${integrationToConnect}`
          : `There's a problem connecting your ${integrationToConnect} account`;
      setInstallStatusText(message);
    }
  }, [installMessage]);

  useEffect(() => {
    if (integration && integration.data.length > 0) {
      const newLinkedAccounts = {
        ...linkedAccounts,
        [integrationToConnect]: integration.data,
      };
      // if google is integrated, by default so is google analytics if install has no errors
      if (
        integrationToConnect === "google" &&
        installMessage?.includes("success")
      ) {
        newLinkedAccounts.google_analytics = integration.data;
      }
      setLinkedAccounts(newLinkedAccounts);
    }
  }, [integration]);

  useEffect(() => {
    if (!isUpdatingIntegration) {
      setSelectingIntegrationAccounts(false);
    }
  }, [isUpdatingIntegration]);

  const handleNext = () => {
    if (activeStep === 0) {
      // check if shopify or amazon are keys in linkedAccounts
      if (linkedAccounts.shopify || linkedAccounts.amazon_sp) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setTooltipOpen(false);
        setInstallStatusText("");
      } else {
        setTooltipOpen(true);
        setTooltipMessage("Please link at least one store");
      }
      return;
    }
    if (activeStep === 1) {
      if (
        linkedAccounts.google ||
        linkedAccounts.facebook ||
        linkedAccounts.tiktok ||
        linkedAccounts.amazon_advertising
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setTooltipOpen(false);
        setInstallStatusText("");
      } else {
        setTooltipOpen(true);
        setTooltipMessage("Please link at least one ad platform");
      }
      return;
    }
    setInstallStatusText("");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setTooltipOpen(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setInstallStatusText("");
  };

  const onClose = () => {
    setActiveStep(0);
    handleCancel();
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleAccountEnableToggle = (accountId: string, params: any) => {
    setAccountsEnabledState({
      ...accountsEnabledState,
      [accountId]: { enabled: params.target.checked },
    });
  };

  const onConfirmEnabledAccounts = () => {
    // for account in accountsEnabledState
    // if there are keys in accountsEnabledState
    if (Object.keys(accountsEnabledState).length > 0) {
      const params = {
        accounts_to_update: accountsEnabledState,
        integration_name: integrationToConnect,
      } as UpdateIntegrationParams;
      onUpdateIntegration(params);
    }
    Object.keys(accountsEnabledState).forEach((accountId) => {
      const trackingString = accountsEnabledState[accountId].enabled
        ? "Enabled"
        : "Disabled";
      analytics.track(`Integration Account ${trackingString}`, {
        integration_name: integrationToConnect,
        account_id: accountId,
      });
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      className={classes.dialogBox}
    >
      {selectingIntegrationAccounts ? (
        <OnboardingSelectingAccountsComponent
          integration={integration}
          setSelectingIntegrationAccounts={setSelectingIntegrationAccounts}
          handleAccountEnableToggle={handleAccountEnableToggle}
          onConfirmEnabledAccounts={onConfirmEnabledAccounts}
          isUpdatingIntegration={isUpdatingIntegration}
        />
      ) : (
        <Container
          maxWidth={false}
          style={{
            display: "flex",
            gap: "5px",
            justifyContent: "center",
            margin: "0px",
            padding: "0px",
          }}
        >
          <Container
            maxWidth={false}
            className={classes.container}
            style={{
              display: "flex",
              width: "fit-content",
              justifyContent: "center",
              margin: "0px",
              maxHeight: "50px",
            }}
          >
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step) => (
                <Step key={step.label}>
                  <StepLabel>{step.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Container>
          <Container
            maxWidth={false}
            style={{
              display: "flex",
              placeContent: "space-around flex-end",
              justifyContent: "space-between",
              flexDirection: "column",
              alignContent: "space-between",
              paddingLeft: "0px",
            }}
          >
            <Container
              maxWidth={false}
              style={{ margin: "0px", padding: "0px" }}
            >
              <DialogTitle id="form-dialog-title">
                {steps[activeStep].title}
              </DialogTitle>
              {installStatusText && (
                <Alert
                  icon={false}
                  severity={
                    installStatusText.includes("Successfully")
                      ? "success"
                      : "error"
                  }
                  style={{ marginLeft: "24px", marginRight: "48px" }}
                >
                  {installStatusText}
                </Alert>
              )}
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Container
                    style={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "column",
                      paddingLeft: "0px",
                      minHeight: "250px",
                    }}
                  >
                    {steps[activeStep].rows.map((row) => {
                      const flexDirection =
                        row.integrationName === "shopify" ||
                        row.integrationName === "klaviyo" ||
                        row.integrationName === "recharge"
                          ? "column"
                          : "row";
                      return (
                        <Container
                          key={row.integrationName}
                          style={{
                            border: "1px solid #b3b3b3",
                            display: "flex",
                            borderRadius: "5px",
                            alignItems: "center",
                          }}
                        >
                          <Container
                            style={{
                              display: "flex",
                              flexDirection,
                              paddingLeft: "0px",
                              paddingRight: "0px",
                            }}
                          >
                            <Container
                              style={{
                                gap: "5px",
                                display: "flex",
                                padding: "4px",
                                alignItems: "center",
                                color: "black",
                                minHeight: "50px",
                              }}
                            >
                              <SvgIcon name={row.svg} size={20} /> {row.title}
                              {linkedAccounts[row.integrationName] && (
                                <Typography
                                  style={{ color: "green", marginLeft: "10px" }}
                                >
                                  {linkedAccounts[row.integrationName].length}{" "}
                                  {linkedAccounts[row.integrationName].length >
                                  1
                                    ? "linked accounts"
                                    : "linked account"}
                                </Typography>
                              )}
                            </Container>
                            {installingIntegration &&
                            integrationToConnect === row.integrationName ? (
                              <Spinner />
                            ) : (
                              <IntegrationButton
                                name={row.integrationName}
                                onInstallIntegration={(params) => {
                                  analytics.track(
                                    "Onboard Integration Installed",
                                    {
                                      integration: row.integrationName,
                                    }
                                  );
                                  setIntegrationToConnect(row.integrationName);
                                  onInstallIntegration(params);
                                  onSetIsOnboardingInstall(true);
                                }}
                                isOnboarding
                                disabled={
                                  installingIntegration ||
                                  linkedAccounts[row.integrationName]
                                }
                              />
                            )}
                          </Container>
                        </Container>
                      );
                    })}
                  </Container>
                </DialogContentText>
              </DialogContent>
            </Container>
            <DialogActions>
              {activeStep > 0 ? (
                <Button type="button" onClick={handleBack} color="primary">
                  Back
                </Button>
              ) : null}
              <StyledTooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={tooltipOpen}
                disableFocusListener
                disableTouchListener
                title={tooltipMessage}
                placement="bottom-end"
                arrow
              >
                <Button
                  type="button"
                  onClick={
                    activeStep === steps.length - 1 ? handleFinish : handleNext
                  }
                  color="primary"
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </StyledTooltip>
            </DialogActions>
          </Container>
        </Container>
      )}
    </Dialog>
  );
}

interface OnboardingModalComponentProps {
  open: boolean;
  handleCancel: () => void;
  handleFinish: () => void;
  onInstallIntegration: (params: InstallIntegrationRequestParams) => void;
  installingIntegration?: boolean;
  onGetIntegration: (params: GetIntegrationParams) => void;
  integration: SetIntegrationParams;
  onSetIntegration: (params: SetIntegrationParams) => void;
  installMessage?: string;
  onSetInstallIntegrationMessage: (message: string) => void;
  onUpdateIntegration: (params: UpdateIntegrationParams) => void;
  isUpdatingIntegration: boolean;
  onSetIsOnboardingInstall: (isOnboardingInstall: boolean) => void;
}

const mapStateToProps = (state) => ({
  installingIntegration: selectInstallingIntegration(state),
  installMessage: selectIntegrationInstallMessage(state),
  integration: selectIntegration(state),
  isUpdatingIntegration: selectIsUpdatingIntegration(state),
});

const mapDispatchToProps = {
  onInstallIntegration: installIntegration,
  onGetIntegration: getIntegration,
  onSetIntegration: setIntegration,
  onSetInstallIntegrationMessage: setInstallIntegrationMessage,
  onUpdateIntegration: updateIntegration,
  onSetIsOnboardingInstall: setIsOnboardingInstall,
};

export const OnboardingModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingModalComponent);
