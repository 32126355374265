import React from "react";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(() => ({
  toggleButtonGroup: {
    height: "44px",
    width: "400px !important",
    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: "#E3E3E3 !important",
      color: "#27282C",
    },
    "& .MuiToggleButton-root": {
      backgroundColor: "#FFFFFF",
      borderColor: "#E3E3E3 !important",
      color: "#656576",
      fontWeight: 500,
      fontFamily: "Work Sans",
      fontSize: "14px",
      fontStyle: "normal",
      letterSpacing: "-0.02em",
    },
  },
  leftButton: {
    "border-top-left-radius": "5px !important",
    "border-bottom-left-radius": "5px !important",
    "border-top-right-radius": "0 !important",
    "border-bottom-right-radius": "0 !important",
    "text-transform": "none !important",
  },
  middleButton: {
    "border-radius": "0px !important",
    "text-transform": "none !important",
  },
  rightButton: {
    "border-top-left-radius": "0px !important",
    "border-bottom-left-radius": "0px !important",
    "border-top-right-radius": "5px !important",
    "border-bottom-right-radius": "5px !important",
    "text-transform": "none !important",
  },
}));

export const IssuePivotSelectorComponent = ({
  pivotSelection,
  handleChange,
}: IssuePivotSelectorComponentProps) => {
  const classes = styles();

  const issuePivotOptions = ["Issue Type", "Category", "Integration"];
  const toggleButtons = [] as any;
  issuePivotOptions.forEach((option) => {
    toggleButtons.push(
      <ToggleButton
        value={option}
        key={option}
        className={
          option === "Issue Type"
            ? classes.leftButton
            : option === "Category"
            ? classes.middleButton
            : classes.rightButton
        }
      >
        {option}
      </ToggleButton>
    );
  });

  return (
    <ToggleButtonGroup
      size="small"
      value={pivotSelection}
      exclusive
      fullWidth
      onChange={handleChange}
      className={classes.toggleButtonGroup}
    >
      {toggleButtons}
    </ToggleButtonGroup>
  );
};

interface IssuePivotSelectorComponentProps {
  // need span of time selection
  pivotSelection: string;
  handleChange: (
    event: React.MouseEvent<HTMLElement>,
    breakdown: string
  ) => void;
}
