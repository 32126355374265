import React from "react";
import { Button } from "@material-ui/core";

interface DeleteIntegrationButtonProps {
  accounts: any[];
  onClick: (open: boolean) => void;
}
export const DeleteIntegrationButton = ({
  accounts,
  onClick,
}: DeleteIntegrationButtonProps) => {
  const handleClick = () => {
    onClick(true);
  };

  if (accounts.length === 0) {
    return null;
  }

  return (
    <Button
      style={{
        display: "flex",
        justifyContent: "center",
        width: "20%",
        padding: "10px",
        borderRadius: "5px",
        backgroundColor: "red",
        color: "white",
      }}
      variant="text"
      onClick={handleClick}
    >
      Delete
    </Button>
  );
};
