import React, { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { connect } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import { OrgInput } from "components/atoms";
import { SupportUserInfo } from "components";
import { getSupportUser, getUser } from "store/auth/actions";
import { User } from "types";
import { AxiosError } from "axios";
import { Alert, Snackbar } from "@mui/material";
import {
  selectIntegrationInstallMessage,
  selectIntegrationUpdateMessage,
  selectNotificationsUpdateMessage,
  selectIsOnboardingInstall,
} from "store/app/selectors";
import { AnalyticsContext } from "index";
import { CustomRouter } from "./Router";

import { auth0Client } from "./configureAxios";
import { selectSupportUser, selectUser } from "./store/auth/selectors";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  rootLoading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
}));

interface AppProps {
  userLoading: boolean;
  authError: Error;
  user: User;
  supportUser: User;
  onGetUser: () => void;
  onGetSupportUser: () => void;
  installMessage: string;
  integrationUpdateMessage: string;
  notificationsUpdateMessage: string;
  isOnboardingInstall: boolean;
  supportUserLoading: boolean;
}
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const App = ({
  userLoading,
  user,
  supportUser,
  onGetUser,
  onGetSupportUser,
  authError,
  installMessage,
  integrationUpdateMessage,
  notificationsUpdateMessage,
  isOnboardingInstall,
  supportUserLoading,
}: AppProps) => {
  const classes = useStyles();
  const { isLoading, isAuthenticated, error } = useAuth0();
  const [hasOrgAssigned, setHasOrgAssigned] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  const { analytics, updateAnalytics } = useContext(AnalyticsContext);

  useEffect(() => {
    if (isAuthenticated) {
      onGetUser();
      onGetSupportUser();
    } else if (!isLoading) {
      auth0Client.loginWithRedirect();
    }
  }, [isAuthenticated, isLoading]);

  // supportUserLoading handle...
  useEffect(() => {
    if (!supportUserLoading && supportUser) {
      updateAnalytics({
        track: (event: string, properties: any) => {
          console.log("Support user, not tracking", event, properties);
        },
        identify: (userId: string, traits: any) => {
          console.log("Support user, not making identify call", userId, traits);
        },
        group: (groupId: string, traits: any) => {
          console.log("Support user, not making group call", groupId, traits);
        },
        page: (pageName: string) => {
          console.log("Support user not making page call", pageName);
        },
      });
    }
  }, [supportUserLoading, supportUser]);

  useEffect(() => {
    if (user) {
      const traits = {
        email: user.email,
      } as any;
      // if both user.firstName and user.lastName are not null, then set the name
      if (user.firstName && user.lastName) {
        traits.name = `${user.firstName} ${user.lastName}`;
      }
      if (!supportUserLoading && !supportUser) {
        analytics.identify(user.id, traits);
        analytics.group(user.currentOrganization.id, {
          name: user.currentOrganization.name,
        });
      }
      setHasOrgAssigned(user.organizations.length > 0);
    }
    if (authError && (authError as AxiosError).code === "NO_ORG") {
      setHasOrgAssigned(false);
    }
  }, [user, authError, supportUserLoading]);

  useEffect(() => {
    if (installMessage) {
      setSnackbarOpen(true);
      setSnackbarMessage(installMessage);
    } else if (integrationUpdateMessage) {
      setSnackbarOpen(true);
      setSnackbarMessage(integrationUpdateMessage);
    } else if (notificationsUpdateMessage) {
      setSnackbarOpen(true);
      setSnackbarMessage(notificationsUpdateMessage);
    } else {
      setSnackbarOpen(false);
    }
  }, [installMessage, integrationUpdateMessage, notificationsUpdateMessage]);

  if (isLoading || userLoading) {
    return (
      <div className={classes.rootLoading}>
        <CircularProgress />
      </div>
    );
  }

  // if error and not redirected from shopify
  if (error && !queryParams.get("shop")?.includes("shopify.com")) {
    return <div>Oops... {error.message}</div>;
  }

  if (isAuthenticated && !hasOrgAssigned) {
    return <OrgInput />;
  }

  if (isAuthenticated && user && hasOrgAssigned) {
    return (
      <>
        {supportUser && (
          <SupportUserInfo user={user} supportUser={supportUser} />
        )}
        <div className={classes.root}>
          <CssBaseline />
          <CustomRouter />
        </div>
        <Snackbar
          open={snackbarOpen && !isOnboardingInstall}
          autoHideDuration={6000}
          onClose={() => {
            setSnackbarOpen(false);
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => {
              setSnackbarOpen(false);
            }}
            severity={
              snackbarMessage?.toLowerCase().includes("success")
                ? "success"
                : "error"
            }
            sx={
              snackbarMessage?.toLowerCase().includes("success")
                ? { width: "100%", backgroundColor: "rgb(198 234 198)" }
                : { width: "100%" }
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </>
    );
  }

  return <></>;
};

const mapStateToProps = (state) => ({
  userLoading: state.auth.loading,
  supportUserLoading: state.auth.supportUserLoading,
  authError: state.auth.error,
  user: selectUser(state),
  supportUser: selectSupportUser(state),
  installMessage: selectIntegrationInstallMessage(state),
  integrationUpdateMessage: selectIntegrationUpdateMessage(state),
  notificationsUpdateMessage: selectNotificationsUpdateMessage(state),
  isOnboardingInstall: selectIsOnboardingInstall(state),
});

const mapDispatchToProps = {
  onGetUser: getUser,
  onGetSupportUser: getSupportUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
