import Amazon from "../assets/icons/Amazon.svg";
import Calendar from "../assets/icons/Calendar.svg";
import Checkmark from "../assets/icons/Checkmark.svg";
import Clock from "../assets/icons/Clock.svg";
import Close from "../assets/icons/Close.svg";
import Email from "../assets/icons/Email.svg";
import Facebook from "../assets/icons/Facebook.svg";
import Google from "../assets/icons/Google.svg";
import GoogleAnalytics from "../assets/icons/Google_Analytics.svg";
import Instagram from "../assets/icons/Instagram.svg";
import Integration from "../assets/icons/Integration.svg";
import Klaviyo from "../assets/icons/Klaviyo.svg";
import Live from "../assets/icons/Live.svg";
import More from "../assets/icons/More.svg";
import Recharge from "../assets/icons/Recharge.svg";
import Shopify from "../assets/icons/Shopify.svg";
import Slack from "../assets/icons/Slack.svg";
import SlackBlack from "../assets/icons/Slack-Black.svg";
import Thick from "../assets/icons/Thick.svg";
import Thin from "../assets/icons/Thin.svg";
import TikTok from "../assets/icons/TikTok.svg";
import TrackstarLogoHero from "../assets/icons/Trackstar_Logo_Hero.svg";
import TrackstarLogoInverse from "../assets/icons/Trackstar_Logo_Inverse.svg";
import WooCommerce from "../assets/icons/WooCommerce_logo.svg";
import Watermark2 from "../assets/icons/Watermark2.svg";

const SVGS = {
  Amazon,
  Calendar,
  Checkmark,
  Clock,
  Close,
  Email,
  Facebook,
  Google,
  GoogleAnalytics,
  Instagram,
  Integration,
  Klaviyo,
  Live,
  More,
  Shopify,
  Slack,
  SlackBlack,
  TikTok,
  WooCommerce,
  Thick,
  Thin,
  TrackstarLogoHero,
  TrackstarLogoInverse,
  Watermark2,
  Recharge,
};
export default SVGS;
