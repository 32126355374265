import { OnboardIntegrationButton } from "components/atoms/buttons/OnboardIntegrationButton";
import React from "react";
import { connect } from "react-redux";

const SlackLoginBase = ({
  onSuccess,
  onFailure,
  isOnboarding,
  disabled,
}: SlackLoginBaseProps) => {
  const openPopup = () => {
    const width = 500;
    const height = 850;
    const left = 400;
    const top = 50;

    const url =
      "https://slack.com/oauth/v2/authorize?scope=channels:read,chat:write,chat:write.public,im:write,im:read,groups:read&client_id=2074070365474.2341703167026&redirect_uri=https://app.trackstarhq.com/integrations/slack";

    return window.open(
      url,
      "",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" + // eslint-disable-line prefer-template
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  const polling = (popup) => {
    const poll = setInterval(() => {
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(poll);
        onFailure("Popup has been closed by user");
      }

      const closeDialog = () => {
        clearInterval(poll);
        popup.close();
      };

      try {
        if (!popup.location.hostname.includes("slack.com")) {
          if (popup.location.search) {
            const query = new URLSearchParams(popup.location.search);
            const authCode = query.get("code");
            closeDialog();
            if (authCode) {
              onSuccess(authCode);
            } else if (onFailure) {
              onFailure(query.get("error"));
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 500);
  };

  const handleClick = () => {
    polling(openPopup());
  };

  if (isOnboarding) {
    return (
      <OnboardIntegrationButton onClick={handleClick} disabled={disabled} />
    );
  }

  return (
    <input
      alt="Add to Slack"
      onClick={handleClick}
      type="image"
      height="40"
      width="139"
      src="https://platform.slack-edge.com/img/add_to_slack@2x.png"
    />
  );
};

interface SlackLoginBaseProps {
  onSuccess: (response: any) => void;
  onFailure: (response: any) => void;
  isOnboarding?: boolean;
  disabled?: boolean;
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export const SlackLogin = connect(
  mapStateToProps,
  mapDispatchToProps
)(SlackLoginBase);
