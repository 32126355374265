import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, Typography } from "@mui/material";
import { IssueType } from "types";
import { TextH2, TextBody2 } from "components";
import RefreshIcon from "@mui/icons-material/Refresh";
import { getMoneySaved, getWeeklyDollarImpact } from "utils";
import { Divider } from "@material-ui/core";
import { IssueHeaderPane } from "./IssueHeaderPane";

const useStyles = makeStyles((themes) => ({
  container: {
    paddingTop: themes.spacing(2),
    paddingBottom: themes.spacing(2),
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  topContainer: {
    padding: "0px",
    margin: "0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  issueSyncInfoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "auto",
    margin: "0 0 0 0",
    padding: "0 0 0 0",
  },
  refreshButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "auto",
    cursor: "pointer",
    padding: "0 0 0 10px",
    marginLeft: "0px",
  },
  dataContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    gap: "10px",
    margin: "0px",
    width: "100%",
  },
  onboardingBanner: {
    "& .MuiAlert-message": {
      padding: "5px",
      borderRadius: "5px",
      backgroundColor: "rgb(198 234 198) !important",
    },
  },
  refreshIcon: {
    color: "#177EC2",
    height: "19px !important",
  },
  refreshText: {
    color: "#177EC2 !important",
    fontSize: "14px !important",
    lineHeight: "16px !important",
  },
  headerBox: {
    height: "110px",
    borderRadius: 5,
    border: "1px solid #E3E3E3",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    flexShrink: 1.25,
  },
}));
export const IssuesHeader = ({
  trackedItemsCountLoading,
  openIssuesLoading,
  resolvedIssuesLoading,
  homepageLoading,
  openIssues,
  resolvedIssues,
  lastUpdated,
  refreshCallback,
  grossSales,
  onboardingFinished,
  trackedItemsCount,
}: IssuesHeaderProps) => {
  const classes = useStyles();

  const weeklyDollarImpact = getWeeklyDollarImpact(openIssues);

  const moneySaved = getMoneySaved(resolvedIssues);

  const revenueLift = (moneySaved / grossSales) * 100;

  const totalResolutionTime = resolvedIssues.reduce(
    (acc, issue) => acc + issue.resolved_at - issue.triggered_at,
    0
  );
  let avgResolutionTime =
    resolvedIssues.length > 0
      ? Math.round(totalResolutionTime / resolvedIssues.length / 60)
      : 0;
  let avgResolutionTimeUnit = "mins.";
  if (avgResolutionTime > 60 && avgResolutionTime < 1440) {
    avgResolutionTime = Math.round(avgResolutionTime / 60);
    avgResolutionTimeUnit = "hrs.";
  }
  if (avgResolutionTime > 1440) {
    avgResolutionTime = Math.round(avgResolutionTime / 1440);
    if (avgResolutionTime > 1) {
      avgResolutionTimeUnit = "days";
    } else {
      avgResolutionTimeUnit = "day";
    }
  }

  return (
    <Container maxWidth={false} className={classes.container}>
      <Container maxWidth={false} className={classes.topContainer}>
        <TextH2>Issues</TextH2>
        {onboardingFinished && (
          <Alert
            icon={false}
            severity="success"
            className={classes.onboardingBanner}
          >
            Congratulations! You've successfully set up Trackstar!
          </Alert>
        )}
        <Container className={classes.issueSyncInfoContainer}>
          <TextBody2>
            Last Synced:{" "}
            {lastUpdated.toLocaleString("en-US", {
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </TextBody2>
          <Container
            onClick={() => refreshCallback()}
            className={classes.refreshButtonContainer}
          >
            <RefreshIcon className={classes.refreshIcon} />
            <Typography
              sx={{ fontWeight: 600 }}
              className={classes.refreshText}
            >
              Refresh
            </Typography>
          </Container>
        </Container>
      </Container>
      <Container className={classes.dataContainer} maxWidth={false}>
        <Container className={classes.headerBox}>
          <IssueHeaderPane
            loading={trackedItemsCountLoading}
            title="Tracked Items"
            value={`${trackedItemsCount.toLocaleString("en-US")}`}
            tooltipText="The total number of skus, ads, discount codes, links, etc... being monitored for issues"
          />
          <Divider orientation="vertical" />

          <IssueHeaderPane
            loading={openIssuesLoading}
            title="Open Issues"
            value={openIssues.length.toLocaleString("en-US")}
          />
          <Divider orientation="vertical" />
          <IssueHeaderPane
            loading={openIssuesLoading}
            title="Projected Impact"
            value={`$${weeklyDollarImpact.toLocaleString("en-US")}`}
            valueSuffix="/ week"
            tooltipText="The projected weekly impact of all open issues: estimated using real-time sales, marketing, and advertising data"
          />
        </Container>
        <Container className={classes.headerBox}>
          <IssueHeaderPane
            loading={resolvedIssuesLoading}
            title="Resolved Issues"
            value={resolvedIssues.length.toLocaleString("en-US")}
          />
          <Divider orientation="vertical" />
          <IssueHeaderPane
            loading={resolvedIssuesLoading}
            title="Avg. Time to Fix"
            value={avgResolutionTime.toLocaleString("en-US")}
            valueSuffix={avgResolutionTimeUnit}
          />
          <Divider orientation="vertical" />
          <IssueHeaderPane
            loading={resolvedIssuesLoading || homepageLoading}
            title="Saved in past year"
            value={`$${moneySaved.toLocaleString("en-US")}`}
            tooltipText="The $ saved from issues that Trackstar helped you resolve. Trackstar takes credit when you click a call-to-action and the issue resolves within 6 hours"
            changeValue={
              revenueLift && revenueLift >= 0.1 ? revenueLift : undefined
            }
            changeString={`${revenueLift.toFixed(1)}% lift`}
            negativeChangeGood={false}
          />
        </Container>
      </Container>
    </Container>
  );
};

interface IssuesHeaderProps {
  trackedItemsCountLoading: boolean;
  openIssuesLoading: boolean;
  resolvedIssuesLoading: boolean;
  homepageLoading: boolean;
  openIssues: IssueType[];
  resolvedIssues: IssueType[];
  grossSales: number;
  refreshCallback: () => void;
  lastUpdated: Date;
  onboardingFinished: boolean;
  trackedItemsCount: number;
}
