import React from "react";
import { SvgIcon } from "@material-ui/core";

export interface SvgIconProps {
  viewBox: string;
  color: string;
}

export const BackIcon = ({ viewBox, color }: SvgIconProps) => (
  <SvgIcon viewBox={viewBox}>
    <path
      d="M0.646446 4.14645C0.451185 4.34171 0.451185 4.65829 0.646446 4.85355L3.82843 8.03553C4.02369 8.2308 4.34027 8.2308 4.53553 8.03553C4.7308 7.84027 4.7308 7.52369 4.53553 7.32843L1.70711 4.5L4.53553 1.67157C4.7308 1.47631 4.7308 1.15973 4.53553 0.964466C4.34027 0.769204 4.02369 0.769204 3.82843 0.964466L0.646446 4.14645ZM21 4L1 4V5L21 5V4Z"
      fill={color}
    />
  </SvgIcon>
);

export const TrashIcon = ({ viewBox, color }: SvgIconProps) => (
  <SvgIcon viewBox={viewBox}>
    <path
      d="M9.69231 2.125H12.9231C13.5692 2.125 14 2.55 14 3.1875V4.25H0V3.1875C0 2.55 0.538462 2.125 1.07692 2.125H4.30769C4.52308 0.95625 5.70769 0 7 0C8.29231 0 9.47692 0.95625 9.69231 2.125ZM5.38462 2.125H8.61539C8.4 1.4875 7.64615 1.0625 7 1.0625C6.35385 1.0625 5.6 1.4875 5.38462 2.125ZM1.07692 5.3125H12.9231L11.9538 16.0438C11.9538 16.575 11.4154 17 10.8769 17H3.12308C2.58462 17 2.15385 16.575 2.04615 16.0438L1.07692 5.3125Z"
      fill={color}
    />
  </SvgIcon>
);
