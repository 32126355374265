import React, { useState, FormEvent, useEffect } from "react";
import { connect } from "react-redux";
import { Box, TextField, CircularProgress, FormGroup } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import { User } from "types";
import { setSupportUserJwt } from "../api";
import { selectSupportUser, selectUser } from "../store/auth/selectors";

export const LoginAsUserComponent = ({
  user,
  supportUser,
}: LoginAsUserProps) => {
  const [email, setEmail] = useState("");
  const [orgName, setOrgName] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const processLogin = async (opts: { email: string; org_name: string }) => {
    setLoading(true);
    setHasError(false);
    const token = await setSupportUserJwt({ ...opts });
    if (token) {
      window.location.href = "/";
      return false;
    }

    setLoading(false);
    setHasError(true);
    return false;
  };

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    await processLogin({ email, org_name: orgName });
    localStorage.removeItem("user");
  };

  useEffect(() => {
    const params = new URL(window.location.href).searchParams;
    if (params.get("email") && params.get("organization")) {
      setEmail(params.get("email") || "");
      setOrgName(params.get("organization") || "");
      processLogin({
        email: params.get("email") || "",
        org_name: params.get("organization") || "",
      });
      localStorage.removeItem("user");
    }
  }, [user, supportUser]);

  return (
    <Box justifyContent="center" width="50%">
      <form onSubmit={handleLogin}>
        <FormGroup>
          <TextField
            required
            autoFocus
            margin="dense"
            label="User Email"
            type="standard"
            fullWidth
            onChange={({ target: { value } }) => setEmail(value)}
            value={email}
          />
        </FormGroup>

        <FormGroup>
          <TextField
            required
            margin="dense"
            label="Organization Name"
            type="standard"
            fullWidth
            onChange={({ target: { value } }) => setOrgName(value)}
            value={orgName}
          />
        </FormGroup>
        {loading && <CircularProgress />}
        {hasError && (
          <Alert severity="error">
            Unable to login via given user email and organization
          </Alert>
        )}
        <Button color="primary" type="submit">
          Login As User
        </Button>
      </form>
    </Box>
  );
};

interface LoginAsUserProps {
  user: User;
  supportUser: User;
}

const mapStateToProps = (state) => ({
  user: selectUser(state),
  supportUser: selectSupportUser(state),
});

export const LoginAsUser = connect(mapStateToProps)(LoginAsUserComponent);
