import React, { useEffect, useState } from "react";
import { formatMetricNameWithoutHeader } from "utils";
import { ExplorerSelector } from "./ExplorerSelectorComponent";

export const MetricSelectorComponent = ({
  availableMetrics,
  selectedMetrics,
  handleMetricSelections,
}: MetricSelectorComponentProps) => {
  const [checkboxSelections, setCheckboxSelections] = useState<string[]>([]);

  useEffect(() => {
    handleMetricSelections(checkboxSelections);
  }, [JSON.stringify(checkboxSelections)]);

  useEffect(() => {
    setCheckboxSelections(selectedMetrics);
  }, [selectedMetrics]);

  const handleChange = (event) => {
    if (event.target.checked) {
      setCheckboxSelections([...checkboxSelections, event.target.value]);
    } else {
      const newSelections = checkboxSelections.filter(
        (elem) => elem !== event.target.value
      );
      setCheckboxSelections(newSelections);
    }
  };
  const groups = {
    advertising: [],
    inventory: [],
    marketing: [],
    orders: [],
    site: [],
  };
  availableMetrics.forEach((metric) => {
    const firstWord = metric.split(".")[0].toLowerCase();
    if (groups[firstWord]) {
      groups[firstWord].push(metric);
    }
  });

  return (
    <ExplorerSelector
      title="Metrics"
      checkboxSelections={checkboxSelections}
      handleChange={handleChange}
      groups={groups}
      formatter={formatMetricNameWithoutHeader}
      loading={!availableMetrics}
      isCheckbox
      explorerViewSelection=""
    />
  );
};

interface MetricSelectorComponentProps {
  availableMetrics: string[];
  selectedMetrics: string[];
  handleMetricSelections: (metrics: string[]) => void;
}
