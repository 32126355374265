import {
  FetchAuthStart,
  FetchAuthSuccess,
  FetchAuthFailure,
  GetUser,
  SetUser,
  User,
  SetOrganizationNotificationChannel,
  OrganizationNotificationChannel,
  Organization,
  SetOrganization,
  SwitchOrgParams,
  SwitchOrgs,
  FetchSupportAuthStart,
  FetchSupportAuthSuccess,
  FetchSupportAuthFailure,
} from "types";

export const AuthActionType = {
  FETCH_AUTH_START: "@@FETCH_AUTH_START",
  FETCH_AUTH_SUCCESS: "@@FETCH_AUTH_SUCCESS",
  FETCH_AUTH_FAILURE: "@@FETCH_AUTH_FAILURE",
  FETCH_SUPPORT_AUTH_START: "@@FETCH_SUPPORT_AUTH_START",
  FETCH_SUPPORT_AUTH_SUCCESS: "@@FETCH_SUPPORT_AUTH_SUCCESS",
  FETCH_SUPPORT_AUTH_FAILURE: "@@FETCH_SUPPORT_AUTH_FAILURE",
  GET_USER: "GET_USER",
  GET_SUPPORT_USER: "GET_SUPPORT_USER",
  SWITCH_ORGS: "SWITCH_ORGS",
  SET_USER: "SET_USER",
  SET_ORG: "SET_ORG",
  SET_SUPPORT_USER: "SET_SUPPORT_USER",
  SET_ORGANIZATION_NOTIFICATION_CHANNEL:
    "SET_ORGANIZATION_NOTIFICATION_CHANNEL",
  SET_CONNECT_MORE_APPS_VISIBLE: "SET_CONNECT_MORE_APPS_VISIBLE",
};

export const fetchAuthStart = (): FetchAuthStart => ({
  type: AuthActionType.FETCH_AUTH_START,
});

export const fetchAuthSuccess = (): FetchAuthSuccess => ({
  type: AuthActionType.FETCH_AUTH_SUCCESS,
});

export const fetchAuthFailure = (error: string): FetchAuthFailure => ({
  type: AuthActionType.FETCH_AUTH_FAILURE,
  error,
});

export const fetchSupportAuthStart = (): FetchSupportAuthStart => ({
  type: AuthActionType.FETCH_SUPPORT_AUTH_START,
});

export const fetchSupportAuthSuccess = (): FetchSupportAuthSuccess => ({
  type: AuthActionType.FETCH_SUPPORT_AUTH_SUCCESS,
});

export const fetchSupportAuthFailure = (
  error: string
): FetchSupportAuthFailure => ({
  type: AuthActionType.FETCH_SUPPORT_AUTH_FAILURE,
  error,
});

export const getUser = (): GetUser => ({
  type: AuthActionType.GET_USER,
});

export const getSupportUser = (): GetUser => ({
  type: AuthActionType.GET_SUPPORT_USER,
});

export const setUser = (user: User): SetUser => ({
  type: AuthActionType.SET_USER,
  user,
});

export const setOrganization = (org: Organization): SetOrganization => ({
  type: AuthActionType.SET_ORG,
  org,
});

export const setSupportUser = (user: User): SetUser => ({
  type: AuthActionType.SET_SUPPORT_USER,
  user,
});

export const setOrganizationNotificationChannel = (
  params: OrganizationNotificationChannel
): SetOrganizationNotificationChannel => ({
  type: AuthActionType.SET_ORGANIZATION_NOTIFICATION_CHANNEL,
  params,
});

export const switchOrgs = (params: SwitchOrgParams): SwitchOrgs => ({
  type: AuthActionType.SWITCH_ORGS,
  params,
});

export const setConnectMoreAppsVisible = () => ({
  type: AuthActionType.SET_CONNECT_MORE_APPS_VISIBLE,
});
