import React from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import BaseIconButton from "@material-ui/core/IconButton";
import { theme } from "config/theme";
import { TrashIcon } from "../commons";

const styles = () =>
  createStyles({
    base: {
      width: "30px",
      height: "30px",
    },
    primaryTrash: {
      backgroundColor: theme.colors.primaryBrand.inactive2,
      "&:hover": {
        backgroundColor: theme.colors.primaryBrand.inactive2,
      },
    },
    tinyTrash: {
      width: "10px",
      height: "10px",
      backgroundColor: theme.colors.primaryBrand.inactive2,
      "&:hover": {
        backgroundColor: theme.colors.primaryBrand.inactive2,
      },
    },
    redTrash: {
      backgroundColor: theme.colors.error,
      "&:hover": {
        backgroundColor: theme.colors.error,
      },
    },
    smallTrash: {
      width: "20px",
      height: "20px",
    },
  });

export interface TrashIconButtonProps extends WithStyles<typeof styles> {
  variant: "primary" | "red" | "small" | "tiny";
}
const TrashIconButtonComponent = ({ classes, variant, ...rest }) => (
  <BaseIconButton
    className={`${classes.base} ${classes[`${variant}Trash`]}`}
    {...rest}
  >
    {variant === "small" ? (
      <TrashIcon viewBox="-10 -8.2 30 30" color={theme.colors.error} />
    ) : (
      <TrashIcon viewBox="-5 -3.2 24 24" color={theme.colors.light} />
    )}
  </BaseIconButton>
);
const TrashIconButton = withStyles(styles)(TrashIconButtonComponent);

export { TrashIconButton };
