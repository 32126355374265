import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import { SvgIcon, SelectBox, Spinner } from "components";
import { useAuth0 } from "@auth0/auth0-react";
import { SwitchOrgParams, User } from "types";
import { connect } from "react-redux";
import { auth0Client } from "configureAxios";
import { switchOrgs } from "store/auth/actions";
import { selectLoading } from "store/app/selectors";
import { ListItemIcon } from "@material-ui/core";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InsertChartOutlinedSharpIcon from "@mui/icons-material/InsertChartOutlinedSharp";
import { Box, ListItem, ListItemButton } from "@mui/material";
import ExtensionIcon from "@mui/icons-material/Extension";
import { Link, useLocation } from "react-router-dom";
import { selectSupportUser, selectUser } from "../../../store/auth/selectors";

const useStyles = makeStyles({
  root: {
    maxWidth: "160px",
    minWidth: "160px",
    height: "100%",
    display: "block",
    position: "fixed",
    zIndex: 5,
    backgroundColor: "#27282c",
    padding: "10px",
    "& .MuiListItem-root": {
      "& .MuiListItemButton-root": {
        "&.Mui-selected, &.Mui-selected:hover, &:hover": {
          backgroundColor: "#525252",
          borderRadius: "5px",
          "& span": {
            color: "#ffffff",
            fontWeight: "bold",
          },
          "& .MuiListItemIcon-root": {
            "& svg": {
              fill: "#ffffff",
            },
          },
        },
      },
    },
  },
  label: {
    "& span": {
      fontFamily: "Work Sans",
      fontWeight: 500,
      fontSize: "14px",
      color: "#C4C4C4",
    },
  },
  tsLabel: {
    "& span": {
      fontFamily: "Work Sans",
      fontWeight: 800,
      fontSize: "24px",
      color: "#C4C4C4",
    },
  },
  bottomNav: {
    position: "absolute",
    bottom: "0",
  },
  logoHolder: {
    background: "#FFFFFF",
    borderRadius: "5px",
    minWidth: "70px",
    width: "70px",
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tsRowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  },
  tsRowContainerColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  switchContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

export interface NavigationProps {
  user: User;
  supportUser: User;
  loading: boolean;
  onSwitchOrgs: (params: SwitchOrgParams) => void;
}

const NavigationComponent = ({
  user,
  supportUser,
  loading,
  onSwitchOrgs,
}: NavigationProps) => {
  const classes = useStyles();
  const { logout } = useAuth0();
  const [switchOrgToggled, setSwitchOrgToggled] = React.useState(0);
  const pathName = useLocation().pathname;

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("support-user-token");
    localStorage.removeItem("supportUser");
    logout({ returnTo: window.location.origin });
  };
  const handleChangeOrg = (org) => {
    const params: SwitchOrgParams = {
      org_name: org,
    };
    onSwitchOrgs(params);
    setSwitchOrgToggled(1);
    localStorage.removeItem("user");
  };

  // Only re-login once the switch org metadata is complete
  useEffect(() => {
    if (switchOrgToggled && !loading) {
      auth0Client.loginWithRedirect();
    }
  }, [switchOrgToggled, loading]);
  return (
    <Box className={classes.root}>
      <List>
        <ListItemButton
          component={Link}
          to="/"
          className={classes.tsRowContainerColumn}
          disableGutters
          disableRipple
        >
          <ListItemIcon className={classes.logoHolder}>
            <SvgIcon name="TrackstarLogoInverse" size={60} />
          </ListItemIcon>
          <ListItemText primary="Trackstar" className={classes.tsLabel} />
        </ListItemButton>
        <ListItem disableGutters>
          <ListItemButton
            component={Link}
            to="/"
            disableRipple
            selected={pathName === "/" || pathName === "/issues"}
          >
            <ListItemIcon style={{ minWidth: "0px", paddingRight: "10px" }}>
              <DashboardIcon style={{ color: "C4C4C4" }} />
            </ListItemIcon>
            <ListItemText primary="Issues" className={classes.label} />
          </ListItemButton>
        </ListItem>
        <ListItem disableGutters>
          <ListItemButton
            component={Link}
            to="/explorer"
            disableRipple
            selected={pathName === "/explorer"}
          >
            <ListItemIcon style={{ minWidth: "0px", paddingRight: "10px" }}>
              <InsertChartOutlinedSharpIcon style={{ color: "C4C4C4" }} />
            </ListItemIcon>
            <ListItemText primary="Explorer" className={classes.label} />
          </ListItemButton>
        </ListItem>
        <ListItem disableGutters>
          <ListItemButton
            component={Link}
            to="/integrations"
            disableRipple
            selected={pathName.includes("/integrations")}
          >
            <ListItemIcon style={{ minWidth: "0px", paddingRight: "10px" }}>
              <ExtensionIcon style={{ color: "C4C4C4" }} />
            </ListItemIcon>
            <ListItemText primary="Integrations" className={classes.label} />
          </ListItemButton>
        </ListItem>
      </List>
      <List className={classes.bottomNav}>
        {((user && user.isAdmin) || (supportUser && supportUser.isAdmin)) && (
          <ListItem disableGutters>
            <ListItemButton component="a" href="/support-login" disableRipple>
              <ListItemText primary="Support Login" className={classes.label} />
            </ListItemButton>
          </ListItem>
        )}
        {loading && switchOrgToggled ? (
          <Spinner center />
        ) : user && user.organizations.length > 1 ? (
          <ListItem disableGutters className={classes.switchContainer}>
            <ListItemText primary="Switch Account" className={classes.label} />
            <SelectBox
              options={user.organizations.map((org) => ({
                label: org.name,
                value: org.name,
              }))}
              value={user.currentOrganization.name}
              onChange={(val) => {
                handleChangeOrg(val);
              }}
              placeholder="Select Org"
              style={{ marginLeft: "0px", color: "#E3E3E3" }}
            />
          </ListItem>
        ) : (
          <></>
        )}
        <ListItem disableGutters>
          <ListItemButton onClick={handleLogout} disableRipple>
            <ListItemIcon style={{ minWidth: "0px", paddingRight: "10px" }}>
              <LogoutIcon style={{ color: "C4C4C4" }} />
            </ListItemIcon>
            <ListItemText primary="Logout" className={classes.label} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state),
  supportUser: selectSupportUser(state),
  loading: selectLoading(state),
});

const mapDispatchToProps = {
  onSwitchOrgs: switchOrgs,
};

const Navigation = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationComponent);

export { Navigation };
