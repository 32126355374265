import { call, takeLatest, put } from "redux-saga/effects";

import * as API from "api";
import { fetchAppStart, fetchAppSuccess } from "store/app/actions";
import {
  AuthActionType,
  fetchAuthStart,
  fetchAuthSuccess,
  fetchAuthFailure,
  setUser,
  setSupportUser,
  setOrganization,
  fetchSupportAuthStart,
  fetchSupportAuthSuccess,
  fetchSupportAuthFailure,
} from "./actions";
import { SetOrganizationNotificationChannel, SwitchOrgs } from "../../types";

export function* getUser() {
  try {
    yield put(fetchAuthStart());
    const user = yield call(API.getUser);
    yield put(fetchAuthSuccess());
    yield put(setUser(user));
  } catch (error) {
    yield put(fetchAuthFailure(error as string));
  }
}

export function* setOrganizationNotificationChannel(
  action: SetOrganizationNotificationChannel
) {
  try {
    yield put(fetchAppStart());
    const { data: org } = yield call(
      API.setOrganizationNotificationChannel,
      action.params
    );
    yield put(fetchAppSuccess());
    yield put(setOrganization(org));
  } catch (error) {
    yield put(fetchAuthFailure(error as string));
  }
}

export function* setConnectMoreAppsVisible() {
  try {
    yield put(fetchAppStart());
    const { data: org } = yield call(API.setConnectMoreAppsVisible);
    yield put(fetchAppSuccess());
    yield put(setOrganization(org));
  } catch (error) {
    console.log(error);
    yield put(fetchAuthFailure(error as string));
  }
}

export function* getSupportUser() {
  try {
    yield put(fetchSupportAuthStart());
    const user = yield call(API.getSupportUser);
    yield put(setSupportUser(user));
    yield put(fetchSupportAuthSuccess());
  } catch (error) {
    yield put(fetchSupportAuthFailure(error as string));
  }
}

export function* switchOrgs(action: SwitchOrgs) {
  try {
    yield put(fetchAppStart());
    yield call(API.switchOrgs, action.params);
    yield put(fetchAppSuccess());
  } catch (error) {
    yield put(fetchAuthFailure(error as string));
  }
}

export function* watchGetUser() {
  yield takeLatest(AuthActionType.GET_USER, getUser);
}

export function* watchGetSupportUser() {
  yield takeLatest(AuthActionType.GET_SUPPORT_USER, getSupportUser);
}

export function* watchSetOrganizationNotificationChannel() {
  yield takeLatest(
    AuthActionType.SET_ORGANIZATION_NOTIFICATION_CHANNEL,
    setOrganizationNotificationChannel
  );
}

export function* watchSwitchOrgs() {
  yield takeLatest(AuthActionType.SWITCH_ORGS, switchOrgs);
}

export function* watchSetConnectMoreAppsVisible() {
  yield takeLatest(
    AuthActionType.SET_CONNECT_MORE_APPS_VISIBLE,
    setConnectMoreAppsVisible
  );
}
