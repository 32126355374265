import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  selectScanners,
  selectTrackedItemsCount,
  selectInstalledIntegrations,
  selectNotifications,
  selectIntegrationsLoading,
  selectScannersLoading,
  selectTrackedItemsCountLoading,
  selectHomepageOrdersGrossSales,
  selectChannels,
  selectNotificationsLoading,
  selectNotificationsSaveLoading,
  selectCustomScanners,
  selectCustomScannersLoading,
  selectCustomScannersSaving,
  selectOpenIssuesLoading,
  selectResolvedIssuesLoading,
  selectHomepageLoading,
  selectOpenIssues,
  selectResolvedIssues,
  selectOrgUsers,
} from "store/app/selectors";
import { Container } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import {
  getOpenIssues,
  getResolvedIssues,
  getScanners,
  getTrackedItemsCount,
  getInstalledIntegrations,
  getNotifications,
  getCustomScanners,
  postHomepageData,
  getSlackChannels,
  getOrgUsers,
} from "store/app/actions";
import { Spinner, TextSmallHeader } from "components";
import {
  IssueType,
  ScannerType,
  InstalledIntegrationsResponseType,
  Notification,
  CustomScannerType,
  PostBatchQueryRequestParams,
  Organization,
  User,
} from "types";
import { setConnectMoreAppsVisible } from "store/auth/actions";
import { selectOrganization, selectSupportUser } from "store/auth/selectors";
import { AnalyticsContext } from "index";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { IssuesHeader } from "./issuesHeader";
import { IssuesTable } from "./issuesTable";
import { IssuesSidebar } from "./issuesSidebar";
import { IssuePivotSelectorComponent } from "./issuePivotSelector";
import { IssuesGetStarted } from "./IssuesGetStarted";
import { IssuesAddMoreIntegrations } from "./IssuesAddMoreIntegrations";
import { IssueNotificationModal } from "./IssueNotificationModal";
import { EditScannerModal } from "./EditScannerModal";

const useStyles = makeStyles(() => ({
  topContainer: {
    padding: "0px",
    margin: "0px",
  },
  contentConainer: {
    margin: "0px",
    padding: "0px !important",
    width: "100% !important",
    display: "flex !important",
    flexDirection: "column",
    gap: "10px",
    paddingTop: "20px !important",
  },
  tableHeaderContainer: {
    display: "flex !important",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "10px",
    padding: "0px !important",
  },
  tableTextHeader: {
    display: "flex !important",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0px !important",
  },
}));

export const Issues = ({
  onGetOpenIssues,
  onGetResolvedIssues,
  onGetScanners,
  onGetTrackedItemsCount,
  trackedItemsCount,
  trackedItemsCountIsLoading,
  openIssues,
  resolvedIssues,
  scanners,
  openIssuesLoading,
  resolvedIssuesLoading,
  homepageLoading,
  scannersIsLoading,
  installedIntegrationsIsLoading,
  onGetInstalledIntegrations,
  installedIntegrations,
  onGetNotifications,
  notifications,
  notificationsIsLoading,
  notificationsIsSaving,
  onGetCustomScanners,
  customScanners,
  customScannersLoading,
  customScannersSaving,
  onPostHomepageData,
  homepageOrdersGrossSales,
  onGetSlackChannels,
  channels,
  onSetConnectMoreAppsVisible,
  organization,
  onGetUsersInOrg,
  orgUsers,
  supportUser,
}: IssuesProps) => {
  const [sidebarFilter, setSidebarFilter] = useState<string>("");
  const [availableFilters, setAvailableFilters] = useState<string[]>([]);
  const [notificationFilter, setNotificationFilter] = useState<string>("");
  const [editScannerFilter, setEditScannerFilter] = useState<string>("");
  const [pivotSelection, setPivotSelection] = useState<string>("Issue Type");
  const [wasIssueDismissed, setWasIssueDismissed] = useState(false);
  const [sidebarClosed, setSidebarClosed] = useState(false);
  const [lastUpdated, setLastUpdated] = useState<Date>(new Date());
  const [onboardingFinished, setOnboardingFinished] = useState(false);
  const { analytics } = useContext(AnalyticsContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { pivot, issueType } = queryString.parse(search) as {
    pivot: string;
    issueType: string;
  };

  useEffect(() => {
    if (!supportUser) {
      analytics.page("Issues");
    }
  }, []);

  useEffect(() => {
    if (homepageOrdersGrossSales == -1) {
      onPostHomepageData({
        timeframe: "-1y",
        queries: [
          {
            id: "orders.gross_sales",
            metric_name: "orders.gross_sales",
            filter_tags: "",
            group_by_tags: "",
            graph_type: "highlight",
          },
        ],
      });
      setLastUpdated(new Date());
    }
  }, [homepageOrdersGrossSales]);

  useEffect(() => {
    if (openIssues.length === 0) {
      onGetOpenIssues();
      setLastUpdated(new Date());
    }
  }, [JSON.stringify(openIssues)]);

  useEffect(() => {
    if (resolvedIssues.length === 0) {
      onGetResolvedIssues();
      setLastUpdated(new Date());
    }
  }, [JSON.stringify(resolvedIssues)]);

  useEffect(() => {
    if (trackedItemsCount === -1) {
      onGetTrackedItemsCount();
      setLastUpdated(new Date());
    }
  }, [trackedItemsCount]);

  useEffect(() => {
    if (scanners.length === 0) {
      onGetScanners();
      setLastUpdated(new Date());
    }
  }, [JSON.stringify(scanners)]);

  useEffect(() => {
    if (installedIntegrations.count === -1) {
      onGetInstalledIntegrations();
      setLastUpdated(new Date());
    }
  }, [JSON.stringify(installedIntegrations)]);

  useEffect(() => {
    if (notifications.length === 0) {
      onGetNotifications();
      setLastUpdated(new Date());
    }
  }, [JSON.stringify(notifications)]);

  useEffect(() => {
    if (customScanners.length === 0) {
      onGetCustomScanners();
      setLastUpdated(new Date());
    }
  }, [JSON.stringify(customScanners)]);

  useEffect(() => {
    if (channels.length === 0) {
      onGetSlackChannels();
      setLastUpdated(new Date());
    }
  }, [JSON.stringify(channels)]);

  useEffect(() => {
    if (orgUsers.length === 0) {
      onGetUsersInOrg();
      setLastUpdated(new Date());
    }
  }, [JSON.stringify(orgUsers)]);

  useEffect(() => {
    if (sidebarClosed) {
      if (wasIssueDismissed) {
        onGetOpenIssues();
        onGetResolvedIssues();
        setWasIssueDismissed(false);
      }
      setSidebarFilter("");
      navigate(`/issues?pivot=${pivotSelection.replace(/ /g, "_")}`);
    }
  }, [wasIssueDismissed, sidebarClosed]);

  useEffect(() => {
    if (issueType !== undefined) {
      setSidebarFilter(issueType);
      setSidebarClosed(false);
    }
  }, [issueType]);

  const formattedIntegrations = installedIntegrations.integrations.map(
    (integration) => {
      if (integration === "amazon_sp") {
        return "amazon";
      }
      return integration;
    }
  );

  useEffect(() => {
    if (pivot !== undefined) {
      const pivotFormatted = pivot.replace(/_/g, " ");
      setPivotSelection(pivotFormatted);
    }
    if (pivot === "Integration") {
      setAvailableFilters(formattedIntegrations);
    }
  }, [pivot]);

  useEffect(() => {
    if (onboardingFinished) {
      onGetOpenIssues();
      onGetResolvedIssues();
      onGetScanners();
      onGetTrackedItemsCount();
      onGetInstalledIntegrations();
      onGetNotifications();
      onGetCustomScanners();
      setLastUpdated(new Date());
    }
  }, [onboardingFinished]);

  const handlePivotSelection = (
    event: React.MouseEvent<HTMLElement>,
    newSelection: string
  ) => {
    setPivotSelection(newSelection || pivotSelection);
    navigate(
      `/issues?pivot=${
        newSelection.replace(/ /g, "_") || pivotSelection.replace(/ /g, "_")
      }`
    );
  };

  const handleSidebarSelection = (newSelection: string) => {
    setSidebarFilter(newSelection || sidebarFilter);
    navigate(
      `/issues?pivot=${pivotSelection.replace(/ /g, "_")}&issueType=${
        newSelection || sidebarFilter
      }`
    );
  };

  const refreshPage = () => {
    onGetOpenIssues();
    onGetResolvedIssues();
    onGetScanners();
    onGetTrackedItemsCount();
    onGetNotifications();
    onGetCustomScanners();
    onPostHomepageData({
      timeframe: "-1y",
      queries: [
        {
          id: "orders.gross_sales",
          metric_name: "orders.gross_sales",
          filter_tags: "",
          group_by_tags: "",
          graph_type: "highlight",
        },
      ],
    });
    setLastUpdated(new Date());
  };

  return (
    <Container maxWidth={false} className={classes.topContainer}>
      <IssuesHeader
        openIssues={openIssues}
        resolvedIssues={resolvedIssues}
        grossSales={homepageOrdersGrossSales}
        trackedItemsCountLoading={trackedItemsCountIsLoading}
        openIssuesLoading={openIssuesLoading}
        resolvedIssuesLoading={resolvedIssuesLoading}
        homepageLoading={homepageLoading}
        lastUpdated={lastUpdated}
        refreshCallback={refreshPage}
        onboardingFinished={onboardingFinished}
        trackedItemsCount={trackedItemsCount}
      />
      {installedIntegrationsIsLoading ? (
        <Spinner center />
      ) : installedIntegrations.count === 0 ? (
        <IssuesGetStarted
          handleOnboardingFinish={() => setOnboardingFinished(true)}
        />
      ) : (
        <Container maxWidth={false} className={classes.contentConainer}>
          <Container maxWidth={false} className={classes.tableHeaderContainer}>
            <TextSmallHeader>Group By</TextSmallHeader>
            <IssuePivotSelectorComponent
              pivotSelection={pivotSelection}
              handleChange={handlePivotSelection}
            />
          </Container>
          <IssuesTable
            issues={openIssues}
            scanners={scanners}
            installedIntegrations={formattedIntegrations}
            notifications={notifications}
            loading={
              openIssuesLoading ||
              notificationsIsLoading ||
              scannersIsLoading ||
              notificationsIsSaving ||
              customScannersSaving
            }
            selectHandler={handleSidebarSelection}
            setNotificationFilter={setNotificationFilter}
            setEditScannerFilter={setEditScannerFilter}
            pivotSelection={pivotSelection}
          />
          {organization.connectMoreAppsVisible && (
            <IssuesAddMoreIntegrations
              installedIntegrations={installedIntegrations.integrations}
              onSetConnectMoreAppsVisible={onSetConnectMoreAppsVisible}
            />
          )}
        </Container>
      )}
      <Container>
        <IssuesSidebar
          loading={openIssuesLoading}
          issues={openIssues}
          issueFilter={sidebarFilter}
          availableFilters={availableFilters}
          pivotSelection={pivotSelection}
          setDismissIssue={setWasIssueDismissed}
          setSidebarClosed={setSidebarClosed}
        />
      </Container>
      <IssueNotificationModal
        loading={notificationsIsLoading || notificationsIsSaving}
        issueFilter={notificationFilter}
        slackChannels={channels}
        notifications={notifications}
        setNotificationFilter={setNotificationFilter}
        orgUsers={orgUsers}
      />
      <EditScannerModal
        loading={customScannersLoading || customScannersSaving}
        scanner_type={editScannerFilter}
        setEditScannerFilter={setEditScannerFilter}
        customScanners={customScanners}
      />
    </Container>
  );
};

interface IssuesProps {
  onGetOpenIssues: () => void;
  onGetResolvedIssues: () => void;
  onGetScanners: () => void;
  onGetTrackedItemsCount: () => void;
  trackedItemsCount: number;
  trackedItemsCountIsLoading: boolean;
  openIssues: IssueType[];
  resolvedIssues: IssueType[];
  scanners: ScannerType[];
  openIssuesLoading: boolean;
  resolvedIssuesLoading: boolean;
  homepageLoading: boolean;
  scannersIsLoading: boolean;
  installedIntegrationsIsLoading: boolean;
  installedIntegrations: InstalledIntegrationsResponseType;
  onGetInstalledIntegrations: () => void;
  onGetNotifications: () => void;
  notifications: Notification[];
  notificationsIsLoading: boolean;
  notificationsIsSaving: boolean;
  onGetCustomScanners: () => void;
  customScanners: CustomScannerType[];
  customScannersLoading: boolean;
  customScannersSaving: boolean;
  onPostHomepageData: (params: PostBatchQueryRequestParams) => void;
  homepageOrdersGrossSales: number;
  channels: string[];
  onGetSlackChannels: () => void;
  onSetConnectMoreAppsVisible: () => void;
  organization: Organization;
  onGetUsersInOrg: () => void;
  orgUsers: User[];
  supportUser: User;
}

const mapStateToProps = (state) => ({
  openIssues: selectOpenIssues(state),
  resolvedIssues: selectResolvedIssues(state),
  scanners: selectScanners(state),
  openIssuesLoading: selectOpenIssuesLoading(state),
  resolvedIssuesLoading: selectResolvedIssuesLoading(state),
  homepageLoading: selectHomepageLoading(state),
  scannersIsLoading: selectScannersLoading(state),
  trackedItemsCount: selectTrackedItemsCount(state),
  trackedItemsCountIsLoading: selectTrackedItemsCountLoading(state),
  installedIntegrationsIsLoading: selectIntegrationsLoading(state),
  installedIntegrations: selectInstalledIntegrations(state),
  notifications: selectNotifications(state),
  notificationsIsLoading: selectNotificationsLoading(state),
  notificationsIsSaving: selectNotificationsSaveLoading(state),
  customScanners: selectCustomScanners(state),
  customScannersLoading: selectCustomScannersLoading(state),
  customScannersSaving: selectCustomScannersSaving(state),
  homepageOrdersGrossSales: selectHomepageOrdersGrossSales(state),
  channels: selectChannels(state),
  organization: selectOrganization(state),
  orgUsers: selectOrgUsers(state),
  supportUser: selectSupportUser(state),
});

const mapDispatchToProps = {
  onGetOpenIssues: getOpenIssues,
  onGetResolvedIssues: getResolvedIssues,
  onGetScanners: getScanners,
  onGetTrackedItemsCount: getTrackedItemsCount,
  onGetInstalledIntegrations: getInstalledIntegrations,
  onGetNotifications: getNotifications,
  onGetCustomScanners: getCustomScanners,
  onPostHomepageData: postHomepageData,
  onGetSlackChannels: getSlackChannels,
  onGetUsersInOrg: getOrgUsers,
  onSetConnectMoreAppsVisible: setConnectMoreAppsVisible,
};

export const IssuesPage = connect(mapStateToProps, mapDispatchToProps)(Issues);
