import React from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import styled from "styled-components";

const SelectContainer = styled.div`
  width: auto;
  height: 32px;
  display: flex;
  padding: 0;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  border: 1px solid #c4c4c4;
  padding: 1px;
  margin-left: 10px;
`;

const styles = () =>
  createStyles({
    formControl: {
      display: "flex",
      backgroundColor: "transparent",
      width: "100px",
      fontSize: 12,
      color: "#C4C4C4",
      padding: 2,
      border: "none",
      cursor: "pointer",
    },
  });

export interface SelectBoxProps extends WithStyles<typeof styles> {
  value: any;
  options: any[];
  onChange: (value: any) => void;
  style?: any;
  placeholder?: any;
}

const SelectBoxComponent = ({
  value,
  options,
  onChange,
  classes,
  style,
  placeholder,
}: SelectBoxProps) => (
  <SelectContainer style={style}>
    <select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={classes.formControl}
    >
      {placeholder ? (
        <option value="" disabled hidden>
          {placeholder}
        </option>
      ) : (
        <></>
      )}
      {options.map((option) => (
        <option
          value={option.value}
          key={option.value}
          disabled={!option.value}
        >
          {option.label}
        </option>
      ))}
    </select>
  </SelectContainer>
);

const SelectBox = withStyles(styles)(SelectBoxComponent);

export { SelectBox };
