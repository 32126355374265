import { all, fork } from "redux-saga/effects";
import * as auth from "./auth/sagas";
import * as app from "./app/sagas";

export function* rootSaga() {
  yield all([
    fork(auth.watchGetUser),
    fork(auth.watchGetSupportUser),
    fork(auth.watchSetOrganizationNotificationChannel),
    fork(auth.watchSwitchOrgs),
    fork(auth.watchSetConnectMoreAppsVisible),

    fork(app.watchGetExplorerViews),
    fork(app.watchPostExplorerView),
    fork(app.watchPutExplorerView),
    fork(app.watchDeleteExplorerView),

    fork(app.watchPostBatchExplorerData),

    fork(app.watchGetAvailableMetrics),

    fork(app.watchGetAllTagsForMetrics),

    fork(app.watchPostAmazonOauthState),

    fork(app.watchInstallIntegration),
    fork(app.watchUninstallIntegration),
    fork(app.watchUpdateIntegration),
    fork(app.watchGetInstalledIntegrations),
    fork(app.watchGetSlackChannels),
    fork(app.watchGetOrgUsers),
    fork(app.watchGetIntegration),
    fork(app.watchPostHomepageData),

    fork(app.watchGetOpenIssues),
    fork(app.watchGetResolvedIssues),
    fork(app.watchGetScanners),
    fork(app.watchGetTrackedItemsCount),
    fork(app.watchGetNotifications),
    fork(app.watchPutNotifications),
    fork(app.watchGetCustomScanners),
    fork(app.watchSaveCustomScanners),
  ]);
}
