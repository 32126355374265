import produce from "immer";
import {
  AuthState,
  AuthAction,
  FetchAuthFailure,
  SetUser,
  SetOrganization,
  FetchSupportAuthFailure,
} from "types";
import { AuthActionType } from "./actions";

const INITIAL_STATE: AuthState = {
  loading: false,
  supportUserLoading: false,
  error: null,
  user: undefined,
  supportUser: undefined,
};

export const auth = produce((draft, action: AuthAction) => {
  switch (action.type) {
    case AuthActionType.FETCH_AUTH_START:
      draft.loading = true;
      break;
    case AuthActionType.FETCH_AUTH_SUCCESS:
      draft.loading = false;
      draft.error = null;
      break;
    case AuthActionType.FETCH_AUTH_FAILURE:
      const { error } = action as FetchAuthFailure;
      draft.loading = false;
      draft.error = error;
      break;
    case AuthActionType.FETCH_SUPPORT_AUTH_START:
      draft.supportUserLoading = true;
      break;
    case AuthActionType.FETCH_SUPPORT_AUTH_SUCCESS:
      draft.supportUserLoading = false;
      draft.error = null;
      break;
    case AuthActionType.FETCH_SUPPORT_AUTH_FAILURE:
      const { error: supportError } = action as FetchSupportAuthFailure;
      draft.supportUserLoading = false;
      draft.error = supportError;
      break;
    case AuthActionType.SET_USER:
      const { user } = action as SetUser;
      draft.user = user;
      break;
    case AuthActionType.SET_SUPPORT_USER:
      draft.supportUser = (action as SetUser).user;
      break;
    case AuthActionType.SET_ORG:
      if (draft.user && draft.user.organizations) {
        draft.user.currentOrganization = (action as SetOrganization).org;
        // need to do this so changes captured on reload.
        // Otherwise on reload, since the user exists in local storage, the updated org state is not reflected.
        localStorage.setItem("user", JSON.stringify(draft.user));
      }
      break;
    default:
      return draft;
  }
}, INITIAL_STATE);
