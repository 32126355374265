import { call, takeLatest, put, all, delay } from "redux-saga/effects";
import { push } from "connected-react-router";
import moment from "moment";
import * as types from "types";
import * as API from "api";
import { NON_SUMMABLE_METRICS } from "constants/index";
import * as acts from "./actions";
import { AppActionType } from "./actions";

function* getExplorerViews() {
  try {
    yield put(acts.fetchExplorerViewsStart());
    const { data: explorerViews } = yield call(API.getExplorerViews);
    yield put(acts.setExplorerViews(explorerViews));
    yield put(acts.fetchExplorerViewsSuccess());
  } catch (error) {
    yield put(acts.fetchExplorerDataFailure(error as string));
  }
}

function* postExplorerView(action: types.PostExplorerView) {
  try {
    yield put(acts.saveExplorerViewStart());
    const { data: newExplorerView } = yield call(
      API.postExplorerView,
      action.params
    );
    yield put(acts.saveExplorerViewSuccess());
    yield put(acts.setExplorerView(newExplorerView));
    yield put(push(`/explorer?view=${newExplorerView.id}`));
  } catch (error) {
    yield put(acts.saveExplorerViewFailure(error as string));
  }
}

function* putExplorerView(action: types.PutExplorerView) {
  try {
    yield put(acts.saveExplorerViewStart());
    const { data: newExplorerView } = yield call(
      API.putExplorerView,
      action.params
    );
    yield put(acts.saveExplorerViewSuccess());
    yield put(acts.setExplorerView(newExplorerView));
    yield put(push(`/explorer?view=${newExplorerView.id}`));
  } catch (error) {
    yield put(acts.saveExplorerViewFailure(error as string));
  }
}

function* deleteExplorerView(action: types.DeleteExplorerView) {
  try {
    yield put(acts.saveExplorerViewStart());
    yield call(API.deleteExplorerView, action.params);
    yield delay(500);
    yield put(acts.saveExplorerViewSuccess());
    yield put(push("/explorer/"));
  } catch (error) {
    yield put(acts.saveExplorerViewFailure(error as string));
  }
}

function* postBatchExplorerData(action: types.PostBatchExplorerData) {
  try {
    const totalParams = { ...action.params };
    totalParams.queries = totalParams.queries.map((query) => ({
      ...query,
      graph_type: "highlight",
      group_by_tags: "",
    }));
    // filter totalParams.queries to only include queries where metric is not a GAUGE_METRIC
    totalParams.queries = totalParams.queries.filter(
      (query) =>
        query.metric_name && !NON_SUMMABLE_METRICS.includes(query.metric_name)
    );
    yield put(acts.fetchExplorerDataStart());
    const apiCallsToMake = [call(API.postBatchQuery, action.params)];
    if (totalParams.queries.length > 0) {
      apiCallsToMake.push(call(API.postBatchQuery, totalParams));
    }
    const yieldedData = yield all(apiCallsToMake);
    const metricData = yieldedData[0].data;
    const totalData = (yieldedData[1] && yieldedData[1].data) || [];
    yield put(acts.setExplorerData(metricData));
    yield put(acts.setExplorerTotalData(totalData));
    yield put(acts.fetchExplorerDataSuccess());
  } catch (error) {
    yield put(acts.fetchExplorerDataFailure(error as string));
  }
}

function* postHomepageData(action: types.PostHomepageData) {
  try {
    yield put(acts.homepageInitFetchStart());
    const { data: metricData } = yield call(API.postBatchQuery, action.params);
    yield put(acts.setHomepageData(metricData));
    yield put(acts.homepageInitFetchSuccess());
  } catch (error) {
    console.log(error);
    yield put(acts.homepageInitFetchFailure(error as string));
  }
}

function* getAvailableMetrics() {
  try {
    yield put(acts.fetchAvailableMetricsStart());
    const { data } = yield call(API.getAvailableMetrics);
    yield put(acts.setAvailableMetrics(data));
    yield put(acts.fetchAvailableMetricsSuccess());
  } catch (error) {
    yield put(acts.fetchAvailableMetricsFailure(error as string));
  }
}

function* getAllTagsForMetrics(action: types.GetAllTagsForMetrics) {
  try {
    yield put(acts.fetchMetricsAllTagsStart());
    let allTagsForMetrics: types.AllTagsForMetrics;
    if (action.params.metrics.length !== 0) {
      const { data: tags } = yield call(
        API.getAllTagsForMetrics,
        action.params
      );
      allTagsForMetrics = {
        tags: tags.tags,
      };
    } else {
      // TODO doesn't work, still sending empty requests
      allTagsForMetrics = { tags: {} };
    }
    yield put(acts.setAllTagsForMetrics(allTagsForMetrics));
    yield put(acts.fetchMetricsAllTagsSuccess());
  } catch (error) {
    yield put(acts.fetchMetricsAllTagsFailure(error as string));
  }
}

function* postAmazonOauthState(action: types.PostAmazonOauthState) {
  try {
    yield put(acts.amazonOauthStart());
    yield call(API.postAmazonOauthState, action.params);
    yield put(acts.amazonOauthSuccess());
  } catch (error) {
    yield put(acts.amazonOauthFailure(error as string));
  }
}

function* installIntegration(action: types.InstallIntegration) {
  try {
    yield put(acts.installingIntegrationStart());
    yield put(acts.setInstallIntegrationMessage(""));
    const { data } = yield call(API.installIntegration, action.params);
    yield put(acts.setInstallIntegrationMessage(data));
    yield put(acts.installingIntegrationSuccess());
  } catch (error: any) {
    yield put(
      acts.setInstallIntegrationMessage(error.response.data.message as string)
    );
    yield put(acts.installingIntegrationFailure(error as string));
  }
}

function* updateIntegration(action: types.UpdateIntegration) {
  try {
    yield put(acts.updatingIntegrationStart());
    yield put(acts.setUpdateIntegrationMessage(""));
    const { data } = yield call(API.updateIntegration, action.params);
    yield put(acts.setUpdateIntegrationMessage(data));
    yield put(acts.updatingIntegrationSuccess());
  } catch (error: any) {
    yield put(
      acts.setUpdateIntegrationMessage(error.response.data.message as string)
    );
    yield put(acts.updatingIntegrationFailure(error as string));
  }
}

function* uninstallIntegration(action: types.UninstallIntegration) {
  try {
    const { data } = yield call(API.uninstallIntegration, action.params);
    yield put(acts.setUninstallIntegrationMessage(data));
  } catch (error: any) {
    yield put(acts.setUninstallIntegrationMessage(error.response.data.message));
  }
}
function* getInstalledIntegrations() {
  try {
    yield put(acts.fetchInstalledIntegrationsStart());
    const { data: installedIntegrations } = yield call(
      API.getInstalledIntegrations
    );
    yield put(acts.setInstalledIntegrations(installedIntegrations));
    yield put(acts.fetchInstalledIntegrationsSuccess());
  } catch (error) {
    yield put(acts.fetchInstalledIntegrationsFailure(error as string));
  }
}
function* getSlackChannels() {
  try {
    yield put(acts.fetchSlackChannelsStart());
    const { data: channels } = yield call(API.getSlackChannels);
    yield put(acts.setSlackChannels(channels));
    yield put(acts.fetchSlackChannelsSuccess());
  } catch (error) {
    yield put(acts.fetchSlackChannelsFailure(error as string));
  }
}

function* getOrgUsers() {
  try {
    yield put(acts.fetchAppStart());
    const {
      data: { users },
    } = yield call(API.getUsersInOrg);
    yield put(acts.setOrgUsers(users));
    yield put(acts.fetchAppSuccess());
  } catch (error) {
    yield put(acts.fetchAppFailure(error as string));
  }
}

function* getIntegration(action: types.GetIntegrationData) {
  try {
    yield put(acts.fetchIntegrationStart());
    const { data: integration } = yield call(API.getIntegration, action.params);
    yield put(acts.setIntegration(integration));
    yield put(acts.fetchIntegrationSuccess());
  } catch (error) {
    yield put(acts.fetchIntegrationFailure(error as string));
  }
}

function* getOpenIssues() {
  try {
    yield put(acts.fetchOpenIssuesStart());
    const openIssuesFiltesr = {
      dismissed: "False",
      status: "TRIGGERED",
      projected_daily_impact: ">0",
    };
    const {
      data: { data: issues },
    } = yield call(API.getIssues, openIssuesFiltesr);
    yield put(acts.setOpenIssues(issues));
    yield put(acts.fetchOpenIssuesSuccess());
  } catch (error) {
    yield put(acts.fetchOpenIssuesFailure(error as string));
  }
}

function* getResolvedIssues() {
  try {
    yield put(acts.fetchResolvedIssuesStart());
    const yearAgo = moment().subtract(1, "year").unix();
    const resolvedIssuesFilters = {
      dismissed: "False",
      status: "OK",
      credit_taken: "True",
      actual_dollar_impact: ">0",
      resolved_at: `>${yearAgo}`,
    };
    const {
      data: { data: issues },
    } = yield call(API.getIssues, resolvedIssuesFilters);
    yield put(acts.setResolvedIssues(issues));
    yield put(acts.fetchResolvedIssuesSuccess());
  } catch (error) {
    yield put(acts.fetchResolvedIssuesFailure(error as string));
  }
}

function* getScanners() {
  try {
    yield put(acts.fetchScannersStart());
    const {
      data: { data: scanners },
    } = yield call(API.getScanners);
    yield put(acts.setScanners(scanners));
    yield put(acts.fetchScannersSuccess());
  } catch (error) {
    yield put(acts.fetchScannersFailure(error as string));
  }
}

function* getTrackedItemsCount() {
  try {
    yield put(acts.fetchTrackedItemsCountStart());
    const {
      data: { data: count },
    } = yield call(API.getTrackedItemsCount);
    yield put(acts.setTrackedItemsCount(count));
    yield put(acts.fetchTrackedItemsCountSuccess());
  } catch (error) {
    yield put(acts.fetchTrackedItemsCountFailure(error as string));
  }
}

function* getNotifications() {
  try {
    yield put(acts.fetchNotificationsStart());
    const {
      data: { data: notifications },
    } = yield call(API.getNotifications);
    yield put(acts.setNotifications(notifications));
    yield put(acts.fetchNotificationsSuccess());
  } catch (error) {
    yield put(acts.fetchNotificationsFailure(error as string));
  }
}

function* putNotifications(action: types.PutNotifications) {
  try {
    yield put(acts.saveNotificationsStart());
    yield put(acts.setUpdateNotificationsMessage(""));
    const { data } = yield call(API.putNotifications, action.params);
    yield put(acts.setUpdateNotificationsMessage(data));
    const {
      data: { data: notifications },
    } = yield call(API.getNotifications);
    yield put(acts.setNotifications(notifications));
    yield put(acts.saveNotificationsSuccess());
  } catch (error: any) {
    yield put(
      acts.setUpdateNotificationsMessage(error.response.data.messgae as string)
    );
    yield put(acts.saveNotificationsFailure(error as string));
  }
}

function* getCustomScanners() {
  try {
    yield put(acts.fetchCustomScannersStart());
    const {
      data: { data: scanners },
    } = yield call(API.getCustomScanners);
    yield put(acts.setCustomScanners(scanners));
    yield put(acts.fetchCustomScannersSuccess());
  } catch (error) {
    yield put(acts.fetchCustomScannersFailure(error as string));
  }
}

function* saveCustomScanners(action: types.SaveCustomScanners) {
  try {
    yield put(acts.saveCustomScannersStart());
    yield call(API.putCustomScanners, action.scanners);
    const {
      data: { data: scanners },
    } = yield call(API.getCustomScanners);
    yield put(acts.setCustomScanners(scanners));
    yield put(acts.saveCustomScannersSuccess());
  } catch (error) {
    yield put(acts.saveCustomScannersFailure(error as string));
  }
}

export function* watchGetExplorerViews() {
  yield takeLatest(AppActionType.GET_EXPLORER_VIEWS, getExplorerViews);
}

export function* watchPostExplorerView() {
  yield takeLatest(AppActionType.POST_EXPLORER_VIEW, postExplorerView);
}
export function* watchPutExplorerView() {
  yield takeLatest(AppActionType.PUT_EXPLORER_VIEW, putExplorerView);
}
export function* watchDeleteExplorerView() {
  yield takeLatest(AppActionType.DELETE_EXPLORER_VIEW, deleteExplorerView);
}

export function* watchPostHomepageData() {
  yield takeLatest(AppActionType.POST_HOMEPAGE_DATA, postHomepageData);
}

export function* watchPostBatchExplorerData() {
  yield takeLatest(
    AppActionType.POST_BATCH_EXPLORER_DATA,
    postBatchExplorerData
  );
}

export function* watchGetAvailableMetrics() {
  yield takeLatest(AppActionType.GET_AVAILABLE_METRICS, getAvailableMetrics);
}

export function* watchGetAllTagsForMetrics() {
  yield takeLatest(
    AppActionType.GET_ALL_TAGS_FOR_METRICS,
    getAllTagsForMetrics
  );
}

export function* watchPostAmazonOauthState() {
  yield takeLatest(AppActionType.POST_AMAZON_OAUTH_STATE, postAmazonOauthState);
}

export function* watchInstallIntegration() {
  yield takeLatest(AppActionType.INSTALL_INTEGRATION, installIntegration);
}

export function* watchUpdateIntegration() {
  yield takeLatest(AppActionType.UPDATE_INTEGRATION, updateIntegration);
}

export function* watchUninstallIntegration() {
  yield takeLatest(AppActionType.UNINSTALL_INTEGRATION, uninstallIntegration);
}

export function* watchGetInstalledIntegrations() {
  yield takeLatest(
    AppActionType.GET_INSTALLED_INTEGRATIONS,
    getInstalledIntegrations
  );
}

export function* watchGetSlackChannels() {
  yield takeLatest(AppActionType.GET_SLACK_CHANNELS, getSlackChannels);
}

export function* watchGetOrgUsers() {
  yield takeLatest(AppActionType.GET_USERS_IN_ORG, getOrgUsers);
}

export function* watchGetIntegration() {
  yield takeLatest(AppActionType.GET_INTEGRATION, getIntegration);
}

export function* watchGetOpenIssues() {
  yield takeLatest(AppActionType.GET_OPEN_ISSUES, getOpenIssues);
}
export function* watchGetResolvedIssues() {
  yield takeLatest(AppActionType.GET_RESOLVED_ISSUES, getResolvedIssues);
}

export function* watchGetScanners() {
  yield takeLatest(AppActionType.GET_SCANNERS, getScanners);
}

export function* watchGetTrackedItemsCount() {
  yield takeLatest(AppActionType.GET_TRACKED_ITEMS_COUNT, getTrackedItemsCount);
}

export function* watchGetNotifications() {
  yield takeLatest(AppActionType.GET_NOTIFICATIONS, getNotifications);
}

export function* watchPutNotifications() {
  yield takeLatest(AppActionType.PUT_NOTIFICATIONS, putNotifications);
}

export function* watchGetCustomScanners() {
  yield takeLatest(AppActionType.GET_CUSTOM_SCANNERS, getCustomScanners);
}

export function* watchSaveCustomScanners() {
  yield takeLatest(AppActionType.SAVE_CUSTOM_SCANNERS, saveCustomScanners);
}
