import React, { useEffect, useContext, useRef, useState } from "react";
import {
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import { IssueType } from "types";
import { formatTimestampIntoDatestring } from "utils";
import { ISSUE_TYPE_DESCRIPTION } from "constants/index";
import { AnalyticsContext } from "index";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import InsertChartOutlinedSharp from "@mui/icons-material/InsertChartOutlinedSharp";
import { SvgIcon } from "../../components/atoms/commons/SvgIcon";

const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: "#fafafa",
    border: "1px solid #E3E3E3",
    boxShadow: "none",
    borderRadius: "5px",
  },
  glow: {
    boxShadow: "0px 0px 22px #177EC2",
  },
  dateTime: {
    fontSize: "14px",
  },
  title: {
    fontSize: "16px",
    fontWeight: 500,
    overflowWrap: "anywhere",
  },
  labels: {
    fontSize: "14px",
    fontWeight: 500,
    width: "150px",
    minWidth: "150px",
  },
  issueDetailContainer: {
    display: "flex",
    gap: "30px",
    padding: 0,
  },
  lossValue: {
    fontSize: "24px",
    fontWeight: 500,
    color: "#177ec2",
  },
}));

interface IssueSidebarCardProps {
  issue: IssueType;
  dismissIssue: (issueId: string) => void;
  sendUserActions: (issueIds: string[]) => void;
}

export const IssueSidebarCard = ({
  issue,
  dismissIssue,
  sendUserActions,
}: IssueSidebarCardProps) => {
  const classes = useStyles();
  const { analytics } = useContext(AnalyticsContext);
  const scrollToRef = useRef<null | any>(null);
  const { search } = useLocation();
  const { pivot, issueType, issueId } = queryString.parse(search);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (issueId === issue.id) {
      scrollToRef.current.scrollIntoView();
    }
  }, [issueId]);

  const makeURL = (value: any) => {
    if (typeof value !== "string") {
      return value;
    }
    const URL_REGEX =
      // eslint-disable-next-line no-useless-escape
      /((http|https)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/;
    const printable = value.split(" ").map((part) =>
      URL_REGEX.test(part) ? (
        <a
          key={part}
          href={part.replace(",", "")}
          target="_blank"
          rel="noreferrer"
        >
          {part.replace(",", "")}
        </a>
      ) : (
        <a key={part} target="_blank" rel="noreferrer">
          {part.concat(" ")}
        </a>
      )
    );
    return printable
      .map<React.ReactNode>((t) => <span key={t.key}>{t}</span>)
      .reduce((prev, curr) => [prev, " ", curr]);
  };

  const cleanMetadata = (value) => {
    if (Array.isArray(value)) {
      const maxLen = 3;
      const { length } = value;
      const arr = value.slice(0, maxLen);
      const urlArr = arr.map((item) => makeURL(item));
      let printable = [<span key="key">Not Found</span>];
      if (length > 0) {
        printable = urlArr.reduce((prev, curr) => [prev, ", ", curr]);
      }
      if (length > maxLen) {
        printable.push(<span>, and {length - maxLen} more</span>);
      }
      return printable;
    }
    return makeURL(value);
  };

  var title = issue.title;
  var objectName = makeURL(issue.object_name);
  if (pivot != "Issue_Type") {
    title = "Issue Type";
    objectName = ISSUE_TYPE_DESCRIPTION[issue.issue_type].title;
  }

  return (
    <Grid item key={issue.id} style={{ maxWidth: "100%" }} ref={scrollToRef}>
      <Card className={issueId === issue.id ? classes.glow : classes.card}>
        <CardContent>
          <Container
            style={{
              padding: 0,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography className={classes.dateTime}>
              Triggered At: {formatTimestampIntoDatestring(issue.triggered_at)}
            </Typography>
            <Container
              style={{
                padding: 0,
                margin: 0,
                width: "fit-content",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Typography>
                <span className={classes.labels}>Projected weekly impact</span>
              </Typography>
              <Typography className={classes.lossValue}>
                $
                {Math.round(7 * issue.projected_daily_impact).toLocaleString(
                  "en-US"
                )}
              </Typography>
            </Container>
          </Container>
          <br />
          <Typography
            className={classes.title}
            style={{ textDecoration: "underline" }}
          >
            {title || "Object Name"}
          </Typography>
          <Typography className={classes.title}>{objectName}</Typography>
          <br />
          <Container
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0px 0px 20px 0px",
            }}
          >
            <Container
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 0,
                gap: "10px",
                flexShrink: 3,
              }}
            >
              {pivot != "Issue_Type" && (
                <Container className={classes.issueDetailContainer}>
                  <span className={classes.labels}>{issue.title}</span>
                  <Box style={{ display: "flex", padding: 0 }}>
                    {makeURL(issue.object_name)}
                  </Box>
                </Container>
              )}
              <Container className={classes.issueDetailContainer}>
                <span className={classes.labels}>Platform</span>
                <Box style={{ display: "flex", padding: 0 }}>
                  {issue.platform !== "other" ? (
                    <SvgIcon
                      name={issue.platform}
                      size={20}
                      style={{ marginRight: "5px" }}
                    />
                  ) : null}
                  {issue.platform
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </Box>
              </Container>
              {issue.metadata.map((pair) => {
                const formattedKey = (pair.Key as string)
                  .split("_")
                  .map((word: string) => word[0].toUpperCase() + word.slice(1))
                  .join(" ");
                const formattedValue = cleanMetadata(pair.Value);
                if (!formattedValue) {
                  return null;
                }
                return (
                  <Container
                    key={`${formattedKey}-${formattedValue}`}
                    className={classes.issueDetailContainer}
                  >
                    <span className={classes.labels}>{formattedKey}</span>
                    <span style={{ whiteSpace: "pre-line" }}>
                      {formattedValue}
                    </span>
                  </Container>
                );
              })}
            </Container>
          </Container>
          <Divider />
          <Container
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              padding: "24px 0px 0px 0px",
              whiteSpace: "nowrap",
            }}
          >
            {issue.explorerData && (
              <Button
                style={{
                  borderRadius: 5,
                  border: "1px solid #E3E3E3",
                  color: "black",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  analytics.track("Issue Explorer View Clicked", {
                    issue_type: issue.issue_type,
                    platform: issue.platform,
                    issue_id: issue.id,
                  });
                  // redirect to explorer
                  const urlParams = new URLSearchParams();
                  // add every key value pair to url params
                  Object.keys(issue.explorerData).forEach((key) => {
                    urlParams.append(key, issue.explorerData[key]);
                  });
                  urlParams.append("filter_type", "and");
                  urlParams.append("timeBreakdown", "1d");
                  const gridState = JSON.stringify({
                    sorting: { sortModel: [{ field: "time", sort: "desc" }] },
                  });
                  urlParams.append("gridState", gridState);
                  window.open(`/explorer?${urlParams.toString()}`, "_blank");
                }}
              >
                See in Explorer
                <InsertChartOutlinedSharp style={{ marginLeft: "2px" }} />
              </Button>
            )}
            {issue.CTA && (
              <Button
                style={{
                  borderRadius: 5,
                  border: "1px solid #E3E3E3",
                  color: "black",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  analytics.track("Issue CTA Clicked", {
                    issue_type: issue.issue_type,
                    platform: issue.platform,
                    issue_id: issue.id,
                  });
                  sendUserActions([issue.id]);
                  window.open(issue.CTA, "_blank");
                }}
              >
                Check on{" "}
                {issue.platform.charAt(0).toUpperCase() +
                  issue.platform.slice(1)}
                <OpenInNewIcon
                  style={{
                    width: 20,
                    height: 20,
                    marginLeft: "2px",
                  }}
                />
              </Button>
            )}
            <Button
              style={{
                borderRadius: 5,
                border: "1px solid #E3E3E3",
                color: isCopied ? "green" : "black",
              }}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.host}/issues/?pivot=${pivot}&issueType=${issueType}&issueId=${issue.id}`
                );
                analytics.track("Issue Link Copied", {
                  issue_type: issue.issue_type,
                  platform: issue.platform,
                  issue_id: issue.id,
                });
                setIsCopied(true);
                setTimeout(() => {
                  setIsCopied(false);
                }, 2000);
              }}
            >
              {isCopied ? "Link Copied!" : "Copy Link"}
              <ContentCopyIcon
                style={{
                  width: 20,
                  height: 20,
                  marginLeft: "2px",
                }}
              />
            </Button>
            <Button
              style={{
                borderRadius: 5,
                border: "1px solid #E3E3E3",
                color: "black",
                paddingLeft: 16,
                paddingRight: 16,
              }}
              onClick={() => {
                analytics.track("Issue Dismissed", {
                  issue_type: issue.issue_type,
                  platform: issue.platform,
                  issue_id: issue.id,
                });
                dismissIssue(issue.id);
              }}
            >
              Dismiss
              <DoNotDisturbOnOutlinedIcon
                style={{
                  width: 20,
                  height: 20,
                  marginLeft: "2px",
                }}
              />
            </Button>
          </Container>
        </CardContent>
      </Card>
    </Grid>
  );
};
