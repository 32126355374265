import React from "react";
import { Box, Typography } from "@material-ui/core";
import styled from "styled-components";
import { DOLLAR_SIGN_PREFIX_LIST } from "constants/index";

const Container = styled(Box)`
  width: 100%;
  background: #ffffff;
  padding: 10px 20px 10px 20px;
`;

const HighlightContainer = styled(Box)`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  width: 95%;
  background: #e9f6ff;
  border-radius: 5px;
`;

export interface HighlightProps {
  value: any;
  comparedToValue?: any;
  comparedTo?: string;
  metric: string;
  position?: any;
}

const HighlightComponent = ({
  value,
  metric,
  comparedToValue,
  comparedTo,
  position,
}: HighlightProps) => (
  <Container
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
  >
    <HighlightContainer style={{ height: comparedToValue ? "100%" : "130px" }}>
      {comparedTo !== "none" ? "Current Period" : ""}
      <Typography
        variant="h1"
        style={
          position && position.w <= 3
            ? { fontSize: "40px" }
            : { fontSize: "60px" }
        }
      >
        {value === undefined
          ? 0
          : DOLLAR_SIGN_PREFIX_LIST.includes(metric)
          ? value < 1
            ? "$".concat(
                value.toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })
              )
            : "$".concat(Math.round(value).toLocaleString("en-US"))
          : (Math.round(value * 100) / 100).toLocaleString("en-US")}
      </Typography>
    </HighlightContainer>
    {comparedToValue && (
      <>
        <br />
        <HighlightContainer style={{ height: "100%" }}>
          {comparedTo === "previous_period"
            ? "Previous Period"
            : "Previous Year"}
          <Typography
            variant="h1"
            style={
              position && position.w <= 3
                ? { fontSize: "40px" }
                : { fontSize: "60px" }
            }
          >
            {comparedToValue === undefined
              ? 0
              : DOLLAR_SIGN_PREFIX_LIST.includes(metric)
              ? value < 1
                ? "$".concat(
                    value.toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })
                  )
                : "$".concat(Math.round(value).toLocaleString("en-US"))
              : (Math.round(value * 100) / 100).toLocaleString("en-US")}
          </Typography>
        </HighlightContainer>
      </>
    )}
  </Container>
);

const Highlight = HighlightComponent;

export { Highlight };
