import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const styles = () => createStyles({});

export interface OnboardIntegrationButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}
const OnboardIntegrationButtonComponent = ({
  onClick,
  disabled,
}: OnboardIntegrationButtonProps) => (
  <Button
    onClick={!disabled ? onClick : undefined}
    disabled={disabled}
    variant="text"
    style={
      !disabled
        ? { color: "#177EC2", fontWeight: 400, display: "flex" }
        : { display: "flex" }
    }
  >
    Connect
  </Button>
);

const OnboardIntegrationButton = withStyles(styles)(
  OnboardIntegrationButtonComponent
);

export { OnboardIntegrationButton };
