import styled from "styled-components";

export interface CustomContainerProps {
  padding?: string;
  direction?: string;
  alignItems?: string;
  justifyContent?: string;
  pt?: number;
  px?: number;
  height?: number;
  width?: number;
}

export const CustomContainer = styled.div<CustomContainerProps>`
  display: flex;
  padding: ${(props) => props.padding};
  padding-top: ${(props) => props.pt}px;
  padding-left: ${(props) => props.px}px;
  padding-right: ${(props) => props.px}px;
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  flex-direction: ${(props) => props.direction};
`;

export const BlockContainer = styled.div`
  display: block;
  float: left;
  width: 100%;
`;
