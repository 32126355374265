import React from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useWindowDimensions } from "App";
import { makeStyles } from "@material-ui/core/styles";
import { Highlight, Spinner, StyledTooltip } from "components";
import { formatMetricName, formatTimeColumn } from "utils";
import moment from "moment";
import { Bar, Line } from "react-chartjs-2/dist";
import { chartColors } from "config/theme";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip,
  PointElement,
  LineElement,
} from "chart.js";
import { DOLLAR_SIGN_PREFIX_LIST, PERCENT_METRICS } from "constants/index";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import BarChartIcon from "@mui/icons-material/BarChart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip,
  PointElement,
  LineElement
);

const useStyles = makeStyles((t) => ({
  widgetContainer: {
    padding: t.spacing(3),
    backgroundColor: "white",
    margin: t.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-around",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingBottom: t.spacing(1),
  },
  tabs: {
    height: "36px !important",
    width: "fit-content",
    minHeight: "36px !important",
    border: "1px solid #E3E3E3",
    borderRadius: "5px",
    "& .MuiButtonBase-root.MuiTab-root": {
      textTransform: "none",
      padding: "0px",
      color: "#656576",
      fontSize: "12px",
      fontWeight: 600,
      fontStyle: "normal",
      fontFamiliy: "Work Sans",
      background: "#FFFFFF",
    },
    "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
      zIndex: 1,
      color: "black",
      background: "#E3E3E3",
    },
  },
  tab: {
    height: "36px !important",
    minHeight: "36px !important",
    width: "36px !important",
    minWidth: "36px !important",
    borderRight: "1px solid #E3E3E3 !important",
  },
  tabRight: {
    height: "36px !important",
    minHeight: "36px !important",
    width: "36px !important",
    minWidth: "36px !important",
  },
}));

export const ExplorerWidgetComponent = ({
  data,
  metric_name,
  group_by_tags,
  time_breakdown,
  isLoading,
}: ExplorerWidgetComponentProps) => {
  const classes = useStyles();
  const height = useWindowDimensions().height * 0.38;
  const [graphType, setGraphType] = React.useState("stackedBar");

  const handleChange = (event, newValue) => {
    setGraphType(newValue);
  };

  const renderBarLineChart = (timeseries: boolean) => {
    let formattedLabels: string[] = [];
    let tagValues: string[] = [];
    let filteredData: any[] = [];
    if (timeseries) {
      filteredData = data.data;
      formattedLabels = filteredData.map((d) =>
        formatTimeColumn(
          moment.unix(d.time),
          time_breakdown,
          data.start,
          data.stop
        )
      );
      tagValues =
        data.data.length > 0
          ? Object.keys(filteredData[0]).filter((i) => i !== "time")
          : [];
    } else {
      // Remove 0 values so that we dont try to render empty bars
      // eslint-disable-next-line no-param-reassign
      filteredData = data.data.filter((o) => {
        const key = Object.keys(o)[0];
        return o[key] !== 0;
      });
      // iterate through data.data and get list of unique keys from the data
      formattedLabels = filteredData.reduce((acc, curr) => {
        const keys = Object.keys(curr);
        return [...acc, ...keys];
      }, [] as string[]);
      tagValues = formattedLabels;
    }
    const graphData = {
      labels: formattedLabels,
      datasets: tagValues.map((t, i) => ({
        label: t,
        data: filteredData.map((d) => d[t]),
        backgroundColor: chartColors[i % chartColors.length],
        borderColor: chartColors[i % chartColors.length],
        borderWidth: 1,
        pointRadius: 2,
        fill: false,
      })),
    };

    const options = {
      type: graphType === "line" ? "line" : "bar",
      options: {
        parsing: false,
        normalized: true,
      },
      animation: {
        duration: 0,
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: graphType === "stackedBar",
          grid: {
            display: false,
          },
          ticks: {
            display: timeseries,
            callback: (tickValue, index) => {
              if (timeseries) {
                // First and last ticks are shown only
                if (index % (data.data.length - 1) === 0) {
                  return graphData.labels[index];
                }
              }
            },
            maxRotation: 0,
            minRotation: 0,
          },
        },
        y: {
          stacked: graphType === "stackedBar",
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 4,
            callback: (v) =>
              DOLLAR_SIGN_PREFIX_LIST.includes(metric_name)
                ? "$".concat(v.toLocaleString("en-US"))
                : PERCENT_METRICS.includes(metric_name)
                ? (v * 100)
                    .toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                    .concat("%")
                : v.toLocaleString("en-US"),
          },
          min: 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          mode:
            graphType === "line" ? ("point" as const) : ("nearest" as const),
          intersect: graphType === "groupedBar" || graphType === "stackedBar",
        },
      },
    };
    return (
      <>
        <Box className={classes.titleContainer}>
          <Typography
            style={{
              fontFamily: "Work Sans",
              fontStyle: "normal",
              letterSpacing: "-0.02em",
              fontWeight: 500,
              fontSize: "18px",
            }}
          >
            {formatMetricName(metric_name)}
          </Typography>
          <Tabs
            value={graphType}
            onChange={handleChange}
            className={classes.tabs}
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
          >
            <Tab
              disableRipple
              label={
                <StyledTooltip title="Stacked Bar Chart" placement="top">
                  <StackedBarChartIcon />
                </StyledTooltip>
              }
              value="stackedBar"
              className={classes.tab}
              iconPosition="start"
            />
            <Tab
              disableRipple
              value="groupedBar"
              className={classes.tab}
              label={
                <StyledTooltip title="Grouped Bar Chart" placement="top">
                  <BarChartIcon />
                </StyledTooltip>
              }
              iconPosition="start"
            />
            <Tab
              disableRipple
              value="line"
              className={classes.tabRight}
              label={
                <StyledTooltip title="Line Chart" placement="top">
                  <StackedLineChartIcon />
                </StyledTooltip>
              }
              iconPosition="start"
            />
          </Tabs>
        </Box>
        {graphType === "line" ? (
          <Line data={graphData} options={options} />
        ) : (
          <Bar data={graphData} options={options} />
        )}
      </>
    );
  };

  let boxContent;
  if (isLoading) {
    boxContent = <Spinner center />;
  } else if (time_breakdown !== "") {
    boxContent = renderBarLineChart(true);
  } else if (group_by_tags !== "") {
    boxContent = renderBarLineChart(false);
  } else {
    boxContent = (
      <>
        <Typography
          style={{
            fontFamily: "Work Sans",
            fontStyle: "normal",
            letterSpacing: "-0.02em",
            fontWeight: 500,
            fontSize: "18px",
          }}
        >
          {formatMetricName(metric_name)}
        </Typography>
        <Highlight
          value={data.data.length > 0 ? data.data[0].everything : 0}
          comparedTo="none"
          metric={metric_name}
        />
        <br />
      </>
    );
  }

  return (
    <Box className={classes.widgetContainer} sx={{ height }}>
      {boxContent}
    </Box>
  );
};

interface ExplorerWidgetComponentProps {
  data: any;
  metric_name: string;
  group_by_tags: string;
  time_breakdown: string;
  isLoading: boolean;
}
