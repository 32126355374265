import * as types from "types";

export const AppActionType = {
  FETCH_APP_START: "@@FETCH_APP_START",
  FETCH_APP_SUCCESS: "@@FETCH_APP_SUCCESS",
  FETCH_APP_FAILURE: "@@FETCH_APP_FAILURE",
  FETCH_EXPLORER_VIEWS_START: "@@FETCH_EXPLORER_VIEWS_START",
  FETCH_EXPLORER_VIEWS_SUCCESS: "@@FETCH_EXPLORER_VIEWS_SUCCESS",
  FETCH_EXPLORER_VIEWS_FAILURE: "@@FETCH_EXPLORER_VIEWS_FAILURE",
  FETCH_INSTALLED_INTEGRATIONS_START: "@@FETCH_INSTALLED_INTEGRATIONS_START",
  FETCH_INSTALLED_INTEGRATIONS_SUCCESS:
    "@@FETCH_INSTALLED_INTEGRATIONS_SUCCESS",
  FETCH_INSTALLED_INTEGRATIONS_FAILURE:
    "@@FETCH_INSTALLED_INTEGRATIONS_FAILURE",
  FETCH_INTEGRATION_START: "@@FETCH_INTEGRATION_START",
  FETCH_INTEGRATION_SUCCESS: "@@FETCH_INTEGRATION_SUCCESS",
  FETCH_INTEGRATION_FAILURE: "@@FETCH_INTEGRATION_FAILURE",
  FETCH_OPEN_ISSUES_START: "@@FETCH_OPEN_ISSUES_START",
  FETCH_OPEN_ISSUES_SUCCESS: "@@FETCH_OPEN_ISSUES_SUCCESS",
  FETCH_OPEN_ISSUES_FAILURE: "@@FETCH_OPEN_ISSUES_FAILURE",
  FETCH_RESOLVED_ISSUES_START: "@@FETCH_RESOLVED_ISSUES_START",
  FETCH_RESOLVED_ISSUES_SUCCESS: "@@FETCH_RESOLVED_ISSUES_SUCCESS",
  FETCH_RESOLVED_ISSUES_FAILURE: "@@FETCH_RESOLVED_ISSUES_FAILURE",
  FETCH_SCANNERS_START: "@@FETCH_SCANNERS_START",
  FETCH_SCANNERS_SUCCESS: "@@FETCH_SCANNERS_SUCCESS",
  FETCH_SCANNERS_FAILURE: "@@FETCH_SCANNERS_FAILURE",
  FETCH_TRACKED_ITEMS_COUNT_START: "@@FETCH_TRACKED_ITEMS_COUNT_START",
  FETCH_TRACKED_ITEMS_COUNT_SUCCESS: "@@FETCH_TRACKED_ITEMS_COUNT_SUCCESS",
  FETCH_TRACKED_ITEMS_COUNT_FAILURE: "@@FETCH_TRACKED_ITEMS_COUNT_FAILURE",
  FETCH_NOTIFICATIONS_START: "@@FETCH_NOTIFICATIONS_START",
  FETCH_NOTIFICATIONS_SUCCESS: "@@FETCH_NOTIFICATIONS_SUCCESS",
  FETCH_NOTIFICATIONS_FAILURE: "@@FETCH_NOTIFICATIONS_FAILURE",
  SAVE_NOTIFICATIONS_START: "@@SAVE_NOTIFICATIONS_START",
  SAVE_NOTIFICATIONS_SUCCESS: "@@SAVE_NOTIFICATIONS_SUCCESS",
  SAVE_NOTIFICATIONS_FAILURE: "@@SAVE_NOTIFICATIONS_FAILURE",
  SET_UPDATE_NOTIFICATIONS_MESSAGE: "SET_UPDATE_NOTIFICATIONS_MESSAGE",
  FETCH_CUSTOM_SCANNERS_START: "@@FETCH_CUSTOM_SCANNERS_START",
  FETCH_CUSTOM_SCANNERS_SUCCESS: "@@FETCH_CUSTOM_SCANNERS_SUCCESS",
  FETCH_CUSTOM_SCANNERS_FAILURE: "@@FETCH_CUSTOM_SCANNERS_FAILURE",
  SAVE_CUSTOM_SCANNERS_START: "@@SAVE_CUSTOM_SCANNERS_START",
  SAVE_CUSTOM_SCANNERS_SUCCESS: "@@SAVE_CUSTOM_SCANNERS_SUCCESS",
  SAVE_CUSTOM_SCANNERS_FAILURE: "@@SAVE_CUSTOM_SCANNERS_FAILURE",
  FETCH_EXPLORER_DATA_START: "@@FETCH_EXPLORER_DATA_START",
  FETCH_EXPLORER_DATA_SUCCESS: "@@FETCH_EXPLORER_DATA_SUCCESS",
  FETCH_EXPLORER_DATA_FAILURE: "@@FETCH_EXPLORER_DATA_FAILURE",
  SAVE_EXPLORER_VIEW_START: "@@SAVE_EXPLORER_VIEW_START",
  SAVE_EXPLORER_VIEW_SUCCESS: "@@SAVE_EXPLORER_VIEW_SUCCESS",
  SAVE_EXPLORER_VIEW_FAILURE: "@@SAVE_EXPLORER_VIEW_FAILURE",
  FETCH_AVAILABLE_METRICS_START: "@@FETCH_AVAILABLE_METRICS_START",
  FETCH_AVAILABLE_METRICS_SUCCESS: "@@FETCH_AVAILABLE_METRICS_SUCCESS",
  FETCH_AVAILABLE_METRICS_FAILURE: "@@FETCH_AVAILABLE_METRICS_FAILURE",
  FETCH_METRICS_ALL_TAGS_START: "@@FETCH_METRICS_ALL_TAGS_START",
  FETCH_METRICS_ALL_TAGS_SUCCESS: "@@FETCH_METRICS_ALL_TAGS_SUCCESS",
  FETCH_METRICS_ALL_TAGS_FAILURE: "@@FETCH_METRICS_ALL_TAGS_FAILURE",
  GET_EXPLORER_VIEWS: "GET_EXPLORER_VIEWS",
  POST_EXPLORER_VIEW: "POST_EXPLORER_VIEW",
  PUT_EXPLORER_VIEW: "PUT_EXPLORER_VIEW",
  SET_EXPLORER_VIEWS: "SET_EXPLORER_VIEWS",
  DELETE_EXPLORER_VIEW: "DELETE_EXPLORER_VIEW",
  SET_EXPLORER_VIEW: "SET_EXPLORER_VIEW",
  GET_AVAILABLE_METRICS: "GET_AVAILABLE_METRICS",
  GET_ALL_TAGS_FOR_METRICS: "GET_ALL_TAGS_FOR_METRICS",
  SET_AVAILABLE_METRICS: "SET_AVAILABLE_METRICS",
  HOMEPAGE_FETCH_START: "HOMEPAGE_FETCH_START",
  HOMEPAGE_FETCH_SUCCESS: "HOMEPAGE_FETCH_SUCCESS",
  HOMEPAGE_FETCH_FAILURE: "HOMEPAGE_FETCH_FAILURE",
  POST_HOMEPAGE_DATA: "POST_HOMEPAGE_DATA",
  SET_HOMEPAGE_DATA: "SET_HOMEPAGE_DATA",
  POST_BATCH_EXPLORER_DATA: "POST_BATCH_EXPLORER_DATA",
  SET_EXPLORER_DATA: "SET_EXPLORER_DATA",
  SET_EXPLORER_TOTAL_DATA: "SET_EXPLORER_TOTAL_DATA",
  SET_METRIC_DATA: "SET_METRIC_DATA",
  SET_ALL_TAGS_FOR_METRICS: "SET_ALL_TAGS_FOR_METRICS",
  SET_AVAILABLE_TAGS: "SET_AVAILABLE_TAGS",
  POST_AMAZON_OAUTH_STATE: "POST_AMAZON_OAUTH_STATE",
  INSTALL_INTEGRATION: "INSTALL_INTEGRATION",
  UNINSTALL_INTEGRATION: "UNINSTALL_INTEGRATION",
  UPDATE_INTEGRATION: "UPDATE_INTEGRATION",
  SET_INSTALL_INTEGRATION_MESSAGE: "SET_INSTALL_INTEGRATION_MESSAGE",
  SET_UNINSTALL_INTEGRATION_MESSAGE: "SET_UNINSTALL_INTEGRATION_MESSAGE",
  SET_UPDATE_INTEGRATION_MESSAGE: "SET_UPDATE_INTEGRATION_MESSAGE",
  SET_IS_ONBOARDING_INSTALL: "SET_IS_ONBOARDING_INSTALL",
  GET_INSTALLED_INTEGRATIONS: "GET_INSTALLED_INTEGRATIONS",
  SET_INSTALLED_INTEGRATIONS: "SET_INSTALLED_INTEGRATIONS",
  GET_SLACK_CHANNELS: "GET_SLACK_CHANNELS",
  SET_SLACK_CHANNELS: "SET_SLACK_CHANNELS",
  GET_USERS_IN_ORG: "GET_USERS_IN_ORG",
  SET_USERS_IN_ORG: "SET_USERS_IN_ORG",
  FETCH_SLACK_CHANNELS_START: "FETCH_SLACK_CHANNELS_START",
  FETCH_SLACK_CHANNELS_SUCCESS: "FETCH_SLACK_CHANNELS_SUCCESS",
  FETCH_SLACK_CHANNELS_FAILURE: "FETCH_SLACK_CHANNELS_FAILURE",
  GET_INTEGRATION: "GET_INTEGRATION",
  SET_INTEGRATION: "SET_INTEGRATION",
  GET_OPEN_ISSUES: "GET_OPEN_ISSUES",
  GET_RESOLVED_ISSUES: "GET_RESOLVED_ISSUES",
  SET_OPEN_ISSUES: "SET_OPEN_ISSUES",
  SET_RESOLVED_ISSUES: "SET_RESOLVED_ISSUES",
  GET_SCANNERS: "GET_SCANNERS",
  SET_SCANNERS: "SET_SCANNERS",
  GET_TRACKED_ITEMS_COUNT: "GET_TRACKED_ITEMS_COUNT",
  SET_TRACKED_ITEMS_COUNT: "SET_TRACKED_ITEMS_COUNT",
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  PUT_NOTIFICATIONS: "PUT_NOTIFICATIONS",
  GET_CUSTOM_SCANNERS: "GET_CUSTOM_SCANNERS",
  SET_CUSTOM_SCANNERS: "SET_CUSTOM_SCANNERS",
  SAVE_CUSTOM_SCANNERS: "SAVE_CUSTOM_SCANNERS",
  INSTALLING_INTEGRATION_START: "INSTALLING_INTEGRATION_START",
  INSTALLING_INTEGRATION_SUCCESS: "INSTALLING_INTEGRATION_SUCCESS",
  INSTALLING_INTEGRATION_FAILURE: "INSTALLING_INTEGRATION_FAILURE",
  AMAZON_OAUTH_START: "AMAZON_OAUTH_START",
  AMAZON_OAUTH_SUCCESS: "AMAZON_OAUTH_SUCCESS",
  AMAZON_OAUTH_FAILURE: "AMAZON_OAUTH_FAILURE",
  UPDATING_INTEGRATION_START: "UPDATING_INTEGRATION_START",
  UPDATING_INTEGRATION_SUCCESS: "UPDATING_INTEGRATION_SUCCESS",
  UPDATING_INTEGRATION_FAILURE: "UPDATING_INTEGRATION_FAILURE",
};

export const fetchAppStart = (): types.FetchAppStart => ({
  type: AppActionType.FETCH_APP_START,
});
export const fetchAppSuccess = (): types.FetchAppSuccess => ({
  type: AppActionType.FETCH_APP_SUCCESS,
});
export const fetchAppFailure = (error: string): types.FetchAppFailure => ({
  type: AppActionType.FETCH_APP_FAILURE,
  error,
});

export const fetchExplorerViewsStart = (): types.FetchExplorerViewsStart => ({
  type: AppActionType.FETCH_EXPLORER_VIEWS_START,
});
export const fetchExplorerViewsSuccess =
  (): types.FetchExplorerViewsSuccess => ({
    type: AppActionType.FETCH_EXPLORER_VIEWS_SUCCESS,
  });
export const fetchExplorerViewsFailure =
  (): types.FetchExplorerViewsFailure => ({
    type: AppActionType.FETCH_EXPLORER_VIEWS_FAILURE,
  });

export const fetchInstalledIntegrationsStart =
  (): types.FetchInstalledIntegrationsStart => ({
    type: AppActionType.FETCH_INSTALLED_INTEGRATIONS_START,
  });
export const fetchInstalledIntegrationsSuccess =
  (): types.FetchInstalledIntegrationsSuccess => ({
    type: AppActionType.FETCH_INSTALLED_INTEGRATIONS_SUCCESS,
  });
export const fetchInstalledIntegrationsFailure = (
  installedIntegrationsError: string
): types.FetchInstalledIntegrationsFailure => ({
  type: AppActionType.FETCH_INSTALLED_INTEGRATIONS_FAILURE,
  installedIntegrationsError,
});

export const fetchIntegrationStart = (): types.FetchIntegrationStart => ({
  type: AppActionType.FETCH_INTEGRATION_START,
});
export const fetchIntegrationSuccess = (): types.FetchIntegrationSuccess => ({
  type: AppActionType.FETCH_INTEGRATION_SUCCESS,
});
export const fetchIntegrationFailure = (
  integrationError: string
): types.FetchIntegrationFailure => ({
  type: AppActionType.FETCH_INTEGRATION_FAILURE,
  integrationError,
});

export const installingIntegrationStart =
  (): types.InstallingIntegrationStart => ({
    type: AppActionType.INSTALLING_INTEGRATION_START,
  });
export const installingIntegrationSuccess =
  (): types.InstallingIntegrationSuccess => ({
    type: AppActionType.INSTALLING_INTEGRATION_SUCCESS,
  });
export const installingIntegrationFailure = (
  installingIntegrationError: string
): types.InstallingIntegrationFailure => ({
  type: AppActionType.INSTALLING_INTEGRATION_FAILURE,
  installingIntegrationError,
});

export const amazonOauthStart = (): types.AmazonOauthStart => ({
  type: AppActionType.AMAZON_OAUTH_START,
});
export const amazonOauthSuccess = (): types.AmazonOauthSuccess => ({
  type: AppActionType.AMAZON_OAUTH_SUCCESS,
});
export const amazonOauthFailure = (
  amazonOauthError: string
): types.AmazonOauthFailure => ({
  type: AppActionType.AMAZON_OAUTH_FAILURE,
  amazonOauthError,
});

export const updatingIntegrationStart = (): types.UpdatingIntegrationStart => ({
  type: AppActionType.UPDATING_INTEGRATION_START,
});
export const updatingIntegrationSuccess =
  (): types.UpdatingIntegrationSuccess => ({
    type: AppActionType.UPDATING_INTEGRATION_SUCCESS,
  });
export const updatingIntegrationFailure = (
  updateIntegrationError: string
): types.UpdatingIntegrationFailure => ({
  type: AppActionType.UPDATING_INTEGRATION_FAILURE,
  updateIntegrationError,
});

export const fetchOpenIssuesStart = (): types.FetchOpenIssuesStart => ({
  type: AppActionType.FETCH_OPEN_ISSUES_START,
});
export const fetchOpenIssuesSuccess = (): types.FetchOpenIssuesSuccess => ({
  type: AppActionType.FETCH_OPEN_ISSUES_SUCCESS,
});
export const fetchOpenIssuesFailure = (
  openIssuesError: string
): types.FetchOpenIssuesFailure => ({
  type: AppActionType.FETCH_OPEN_ISSUES_FAILURE,
  openIssuesError,
});

export const fetchResolvedIssuesStart = (): types.FetchResolvedIssuesStart => ({
  type: AppActionType.FETCH_RESOLVED_ISSUES_START,
});
export const fetchResolvedIssuesSuccess =
  (): types.FetchResolvedIssuesSuccess => ({
    type: AppActionType.FETCH_RESOLVED_ISSUES_SUCCESS,
  });
export const fetchResolvedIssuesFailure = (
  resolvedIssuesError: string
): types.FetchResolvedIssuesFailure => ({
  type: AppActionType.FETCH_RESOLVED_ISSUES_FAILURE,
  resolvedIssuesError,
});

export const fetchScannersStart = (): types.FetchScannersStart => ({
  type: AppActionType.FETCH_SCANNERS_START,
});
export const fetchScannersSuccess = (): types.FetchScannersSuccess => ({
  type: AppActionType.FETCH_SCANNERS_SUCCESS,
});
export const fetchScannersFailure = (
  scannersError: string
): types.FetchScannersFailure => ({
  type: AppActionType.FETCH_SCANNERS_FAILURE,
  scannersError,
});

export const fetchTrackedItemsCountStart =
  (): types.FetchTrackedItemsCountStart => ({
    type: AppActionType.FETCH_TRACKED_ITEMS_COUNT_START,
  });
export const fetchTrackedItemsCountSuccess =
  (): types.FetchTrackedItemsCountSuccess => ({
    type: AppActionType.FETCH_TRACKED_ITEMS_COUNT_SUCCESS,
  });
export const fetchTrackedItemsCountFailure = (
  trackedItemsCountError: string
): types.FetchTrackedItemsCountFailure => ({
  type: AppActionType.FETCH_TRACKED_ITEMS_COUNT_FAILURE,
  trackedItemsCountError,
});

export const fetchNotificationsStart = (): types.FetchNotificationsStart => ({
  type: AppActionType.FETCH_NOTIFICATIONS_START,
});
export const fetchNotificationsSuccess =
  (): types.FetchNotificationsSuccess => ({
    type: AppActionType.FETCH_NOTIFICATIONS_SUCCESS,
  });
export const fetchNotificationsFailure = (
  notificationsError: string
): types.FetchNotificationsFailure => ({
  type: AppActionType.FETCH_NOTIFICATIONS_FAILURE,
  notificationsError,
});
export const saveNotificationsStart = (): types.SaveNotificationsStart => ({
  type: AppActionType.SAVE_NOTIFICATIONS_START,
});
export const saveNotificationsSuccess = (): types.SaveNotificationsSuccess => ({
  type: AppActionType.SAVE_NOTIFICATIONS_SUCCESS,
});
export const saveNotificationsFailure = (
  notificationsSaveError: string
): types.SaveNotificationsFailure => ({
  type: AppActionType.SAVE_NOTIFICATIONS_FAILURE,
  notificationsSaveError,
});

export const setUpdateNotificationsMessage = (
  updateNotificationsMessage: string
): types.SetUpdateNotificationsMessage => ({
  type: AppActionType.SET_UPDATE_NOTIFICATIONS_MESSAGE,
  updateNotificationsMessage,
});

export const fetchCustomScannersStart = (): types.FetchCustomScannersStart => ({
  type: AppActionType.FETCH_CUSTOM_SCANNERS_START,
});
export const fetchCustomScannersSuccess =
  (): types.FetchCustomScannersSuccess => ({
    type: AppActionType.FETCH_CUSTOM_SCANNERS_SUCCESS,
  });
export const fetchCustomScannersFailure = (
  customScannersError: string
): types.FetchCustomScannersFailure => ({
  type: AppActionType.FETCH_CUSTOM_SCANNERS_FAILURE,
  customScannersError,
});
export const saveCustomScannersStart = (): types.SaveCustomScannersStart => ({
  type: AppActionType.SAVE_CUSTOM_SCANNERS_START,
});
export const saveCustomScannersSuccess =
  (): types.SaveCustomScannersSuccess => ({
    type: AppActionType.SAVE_CUSTOM_SCANNERS_SUCCESS,
  });
export const saveCustomScannersFailure = (
  customScannersSaveError: string
): types.SaveCustomScannersFailure => ({
  type: AppActionType.SAVE_CUSTOM_SCANNERS_FAILURE,
  customScannersSaveError,
});

// Explorer
export const fetchExplorerDataStart = (): types.FetchExplorerDataStart => ({
  type: AppActionType.FETCH_EXPLORER_DATA_START,
});
export const fetchExplorerDataSuccess = (): types.FetchExplorerDataSuccess => ({
  type: AppActionType.FETCH_EXPLORER_DATA_SUCCESS,
});
export const fetchExplorerDataFailure = (
  explorerError: string
): types.FetchExplorerDataFailure => ({
  type: AppActionType.FETCH_EXPLORER_DATA_FAILURE,
  explorerError,
});

export const saveExplorerViewStart = (): types.SaveExplorerViewStart => ({
  type: AppActionType.SAVE_EXPLORER_VIEW_START,
});
export const saveExplorerViewSuccess = (): types.SaveExplorerViewSuccess => ({
  type: AppActionType.SAVE_EXPLORER_VIEW_SUCCESS,
});
export const saveExplorerViewFailure = (
  explorerSaveError: string
): types.SaveExplorerViewFailure => ({
  type: AppActionType.SAVE_EXPLORER_VIEW_FAILURE,
  explorerSaveError,
});

export const getExplorerViews = (): types.GetExplorerViews => ({
  type: AppActionType.GET_EXPLORER_VIEWS,
});

export const setExplorerViews = (
  explorerViews: types.ExplorerViewType[]
): types.SetExplorerViews => ({
  type: AppActionType.SET_EXPLORER_VIEWS,
  explorerViews,
});

export const setExplorerView = (
  explorerView: types.ExplorerViewType
): types.SetExplorerView => ({
  type: AppActionType.SET_EXPLORER_VIEW,
  explorerView,
});

export const postExplorerView = (
  params: types.PostExplorerViewRequestParams
): types.PostExplorerView => ({
  type: AppActionType.POST_EXPLORER_VIEW,
  params,
});

export const putExplorerView = (
  params: types.PutExplorerViewRequestParams
): types.PutExplorerView => ({
  type: AppActionType.PUT_EXPLORER_VIEW,
  params,
});

export const deleteExplorerView = (
  params: types.DeleteExplorerViewRequestParams
): types.DeleteExplorerView => ({
  type: AppActionType.DELETE_EXPLORER_VIEW,
  params,
});

// Issues
export const getOpenIssues = (): types.GetIssues => ({
  type: AppActionType.GET_OPEN_ISSUES,
});

export const getResolvedIssues = (): types.GetIssues => ({
  type: AppActionType.GET_RESOLVED_ISSUES,
});

export const setOpenIssues = (
  openIssues: types.IssueType[]
): types.SetOpenIssues => ({
  type: AppActionType.SET_OPEN_ISSUES,
  openIssues,
});

export const setResolvedIssues = (
  resolvedIssues: types.IssueType[]
): types.SetResolvedIssues => ({
  type: AppActionType.SET_RESOLVED_ISSUES,
  resolvedIssues,
});

export const getScanners = (): types.GetScanners => ({
  type: AppActionType.GET_SCANNERS,
});
export const setScanners = (
  scanners: types.ScannerType[]
): types.SetScanners => ({
  type: AppActionType.SET_SCANNERS,
  scanners,
});

export const getTrackedItemsCount = (): types.GetTrackedItemsCount => ({
  type: AppActionType.GET_TRACKED_ITEMS_COUNT,
});

export const setTrackedItemsCount = (
  trackedItemsCount: number
): types.SetTrackedItemsCount => ({
  type: AppActionType.SET_TRACKED_ITEMS_COUNT,
  trackedItemsCount,
});

export const getNotifications = (): types.GetNotifications => ({
  type: AppActionType.GET_NOTIFICATIONS,
});

export const setNotifications = (
  notifications: types.Notification[]
): types.SetNotifications => ({
  type: AppActionType.SET_NOTIFICATIONS,
  notifications,
});

export const putNotifications = (
  params: types.PutNotificationRequestParams
): types.PutNotifications => ({
  type: AppActionType.PUT_NOTIFICATIONS,
  params,
});

export const getCustomScanners = (): types.GetCustomScanners => ({
  type: AppActionType.GET_CUSTOM_SCANNERS,
});
export const setCustomScanners = (
  customScanners: types.CustomScannerType[]
): types.SetCustomScanners => ({
  type: AppActionType.SET_CUSTOM_SCANNERS,
  customScanners,
});
export const saveCustomScanners = (
  scanners: types.CustomScannerType[]
): types.SaveCustomScanners => ({
  type: AppActionType.SAVE_CUSTOM_SCANNERS,
  scanners,
});

export const fetchMetricsAllTagsStart = (): types.FetchMetricsAllTagsStart => ({
  type: AppActionType.FETCH_METRICS_ALL_TAGS_START,
});
export const fetchMetricsAllTagsSuccess =
  (): types.FetchMetricsAllTagsSuccess => ({
    type: AppActionType.FETCH_METRICS_ALL_TAGS_SUCCESS,
  });
export const fetchMetricsAllTagsFailure = (
  metricsAllTagsError: string
): types.FetchMetricsAllTagsFailure => ({
  type: AppActionType.FETCH_METRICS_ALL_TAGS_FAILURE,
  metricsAllTagsError,
});

export const getAvailableMetrics = (): types.GetAvailableMetrics => ({
  type: AppActionType.GET_AVAILABLE_METRICS,
});
export const fetchAvailableMetricsStart =
  (): types.GetAvailableMetricsStart => ({
    type: AppActionType.FETCH_AVAILABLE_METRICS_START,
  });
export const fetchAvailableMetricsSuccess =
  (): types.GetAvailableMetricsSuccess => ({
    type: AppActionType.FETCH_AVAILABLE_METRICS_SUCCESS,
  });
export const fetchAvailableMetricsFailure = (
  availableMetricsError: string
): types.GetAvailableMetricsFailure => ({
  type: AppActionType.FETCH_AVAILABLE_METRICS_FAILURE,
  availableMetricsError,
});
export const setAvailableMetrics = (metrics: string[]) => ({
  type: AppActionType.SET_AVAILABLE_METRICS,
  metrics,
});

export const homepageInitFetchStart = (): types.HomepageFetchStart => ({
  type: AppActionType.HOMEPAGE_FETCH_START,
});
export const homepageInitFetchSuccess = (): types.HomepageFetchSuccess => ({
  type: AppActionType.HOMEPAGE_FETCH_SUCCESS,
});
export const homepageInitFetchFailure = (
  error: string
): types.HomepageFetchFailure => ({
  type: AppActionType.HOMEPAGE_FETCH_FAILURE,
  error,
});

export const postHomepageData = (
  params: types.PostBatchQueryRequestParams
): types.PostHomepageData => ({
  type: AppActionType.POST_HOMEPAGE_DATA,
  params,
});

export const setHomepageData = (
  homepageData: types.IMetricDataValue[]
): types.SetHomepageData => ({
  type: AppActionType.SET_HOMEPAGE_DATA,
  homepageData,
});

export const postBatchExplorerData = (
  params: types.PostBatchQueryRequestParams
): types.PostBatchExplorerData => ({
  type: AppActionType.POST_BATCH_EXPLORER_DATA,
  params,
});

export const setExplorerData = (
  explorerData: types.IMetricDataValue[]
): types.SetExplorerData => ({
  type: AppActionType.SET_EXPLORER_DATA,
  explorerData,
});

export const setExplorerTotalData = (
  explorerTotalData: types.IMetricDataValue[]
): types.SetExplorerTotalData => ({
  type: AppActionType.SET_EXPLORER_TOTAL_DATA,
  explorerTotalData,
});

export const getAllTagsForMetrics = (
  params: types.GetAllTagsForMetricsRequestParams
): types.GetAllTagsForMetrics => ({
  type: AppActionType.GET_ALL_TAGS_FOR_METRICS,
  params,
});
export const setAllTagsForMetrics = (
  allTagsForMetrics: types.AllTagsForMetrics
): types.SetAllTagsForMetrics => ({
  type: AppActionType.SET_ALL_TAGS_FOR_METRICS,
  allTagsForMetrics,
});

export const postAmazonOauthState = (
  params: types.PostAmazonOauthStateRequestParams
): types.PostAmazonOauthState => ({
  type: AppActionType.POST_AMAZON_OAUTH_STATE,
  params,
});

export const installIntegration = (
  params: types.InstallIntegrationRequestParams
): types.InstallIntegration => ({
  type: AppActionType.INSTALL_INTEGRATION,
  params,
});

export const setInstallIntegrationMessage = (
  installMessage: string
): types.SetInstallIntegrationMessage => ({
  type: AppActionType.SET_INSTALL_INTEGRATION_MESSAGE,
  installMessage,
});

export const updateIntegration = (
  params: types.UpdateIntegrationParams
): types.UpdateIntegration => ({
  type: AppActionType.UPDATE_INTEGRATION,
  params,
});

export const setUpdateIntegrationMessage = (
  updateIntegrationMessage: string
): types.SetUpdateIntegrationMessage => ({
  type: AppActionType.SET_UPDATE_INTEGRATION_MESSAGE,
  updateIntegrationMessage,
});

export const uninstallIntegration = (
  params: types.UninstallIntegrationRequestParams
): types.UninstallIntegration => ({
  type: AppActionType.UNINSTALL_INTEGRATION,
  params,
});

export const setUninstallIntegrationMessage = (
  installMessage?: string
): types.SetUninstallIntegrationMessage => ({
  type: AppActionType.SET_UNINSTALL_INTEGRATION_MESSAGE,
  installMessage,
});

export const getInstalledIntegrations = (): types.GetInstalledIntegrations => ({
  type: AppActionType.GET_INSTALLED_INTEGRATIONS,
});

export const setInstalledIntegrations = (
  installedIntegrations: types.InstalledIntegrationsResponseType
): types.SetInstalledIntegrations => ({
  type: AppActionType.SET_INSTALLED_INTEGRATIONS,
  installedIntegrations,
});

export const setIsOnboardingInstall = (
  isOnboardingInstall: boolean
): types.SetIsOnboardingInstall => ({
  type: AppActionType.SET_IS_ONBOARDING_INSTALL,
  isOnboardingInstall,
});

export const getSlackChannels = (): types.GetSlackChannels => ({
  type: AppActionType.GET_SLACK_CHANNELS,
});

export const setSlackChannels = (
  channels: string[]
): types.SetSlackChannels => ({
  type: AppActionType.SET_SLACK_CHANNELS,
  channels,
});

export const getOrgUsers = (): types.GetOrgUsers => ({
  type: AppActionType.GET_USERS_IN_ORG,
});

export const setOrgUsers = (users: types.User[]): types.SetOrgUsers => ({
  type: AppActionType.SET_USERS_IN_ORG,
  users,
});

export const fetchSlackChannelsStart = (): types.FetchSlackChannelsStart => ({
  type: AppActionType.FETCH_SLACK_CHANNELS_START,
});
export const fetchSlackChannelsSuccess =
  (): types.FetchSlackChannelsSuccess => ({
    type: AppActionType.FETCH_SLACK_CHANNELS_SUCCESS,
  });
export const fetchSlackChannelsFailure = (
  slackChannelsError: string
): types.FetchSlackChannelsFailure => ({
  type: AppActionType.FETCH_SLACK_CHANNELS_FAILURE,
  slackChannelsError,
});

export const getIntegration = (
  params: types.GetIntegrationParams
): types.GetIntegrationData => ({
  type: AppActionType.GET_INTEGRATION,
  params,
});

export const setIntegration = (
  params: types.SetIntegrationParams
): types.SetIntegrationData => ({
  type: AppActionType.SET_INTEGRATION,
  params,
});
