import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { TextHighlight } from "components";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const useStyles = makeStyles(() => ({
  headerChangeContent: {
    padding: "0",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "nowrap",
    height: "25px",
    justifyContent: "center",
  },
  changeValueDisplay: {
    display: "flex",
    marginTop: "5px",
    fontSize: "15px",
    alignItems: "center",
  },
}));

interface HeaderBoxContentProps {
  value?: string;
  valueSuffix?: string;
  changeValue?: number;
  changeString?: string;
  goodChange: boolean;
}

export const HeaderBoxContent = ({
  value,
  valueSuffix,
  changeValue,
  changeString,
  goodChange,
}: HeaderBoxContentProps) => {
  const classes = useStyles();
  const backgroundColor = goodChange ? "green" : "red";
  return (
    <Container
      className={classes.headerChangeContent}
      style={changeValue ? {} : { alignItems: "flex-end" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
        }}
      >
        <TextHighlight style={valueSuffix ? { marginRight: "10px" } : {}}>
          {value}
        </TextHighlight>
        {valueSuffix}
      </div>
      {changeValue && changeValue !== 0 ? (
        changeValue < 0 ? (
          <div
            className={classes.changeValueDisplay}
            style={{
              color: backgroundColor,
            }}
          >
            <ArrowDropDownIcon
              style={{ color: backgroundColor, marginRight: "-3px" }}
            />
            {changeString}
          </div>
        ) : (
          <div
            className={classes.changeValueDisplay}
            style={{
              color: backgroundColor,
            }}
          >
            <ArrowDropUpIcon
              style={{ color: backgroundColor, marginRight: "-3px" }}
            />
            {changeString}
          </div>
        )
      ) : null}
    </Container>
  );
};
