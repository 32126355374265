import axios, { AxiosHeaders } from "axios";
import { Auth0Client } from "auth0-spa-js";

export const auth0Client = new Auth0Client({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
});

axios.interceptors.request.use(
  async (config) => {
    const audience = process.env.REACT_APP_API_AUTH0_AUDIENCE_URI;
    const scope = "";

    const accessToken = await auth0Client.getTokenSilently({ audience, scope });
    const configWithAuthorization = config;
    (configWithAuthorization.headers as AxiosHeaders).set(
      "Authorization",
      `Bearer ${accessToken}`
    );
    const supportUserToken = localStorage.getItem("support-user-token");
    if (supportUserToken && supportUserToken.length > 0) {
      configWithAuthorization.headers!["x-support-user"] = supportUserToken;
    }
    return configWithAuthorization;
  },
  (error) => {
    if (error.error === "login_required") {
      auth0Client.loginWithRedirect();
    } else if (error.error === "consent_required") {
      const opts = {
        audience: process.env.REACT_APP_API_AUTH0_AUDIENCE_URI,
        scope: "",
      };
      auth0Client.loginWithRedirect(opts);
    } else {
      return Promise.reject(error);
    }
  }
);
