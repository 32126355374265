import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Typography,
} from "@material-ui/core";
import { Button, Spinner, SvgIcon } from "components";
import { SetIntegrationParams } from "types";

const useStyles = makeStyles(() => ({
  switch: {
    "&.Mui-checked": {
      color: "#177ec2",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#177ec2",
    },
  },
}));

interface OnboardingSelectingAccountsProps {
  integration: SetIntegrationParams;
  setSelectingIntegrationAccounts: (value: boolean) => void;
  handleAccountEnableToggle: (account: string, params: any) => void;
  onConfirmEnabledAccounts: () => void;
  isUpdatingIntegration: boolean;
}
export function OnboardingSelectingAccountsComponent({
  integration,
  setSelectingIntegrationAccounts,
  handleAccountEnableToggle,
  onConfirmEnabledAccounts,
  isUpdatingIntegration,
}: OnboardingSelectingAccountsProps) {
  const classes = useStyles();
  if (integration.data.length === 0 || isUpdatingIntegration) {
    return <Spinner center />;
  }

  // sort integrationAccounts by name
  integration.data.sort((a, b) => {
    if (a.account_name < b.account_name) {
      return -1;
    }
    if (a.account_name > b.account_name) {
      return 1;
    }
    return 0;
  });

  return (
    <Container style={{ height: "100%" }}>
      <Container
        style={{
          paddingTop: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <SvgIcon
          name={integration.integration_name}
          size={20}
          style={{ justifyContent: "center" }}
        />
        <DialogTitle
          id="form-dialog-title"
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "0px",
          }}
        >
          Select Ad Accounts
        </DialogTitle>
      </Container>
      <DialogContent
        style={{
          height: "75%",
          borderBottom: "1px solid gainsboro",
          borderTop: "1px solid gainsboro",
        }}
      >
        {integration.data.map((account) => (
          <Container
            key={account.account_name}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Container style={{ display: "flex", flexDirection: "column" }}>
              <Typography>{account.account_name}</Typography>
              <Typography style={{ color: "gray", fontSize: "11px" }}>
                {account.account_id}
              </Typography>
            </Container>
            <Container style={{ display: "flex", justifyContent: "flex-end" }}>
              <Switch
                classes={{ colorPrimary: classes.switch }}
                color="primary"
                onChange={(params) => {
                  handleAccountEnableToggle(account.account_id, params);
                }}
              />
            </Container>
          </Container>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setSelectingIntegrationAccounts(false);
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button onClick={onConfirmEnabledAccounts} color="primary">
          Connect
        </Button>
      </DialogActions>
    </Container>
  );
}
