import axios from "axios";
import { User, SwitchOrgParams } from "types";

const ApiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const getUser = async (): Promise<User | undefined> => {
  const userJsonStr = localStorage.getItem("user");
  let user: User | undefined;

  try {
    user = JSON.parse(userJsonStr ?? "");
  } catch (e) {
    const res = await axios.get(`${ApiEndpoint}/auth/user`);
    if (res && res.data && res.data.id) {
      user = res.data;
      localStorage.setItem("user", JSON.stringify(user));
    }
  }

  return user;
};

export const getSupportUser = async (): Promise<User | undefined> => {
  const supportUserJsonStr = localStorage.getItem("supportUser");
  let supportUser: User | undefined;

  try {
    supportUser = JSON.parse(supportUserJsonStr ?? "");
  } catch (e) {
    try {
      const res = await axios.get(`${ApiEndpoint}/auth/support-user`);
      if (res && res.data && res.data.id) {
        supportUser = res.data;
        localStorage.setItem("supportUser", JSON.stringify(supportUser));
      }
    } catch (e2) {
      console.log(e2);
    }
  }

  return supportUser;
};

export const setSupportUserJwt = async (opts: {
  email: string;
  org_name: string;
}): Promise<string | null> => {
  try {
    const res = await axios.post(`${ApiEndpoint}/auth/support-user-jwt`, opts);
    if (res && res.data && res.data.token && res.data.token.length > 0) {
      localStorage.setItem("support-user-token", res.data.token);
      return res.data.token;
    }
  } catch (e) {
    console.log(e);
  }
  return null;
};

export const switchOrgs = (params: SwitchOrgParams) =>
  axios.post(`${ApiEndpoint}/auth/switch-org`, params);
