import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { StyledTooltip } from "components/atoms";
import { TextSmallHeader } from "components";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const useStyles = makeStyles(() => ({
  titleFormat: {
    padding: "0",
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    alignItems: "center",
    whiteSpace: "nowrap",
    color: "#656576",
    fontSize: "12px",
    justifyContent: "center",
  },
}));

interface HeaderBoxTitleProps {
  title?: string;
  tooltipText?: string;
  titleSuffix?: string;
}

export const HeaderBoxTitle = ({
  title,
  tooltipText,
  titleSuffix,
}: HeaderBoxTitleProps) => {
  const classes = useStyles();

  return (
    <Container className={classes.titleFormat}>
      <TextSmallHeader>{title}</TextSmallHeader>
      {titleSuffix}
      {tooltipText && (
        <StyledTooltip title={tooltipText} placement="bottom">
          {/* padding bc doesn't align perfectly, probably better way to align items */}
          <div style={{ height: "16px", cursor: "pointer" }}>
            <InfoOutlinedIcon sx={{ fontSize: "medium" }} />
          </div>
        </StyledTooltip>
      )}
    </Container>
  );
};
