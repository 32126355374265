import React, { useContext, useEffect, useState } from "react";
import { Notification, PutNotificationRequestParams } from "types";
import {
  Grid,
  Typography,
  Container,
  Dialog,
  Divider,
  Button,
  TextField,
  FormControlLabel,
  MenuItem,
  Switch,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { selectUser } from "store/auth/selectors";
import { User } from "@auth0/auth0-react";
import uuid from "react-uuid";
import { connect } from "react-redux";
import { Spinner } from "components";
import { putNotifications } from "store/app/actions";
import { ISSUE_TYPE_DESCRIPTION } from "constants/index";
import { OutlinedInput } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { AnalyticsContext } from "index";

const useStyles = makeStyles(() => ({
  checkbox: {
    width: 20,
    height: 20,
    "&.Mui-checked": {
      color: "#177ec2",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-disabled": {
      opacity: 0.5,
    },
  },
  switch: {
    "&.Mui-checked": {
      color: "#177ec2",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#177ec2",
    },
  },
  errorSelect: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
}));

export default function IssueNotificationModalComponent({
  loading,
  issueFilter,
  slackChannels,
  notifications,
  user,
  setNotificationFilter,
  onPutNotifications,
  orgUsers,
}: IssueNotificationModalProps) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [issueTitle, setIssueTitle] = useState("");
  const [issueNotification, setIssueNotification] = useState<Notification>();
  const [notificationsOn, setNotificationsOn] = useState(false);
  const [editNotification, setEditNotification] = useState(false);
  const [notifySlack, setNotifySlack] = useState(false);
  const [slackError, setSlackError] = useState(false);
  const [notifyEmail, setNotifyEmail] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [selectedEmails, setSelectedEmails] = React.useState<string[]>([]);
  const [thresholdValue, setThresholdValue] = useState(0);
  const [allowNotifySave, setAllowNotifySave] = useState(false);
  const [slackChannel, setSlackChannel] = useState(
    user.currentOrganization.defaultNotificationChannel &&
      user.currentOrganization.defaultNotificationChannel.type === "slack"
      ? user.currentOrganization.defaultNotificationChannel.value
      : ""
  );
  const [notifyOnResolve, setNotifyOnResolve] = useState(false);
  const { analytics } = useContext(AnalyticsContext);
  useEffect(() => {
    setAllowNotifySave(notifyEmail || notifySlack);
  }, [notifyEmail, notifySlack]);

  const handleClose = () => {
    setIsOpen(false);
    setNotificationFilter("");
  };
  const setEmails = (event: SelectChangeEvent<typeof selectedEmails>) => {
    const {
      target: { value },
    } = event;
    setSelectedEmails(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setEmailError(false);
  };

  const saveNotification = (enabled: boolean) => {
    const selectedChannels: any[] = [];
    if (notifySlack) {
      if (slackChannel !== "") {
        selectedChannels.push({ type: "slack", channel: slackChannel });
      } else {
        setSlackError(true);
        return;
      }
    }

    if (notifyEmail) {
      if (selectedEmails.length > 0) {
        selectedEmails.forEach((email) => {
          selectedChannels.push({ type: "email", channel: email });
        });
      } else {
        setEmailError(true);
        return;
      }
    }
    const notification = {
      id: issueNotification?.id ?? uuid(),
      org_id: user.currentOrganization.id,
      issue_type: issueFilter,
      projected_loss_threshold: thresholdValue,
      notification_channels: selectedChannels,
      enabled,
      notify_on_resolve: notifyOnResolve && editNotification,
    };
    onPutNotifications(notification);
    // if user enables or disables a notifcation, track it
    if (issueNotification?.enabled !== enabled) {
      analytics.track(`Notification ${enabled ? "Enabled" : "Disabled"}`, {
        issue_type: issueFilter,
        projected_loss_threshold: thresholdValue,
        notification_channels: selectedChannels,
        notify_on_resolve: notifyOnResolve && editNotification,
      });
    }
    handleClose();
  };

  const handleNotificationToggle = (event) => {
    setEditNotification(event.target.checked);
    setNotificationsOn(event.target.checked);
  };

  useEffect(() => {
    if (isOpen) {
      const existingNotification = notifications.find(
        (notification) =>
          notification.issue_type.toLowerCase() === issueFilter.toLowerCase()
      );

      setNotificationsOn(existingNotification?.enabled ?? false);
      setNotifyOnResolve(existingNotification?.notify_on_resolve ?? false);
      setEditNotification(existingNotification?.enabled ?? false);
      setIssueNotification(existingNotification);
      setSlackChannel(
        existingNotification?.notification_channels.find(
          (channel) => channel.type === "slack"
        )?.channel ??
          (user.currentOrganization.defaultNotificationChannel &&
          user.currentOrganization.defaultNotificationChannel.type === "slack"
            ? user.currentOrganization.defaultNotificationChannel.value
            : "")
      );
      setNotifySlack(
        existingNotification?.notification_channels.find(
          (channel) => channel.type === "slack"
        ) ?? false
      );
      setNotifyEmail(
        existingNotification?.notification_channels.find(
          (channel) => channel.type === "email"
        ) ?? false
      );
      // iterate through existingNotification.notification_channels and add to selectedEmails
      const emails: string[] = [];
      existingNotification?.notification_channels.forEach((channel) => {
        if (channel.type === "email") {
          emails.push(channel.channel);
        }
      });
      if (emails.length === 0) {
        emails.push(user.email!);
      }
      setSelectedEmails(emails);
    }
  }, [isOpen]);

  useEffect(() => {
    setIssueTitle(
      issueFilter in ISSUE_TYPE_DESCRIPTION
        ? ISSUE_TYPE_DESCRIPTION[issueFilter].title
        : "new issue"
    );
    if (issueFilter !== "") {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [issueFilter]);

  let dialogContent;
  if (loading) {
    dialogContent = <Spinner center />;
  } else {
    dialogContent = (
      <Container>
        <Grid
          container
          justifyContent="space-between"
          style={{ marginTop: 20 }}
        >
          <Grid item>
            <Typography style={{ fontSize: 18, fontWeight: 500 }}>
              Notification
            </Typography>
          </Grid>
          <Container
            style={{ display: "flex", flexDirection: "row", padding: 0 }}
          >
            <Grid item>
              <Typography style={{ fontSize: 14 }}>
                Get notified when there's a {issueTitle} so you can immediately
                take action on high impact items
              </Typography>
            </Grid>
          </Container>
        </Grid>
        <Container style={{ marginBottom: 20, padding: 0 }}>
          <Container
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              paddingTop: 10,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: "#656576",
              }}
            >
              Enabled
            </Typography>
            <Grid item>
              <Switch
                checked={notificationsOn}
                classes={{ colorPrimary: classes.switch }}
                color="primary"
                onChange={handleNotificationToggle}
              />
            </Grid>
          </Container>
          <Container
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              paddingBottom: 10,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: "#656576",
              }}
            >
              Get notified when issue resolves
            </Typography>
            <Checkbox
              checked={notifyOnResolve && editNotification}
              color="primary"
              onChange={(e) => setNotifyOnResolve(e.target.checked)}
              disabled={!editNotification}
            />
          </Container>
          <Divider style={{ marginTop: 5, marginBottom: 5 }} />
          <Container
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: 10,
            }}
          >
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: "#656576",
              }}
            >
              Threshold
            </Typography>
            <Container
              style={{
                flexDirection: "row",
                display: "flex",
                padding: 0,
                gap: "10px",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                Weekly projected loss is above: $
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                style={{ width: 110 }}
                inputProps={{ min: 0 }}
                type="number"
                disabled={!editNotification}
                defaultValue={
                  issueNotification?.projected_loss_threshold ?? thresholdValue
                }
                onChange={(e) => {
                  setThresholdValue(Number(e.target.value));
                }}
              />
            </Container>
          </Container>
          <Divider style={{ marginTop: 5, marginBottom: 5 }} />
          <Container
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              padding: 10,
              gap: 10,
            }}
          >
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: "#656576",
              }}
            >
              Channel
            </Typography>
            <Container
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-end",
                gap: 20,
                padding: 0,
              }}
            >
              <Container
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: 0,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={notifySlack}
                      className={classes.checkbox}
                      onChange={(e) => setNotifySlack(e.target.checked)}
                      disabled={!editNotification}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: 14 }}>Slack</Typography>
                  }
                />
                <Select
                  displayEmpty
                  className={slackError ? classes.errorSelect : ""}
                  disabled={!notifySlack || !editNotification}
                  defaultValue={slackChannel}
                  style={{ height: 40, width: "70%" }}
                  onChange={(e) => {
                    setSlackChannel(e.target.value);
                    setSlackError(false);
                  }}
                  renderValue={(selected) => {
                    if (selected === "") {
                      return "Select channel to notify";
                    }
                    return selected;
                  }}
                >
                  {slackChannels.map((channel) => (
                    <MenuItem key={channel} value={channel}>
                      {channel}
                    </MenuItem>
                  ))}
                </Select>
              </Container>
              <Container
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: 0,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={notifyEmail}
                      className={classes.checkbox}
                      onChange={(e) => setNotifyEmail(e.target.checked)}
                      disabled={!editNotification}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: 14 }}>Email</Typography>
                  }
                />
                <Select
                  multiple
                  displayEmpty
                  className={emailError ? classes.errorSelect : ""}
                  value={selectedEmails}
                  disabled={!notifyEmail || !editNotification}
                  onChange={setEmails}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return "Select emails to notify";
                    }
                    if (selected.length > 1) {
                      return `${selected.length} emails selected`;
                    }
                    return selected.join(", ");
                  }}
                  defaultValue={issueNotification?.notification_channels.find(
                    (channel) => channel.type === "email"
                  )}
                  style={{ width: "70%", maxWidth: "350px", height: 40 }}
                >
                  {orgUsers.map((orgUser) => (
                    <MenuItem key={orgUser.email} value={orgUser.email}>
                      <Checkbox
                        checked={
                          (orgUser.email &&
                            selectedEmails.indexOf(orgUser.email) > -1) ||
                          false
                        }
                      />
                      <ListItemText primary={orgUser.email} />
                    </MenuItem>
                  ))}
                </Select>
              </Container>
            </Container>
          </Container>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                style={{
                  borderRadius: 5,
                  backgroundColor: allowNotifySave ? "#177ec2" : "gray",
                  color: "white",
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
                disabled={!allowNotifySave}
                onClick={() => {
                  saveNotification(editNotification);
                }}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                style={{
                  borderRadius: 5,
                  borderColor: "#177ec2",
                  backgroundColor: "white",
                  color: "#177ec2",
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Container>
    );
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} disableEnforceFocus>
      {dialogContent}
    </Dialog>
  );
}

interface IssueNotificationModalProps {
  loading: boolean;
  issueFilter: string;
  slackChannels: string[];
  notifications: Notification[];
  user: User;
  setNotificationFilter: (issue: any) => void;
  onPutNotifications: (params: PutNotificationRequestParams) => void;
  orgUsers: User[];
}

const mapStateToProps = (state) => ({
  user: selectUser(state),
});

const mapDispatchToProps = {
  onPutNotifications: putNotifications,
};

export const IssueNotificationModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueNotificationModalComponent);
