import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIcon, TextBody } from "components";
import { Link } from "react-router-dom";
import { getTitle, getDescription } from "utils/index";
import { Container, Typography } from "@mui/material";
import { theme } from "config/theme";
import { connect } from "react-redux";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  text: {
    color: "#008736",
  },
  text1: {
    color: "#656576",
  },
  itemLink: {
    fontWeight: 600,
    textDecoration: "none",
    color: "#177EC2",
    "&:hover": {
      color: theme.colors.primaryBrand.main,
    },
  },
}));

interface IntegrationCardProps {
  name: string;
  installed: boolean;
}

export const IntegrationCardComponent = ({
  name,
  installed,
}: IntegrationCardProps) => {
  const classes = useStyles();
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        padding: "15px",
        margin: "5px",
        width: "300px",
        height: "210px",
        background: "#ffffff",
        border: "1px solid #E3E3E3",
        borderRadius: "5px",
      }}
    >
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 0,
          margin: 0,
        }}
      >
        <SvgIcon name={name} size={24} />
        <Typography
          className={installed ? classes.text : classes.text1}
          style={{ fontWeight: 600, fontSize: 12 }}
        >
          {installed ? "Installed" : "Uninstalled"}
        </Typography>
      </Container>
      <Typography
        style={{
          fontFamily: "Work Sans",
          fontStyle: "normal",
          letterSpacing: "-0.02em",
          fontSize: "24px",
          fontWeight: 500,
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        {getTitle(name)}
      </Typography>
      <TextBody style={{ fontSize: 15 }}>{getDescription(name)}</TextBody>
      <Divider orientation="horizontal" style={{ width: "100%" }} />
      <Link to={`/integrations/${name}`} className={classes.itemLink}>
        {installed ? "Manage" : "Install"}
      </Link>
    </Container>
  );
};

export const IntegrationCard = connect()(IntegrationCardComponent);
