import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Container,
  Dialog,
  Divider,
  Button,
  TextField,
} from "@material-ui/core";
import { Spinner } from "components";
import { CustomScannerType } from "types";
import { saveCustomScanners } from "store/app/actions";
import { ISSUE_TYPE_DESCRIPTION } from "constants/index";

const useStyles = makeStyles({
  input: {
    "& input[type=number]::-webkit-inner-spin-button": {
      opacity: 1,
    },
    width: 80,
  },
});

export function EditScannerModalComponent({
  scanner_type,
  setEditScannerFilter,
  customScanners,
  loading,
  onSaveCustomScanners,
}: EditScannerModalProps) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [existingScanner, setExistingScanner] =
    useState<null | CustomScannerType>();
  const [configuredValue, setConfiguredValue] = useState(0);
  const [thresholdDescription, setThresholdDescription] = useState([]);
  const [thresholdType, setThresholdType] = useState("");
  const [thresholdDefault, setThresholdDefault] = useState("");
  const handleClose = () => {
    setIsOpen(false);
    setEditScannerFilter("");
  };

  useEffect(() => {
    if (scanner_type !== "") {
      setIsOpen(true);
      const scannerConstant = ISSUE_TYPE_DESCRIPTION[scanner_type];
      setTitle(scannerConstant.title);
      const filteredScanners = customScanners.filter(
        (scanner) => scanner.scanner_type === scanner_type
      );
      const scanner =
        filteredScanners && filteredScanners.length > 0
          ? filteredScanners[0]
          : null;
      setExistingScanner(scanner);
      setConfiguredValue(
        scanner
          ? scanner[scannerConstant.custom_threshold_type]!
          : scannerConstant.custom_threshold_default_value
      );
      setThresholdDescription(scannerConstant.custom_threshold_description);
      setThresholdType(scannerConstant.custom_threshold_type);
      setThresholdDefault(scannerConstant.custom_threshold_default_description);
    } else {
      setIsOpen(false);
      setTitle("");
      setExistingScanner(null);
    }
  }, [scanner_type]);

  const handleSave = () => {
    const newScanner: CustomScannerType = {
      scanner_type,
      [thresholdType]: configuredValue,
    };
    if (existingScanner) {
      newScanner.id = existingScanner.id;
    }
    onSaveCustomScanners([newScanner]);
    handleClose();
  };

  let dialogContent;
  if (loading) {
    dialogContent = <Spinner center />;
  } else {
    dialogContent = (
      <Container>
        <Grid
          container
          justifyContent="space-between"
          style={{ marginTop: 20 }}
        >
          <Grid item>
            <Typography style={{ fontSize: 18, fontWeight: 500 }}>
              Customize {title} Scanner
            </Typography>
          </Grid>
        </Grid>
        <Container
          style={{
            marginBottom: 20,
            padding: 0,
            paddingTop: 10,
          }}
        >
          <Container
            style={{
              flexDirection: "row",
              display: "flex",
              padding: 0,
              gap: "10px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography style={{ fontSize: 14, fontWeight: 500 }}>
              {thresholdDescription[0]}
            </Typography>
            <TextField
              className={classes.input}
              variant="outlined"
              size="small"
              inputProps={{ min: 0 }}
              type="number"
              defaultValue={configuredValue}
              onChange={(e) => {
                setConfiguredValue(Number(e.target.value));
              }}
            />
            <Typography style={{ fontSize: 14, fontWeight: 500 }}>
              {thresholdDescription[1]}
            </Typography>
          </Container>
          <Typography style={{ color: "gray", fontWeight: "lighter" }}>
            Default: {thresholdDefault}
          </Typography>
          <Divider style={{ marginTop: 5, marginBottom: 20 }} />
          <Grid container spacing={1}>
            <Grid item>
              <Button
                style={{
                  borderRadius: 5,
                  backgroundColor: "#177ec2",
                  color: "white",
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                style={{
                  borderRadius: 5,
                  borderColor: "#177ec2",
                  backgroundColor: "white",
                  color: "#177ec2",
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Container>
    );
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} disableEnforceFocus>
      {dialogContent}
    </Dialog>
  );
}

interface EditScannerModalProps {
  scanner_type: string;
  setEditScannerFilter: (filter: string) => void;
  customScanners: CustomScannerType[];
  loading: boolean;
  onSaveCustomScanners: (scanners: CustomScannerType[]) => void;
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  onSaveCustomScanners: saveCustomScanners,
};

export const EditScannerModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditScannerModalComponent);
