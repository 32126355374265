import React, { useState } from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TrashIconButton } from "components";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MenuItem } from "@material-ui/core";
import { useWindowDimensions } from "App";

const styles = () =>
  createStyles({
    formControl: {
      // width: "250px", // DL REMOVED TODO getting dropdown to some width
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#656576",
        borderRadius: "5px",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#656576",
      },
      "& .MuiInputLabel-root": {
        color: "#656576",
      },
      "& .MuiInputBase-root.MuiOutlinedInput-root": {
        borderRadius: "0px !important",
      },
      "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
        fontSize: "14px",
        fontFamily: "Work Sans",
        fontWeight: "500",
        letterSpacing: "-0.02em",
      },
      "& .MuiButtonBase-root.MuiChip-root": {
        height: "23px",
      },
    },
    formGroup: {
      marginLeft: "10px",
      "& .MuiTypography-root": {
        fontSize: "12px",
      },
      minWidth: "200px",
    },
    select: {
      width: "200px",
      height: "44px",
      "& .MuiOutlinedInput-input": {
        color: "#656576",
      },
      "& .MuiSelect-icon": {
        color: "#656576",
      },
    },
    accordion: {
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
      "& .MuiTypography-root": {
        fontSize: "12px",
      },
      minWidth: "200px",
    },
  });

export interface ExplorerSelectorProps extends WithStyles<typeof styles> {
  title: string;
  checkboxSelections: string[];
  handleChange: (event: any) => void;
  handleDelete?: (params: any) => void;
  groups: {};
  formatter: (option: string) => string;
  chipFormatter?: (option: string) => string;
  loading: boolean;
  isCheckbox: boolean;
  explorerViewSelection: string;
}

const ExplorerSelectorComponent = ({
  title,
  checkboxSelections,
  handleChange,
  handleDelete,
  groups,
  formatter,
  chipFormatter,
  loading,
  classes,
  isCheckbox,
  explorerViewSelection,
}: ExplorerSelectorProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const keys = Object.keys(groups);
  const { height } = useWindowDimensions();
  const handleOpen = () => {
    if (!(loading || keys.length === 0)) {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChipDelete = (chip) => () => {
    const e = { target: { value: chip, checked: false } };
    handleChange(e);
  };

  const renderValue = (checkboxSelection: any) => {
    if (loading) {
      return "Loading...";
    }
    if (isCheckbox) {
      if (keys.length === 0) {
        return `${title}`;
      }
      if (checkboxSelection.length === 0) {
        return `Select ${title}`;
      }
      if (checkboxSelection.length === 1) {
        return (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {checkboxSelection.map((value) => (
              <Chip
                key={value}
                label={chipFormatter ? chipFormatter(value) : formatter(value)}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onDelete={handleChipDelete(value)}
              />
            ))}
          </Box>
        );
      }
      return `${checkboxSelection.length} ${title} selected`;
    }
    if (keys.length === 0) {
      return "No saved views";
    }
    if (explorerViewSelection === "") {
      return "Select a View";
    }
    let viewName = null as any;
    keys.forEach((key) => {
      viewName = groups[key].find((view) => view.id === explorerViewSelection);
    });
    return viewName ? viewName.name : "";
  };

  const checkBoxDropdown = () => {
    if (keys.length === 0) return [];
    if (keys.length > 1) {
      return keys.map((key) => (
        <Accordion key={key} disableGutters className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {key.charAt(0).toUpperCase() + key.slice(1)} (
            {
              checkboxSelections.filter((value) =>
                value
                  .toLowerCase()
                  .startsWith(key.toLowerCase().split(" ").join("_"))
              ).length
            }
            )
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              {groups[key].map((option) => (
                <FormControlLabel
                  key={`{key}-${option}`}
                  control={<Checkbox onChange={handleChange} value={option} />}
                  label={formatter(option)}
                  checked={checkboxSelections.includes(option)}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      ));
    }
    return (
      <FormControl className={classes.formControl}>
        <FormGroup className={classes.formGroup}>
          {groups[keys[0]].map((option) => (
            <FormControlLabel
              key={option}
              control={<Checkbox onChange={handleChange} value={option} />}
              label={formatter(option)}
              checked={checkboxSelections.includes(option)}
            />
          ))}
        </FormGroup>
      </FormControl>
    );
  };

  // iterate through the explorerViews array and create a menu item for each one
  const explorerSavedViews = () => {
    const views: any[] = [];
    keys.forEach((view) => {
      groups[view].forEach((v) => {
        views.push(
          <Box
            key={`${view}-${v.id}`}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{ paddingRight: "5px" }}
          >
            <MenuItem
              key={v.id}
              value={v.id}
              style={{ width: "100%" }}
              onClick={() => handleChange(v.id)}
            >
              {v.name}
            </MenuItem>
            {handleDelete ? (
              <TrashIconButton
                variant="small"
                onClick={() => handleDelete(v.id)}
              />
            ) : null}
          </Box>
        );
      });
    });
    if (views.length > 0) {
      views.push(
        <MenuItem
          key="new"
          value="new"
          style={{ width: "100%" }}
          onClick={() => handleChange("")}
        >
          Create New View
        </MenuItem>
      );
    }
    return views;
  };

  return (
    <FormControl
      className={classes.formControl}
      disabled={loading || keys.length === 0}
    >
      <Select
        label={title}
        displayEmpty
        multiple={isCheckbox}
        className={classes.select}
        value={isCheckbox ? checkboxSelections : explorerViewSelection}
        input={<OutlinedInput />}
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        renderValue={renderValue}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          PaperProps: {
            style: {
              maxHeight: height * 0.77,
              border: "1px solid #c4c4c4",
              borderRadius: "0px",
            },
          },
        }}
      >
        {isCheckbox ? checkBoxDropdown() : explorerSavedViews()}
      </Select>
    </FormControl>
  );
};

const ExplorerSelector = withStyles(styles)(ExplorerSelectorComponent);

export { ExplorerSelector };
