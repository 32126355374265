import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import randomstring from "randomstring";

import { selectUser } from "store/auth/selectors";
import { PostAmazonOauthStateRequestParams, User } from "types";
import { postAmazonOauthState } from "store/app/actions";
import { OAUTH_AMAZON_SP } from "../../constants";
import { OnboardIntegrationButton } from "components/atoms/buttons/OnboardIntegrationButton";

const oauthState = randomstring.generate();

const AmazonOAuthBase = ({
  user,
  onPostAmazonOauthState,
  onSuccess,
  onFailure,
  disabled,
}: AmazonOAuthBaseProps) => {
  const [oauthUrl, setOauthUrl] = useState("");
  const orgId = user.currentOrganization.id;
  const handleClickAmazonLogin = () => {
    onPostAmazonOauthState({
      state: oauthState,
      org_id: orgId,
      function_name: OAUTH_AMAZON_SP,
    });
  };

  useEffect(() => {
    setOauthUrl(
      `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${process.env.REACT_APP_AMAZON_SELLER_APP_ID}&version=${process.env.REACT_APP_AMAZON_SELLER_APP_VERSION}&state=${oauthState}_US_${orgId}`
    );
  }, [orgId]);

  const polling = (popup) => {
    const poll = setInterval(() => {
      console.log(popup);
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(poll);
        console.log("Popup has been closed by user");
      }

      const closeDialog = () => {
        clearInterval(poll);
        popup.close();
      };

      try {
        if (!popup.location.hostname.includes("amazon")) {
          if (popup.location.search) {
            const query = new URLSearchParams(popup.location.search);
            const authCode = query.get("spapi_oauth_code");
            const state = query.get("state");
            const sellingPartnerId = query.get("selling_partner_id");
            closeDialog();
            if (authCode) {
              onSuccess({ authCode, state, sellingPartnerId });
            } else {
              onFailure(query.get("error"));
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 500);
  };

  const handleClick = () => {
    handleClickAmazonLogin();
    polling(window.open(oauthUrl, "_blank"));
  };

  return <OnboardIntegrationButton onClick={handleClick} disabled={disabled} />;
};

interface AmazonOAuthBaseProps {
  user: User;
  onPostAmazonOauthState: (params: PostAmazonOauthStateRequestParams) => void;
  onSuccess: (response: any) => void;
  onFailure: (response: any) => void;
  disabled?: boolean;
}

const mapStateToProps = (state) => ({
  user: selectUser(state),
});
const mapDispatchToProps = {
  onPostAmazonOauthState: postAmazonOauthState,
};

export const AmazonOAuth = connect(
  mapStateToProps,
  mapDispatchToProps
)(AmazonOAuthBase);
