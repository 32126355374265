import { AppState } from "types";

export const selectLoading = (state) => (state.app as AppState).loading;
export const selectExplorerViewsLoading = (state) =>
  (state.app as AppState).explorerViewsLoading;
export const selectExplorerDataLoading = (state) =>
  (state.app as AppState).explorerDataLoading;
export const selectIntegrationsLoading = (state) =>
  (state.app as AppState).integrationsLoading;
export const selectIntegrationLoading = (state) =>
  (state.app as AppState).integrationLoading;
export const selectOpenIssuesLoading = (state) =>
  (state.app as AppState).openIssuesLoading;
export const selectResolvedIssuesLoading = (state) =>
  (state.app as AppState).resolvedIssuesLoading;
export const selectScannersLoading = (state) =>
  (state.app as AppState).scannersLoading;
export const selectTrackedItemsCountLoading = (state) =>
  (state.app as AppState).trackedItemsCountLoading;
export const selectNotificationsLoading = (state) =>
  (state.app as AppState).notificationsLoading;
export const selectNotificationsSaveLoading = (state) =>
  (state.app as AppState).notificationsSaveLoading;
export const selectNotificationsUpdateMessage = (state) =>
  (state.app as AppState).updateNotificationsMessage;
export const selectCustomScannersLoading = (state) =>
  (state.app as AppState).customScannersLoading;
export const selectCustomScannersSaving = (state) =>
  (state.app as AppState).customScannersSaving;
export const selectSavingExplorerView = (state) =>
  (state.app as AppState).savingExplorerView;
export const selectExplorerViews = (state) =>
  (state.app as AppState).explorerViews;
export const selectExplorerView = (state) =>
  (state.app as AppState).explorerView;
export const selectAvailableMetrics = (state) =>
  (state.app as AppState).availableMetrics;
export const selectAllTagsForMetrics = (state) =>
  (state.app as AppState).allTagsForMetrics;
export const selectExplorerData = (state) =>
  (state.app as AppState).explorerData;
export const selectExplorerTotalData = (state) =>
  (state.app as AppState).explorerTotalData;
export const selectIntegrationInstallMessage = (state) =>
  (state.app as AppState).installMessage;
export const selectIntegrationUpdateMessage = (state) =>
  (state.app as AppState).updateIntegrationMessage;
export const selectInstalledIntegrations = (state) =>
  (state.app as AppState).installedIntegrations;
export const selectChannels = (state) => (state.app as AppState).channels;
export const selectIntegration = (state) => (state.app as AppState).integration;
export const selectHomepageLoading = (state) =>
  (state.app as AppState).homepageLoading;
export const selectHomepageOrdersGrossSales = (state) =>
  (state.app as AppState).homepage_orders_gross_sales;
export const selectOpenIssues = (state) => (state.app as AppState).open_issues;
export const selectResolvedIssues = (state) =>
  (state.app as AppState).resolved_issues;
export const selectScanners = (state) => (state.app as AppState).scanners;
export const selectTrackedItemsCount = (state) =>
  (state.app as AppState).tracked_items_count;
export const selectNotifications = (state) =>
  (state.app as AppState).notifications;
export const selectCustomScanners = (state) =>
  (state.app as AppState).customScanners;
export const selectInstallingIntegration = (state) =>
  (state.app as AppState).installingIntegration;
export const selectIsUpdatingIntegration = (state) =>
  (state.app as AppState).isUpdatingIntegration;
export const selectIsOnboardingInstall = (state) =>
  (state.app as AppState).isOnboardingInstall;
export const selectMetricsAllTagsLoading = (state) =>
  (state.app as AppState).metricsAllTagsLoading;
export const selectOrgUsers = (state) => (state.app as AppState).orgUsers;
