import React from "react";
import { OnboardIntegrationButton } from "components/atoms/buttons/OnboardIntegrationButton";
import { connect } from "react-redux";

const TikTokLoginBase = ({
  onSuccess,
  onFailure,
  disabled,
}: TikTokLoginBaseProps) => {
  const openPopup = () => {
    const width = 1000;
    const height = 850;
    const left = 300;
    const top = 50;

    const url =
      "https://ads.tiktok.com/marketing_api/auth?app_id=7014179817641738242&redirect_uri=https%3A%2F%2Fapp.trackstarhq.com%2Fintegrations%2Ftiktok&rid=h4hg3xkqftg";

    return window.open(
      url,
      "",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" + // eslint-disable-line prefer-template
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  const polling = (popup) => {
    const poll = setInterval(() => {
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(poll);
        onFailure("Popup has been closed by user");
      }

      const closeDialog = () => {
        clearInterval(poll);
        popup.close();
      };

      try {
        if (!popup.location.hostname.includes("tiktok.com")) {
          if (popup.location.search) {
            const query = new URLSearchParams(popup.location.search);
            const authCode = query.get("code");
            closeDialog();
            if (authCode) {
              onSuccess(authCode);
            } else if (onFailure) {
              onFailure(query.get("error"));
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 500);
  };

  const handleClick = () => {
    polling(openPopup());
  };

  return <OnboardIntegrationButton onClick={handleClick} disabled={disabled} />;
};

interface TikTokLoginBaseProps {
  onSuccess: (response: any) => void;
  onFailure: (response: any) => void;
  disabled?: boolean;
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export const TikTokLogin = connect(
  mapStateToProps,
  mapDispatchToProps
)(TikTokLoginBase);
