import React, { useState, useEffect } from "react";
import { TextField, Box } from "@material-ui/core";
import { connect } from "react-redux";
import { MuiButton } from "components";
import { selectUser } from "store/auth/selectors";
import { User } from "types";

const WooCommerceFormBase = ({ user }: WooCommerceFormBaseProps) => {
  const [storeURL, setstoreURL] = useState("");
  const [oauthUrl, setOauthUrl] = useState("");
  const orgId = user.currentOrganization.id;

  useEffect(() => {
    setOauthUrl(
      `https://${storeURL}/wc-auth/v1/authorize?app_name=Trackstar&scope=read&user_id=aaf43bbfdcc8897070959d21ea6af461&return_url=https%3A%2F%2Fapp.trackstarhq.com%2Fintegrations%2Fwoocommerce&callback_url=https://v9lz0tk2th.execute-api.us-east-1.amazonaws.com/prod/integrations/woocommerce/install?store_name=${storeURL}%26org_id=${orgId}`
    );
  }, [storeURL, orgId]);

  const polling = (popup) => {
    console.log(oauthUrl);
    const poll = setInterval(() => {
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(poll);
        console.log("Popup has been closed by user");
      }
    }, 500);
  };

  const handleClick = () => {
    polling(window.open(oauthUrl, "_self"));
  };
  return (
    <Box
      justifyContent="flex-start"
      flexDirection="row"
      alignItems="flex-end"
      display="flex"
      marginBottom={4}
    >
      <TextField
        required
        margin="dense"
        id="store_name"
        label="Store URL"
        type="standard"
        size="small"
        onChange={({ target: { value } }) => setstoreURL(value)}
        value={storeURL}
        style={{ marginRight: 10 }}
      />
      <MuiButton
        onClick={handleClick}
        variant="main"
        disabled={storeURL === ""}
      >
        Login with WooCommerce
      </MuiButton>
    </Box>
  );
};

interface WooCommerceFormBaseProps {
  user: User;
}

const mapStateToProps = (state) => ({
  user: selectUser(state),
});

const mapDispatchToProps = {};

export const WooCommerceForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(WooCommerceFormBase);
