import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { OnboardingModal } from "components/atoms/modals/OnboardingModal";
import carouselGif from "../../assets/carousel.gif";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFFFFF",
    border: "1px solid #E3E3E3",
    borderRadius: "5px",
    width: "100%",
    gap: "50px",
    padding: "1em",
  },
  numberStepContainer: {
    background: "#E9F6FF",
    borderRadius: "25px",
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "2px 9px",
    gap: "10px",
    margin: "0px",
  },
  numberStepFont: {
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#005B97",
  },
  stepRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0px",
    gap: "5px",
    margin: "0px",
    width: "auto",
  },
  stepsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0px",
    gap: "20px",
  },
}));

interface IssuesGetStartedProps {
  handleOnboardingFinish: () => void;
}

export const IssuesGetStarted = ({
  handleOnboardingFinish,
}: IssuesGetStartedProps) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <Container className={classes.container} maxWidth={false}>
      <Container style={{ width: "100%" }}>
        <Typography variant="h2">
          Resolve issues more quickly with Trackstar
        </Typography>
        <Typography>
          Connect the tools in your eCommerce stack using our 1-click Installs.
          In just minutes, we'll start scanning your tools for issues and
          problems, quantify the dollar impact, and get you on your way to
          fixing them.
        </Typography>
        <br />
        <Typography style={{ fontWeight: "bold" }}>
          Get started in 4 easy steps:
        </Typography>
        <br />
        <Container className={classes.stepsContainer}>
          <Container className={classes.stepRow}>
            <Container className={classes.numberStepContainer}>
              <Typography className={classes.numberStepFont}>1</Typography>
            </Container>
            <Typography>Storefronts</Typography>
          </Container>
          <Container className={classes.stepRow}>
            <Container className={classes.numberStepContainer}>
              <Typography className={classes.numberStepFont}>2</Typography>
            </Container>
            <Typography>Ad Accounts</Typography>
          </Container>
          <Container className={classes.stepRow}>
            <Container className={classes.numberStepContainer}>
              <Typography className={classes.numberStepFont}>3</Typography>
            </Container>
            <Typography>Your eCommerce stack</Typography>
          </Container>
          <Container className={classes.stepRow}>
            <Container className={classes.numberStepContainer}>
              <Typography className={classes.numberStepFont}>4</Typography>
            </Container>
            <Typography>Slack</Typography>
          </Container>
        </Container>
        <br />
        <Button
          variant="contained"
          onClick={() => setIsModalOpen(true)}
          size="large"
          style={{
            textTransform: "none",
            fontWeight: "bold",
            width: "50%",
            fontSize: "18px",
          }}
        >
          Begin Onboarding
        </Button>
        <OnboardingModal
          open={isModalOpen}
          handleCancel={() => setIsModalOpen(false)}
          handleFinish={handleOnboardingFinish}
        />
      </Container>
      <Container style={{ flexShrink: 1.6 }}>
        <img src={carouselGif} alt="carousel" height={400} />
      </Container>
    </Container>
  );
};
