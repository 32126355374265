import React, { useContext, useState } from "react";
import { IssueType, ScannerType, Notification } from "types";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import { Container, LinearProgress, Box } from "@mui/material";
import { Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@material-ui/core/styles";
import {
  INTEGRATION_ISSUE_TYPE_MAP,
  ISSUE_TYPE_DESCRIPTION,
  ISSUE_CATEGORIES,
} from "constants/index";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { StyledTooltip } from "components";
import { AnalyticsContext } from "index";
import { SvgIcon } from "../../components/atoms/commons/SvgIcon";

const useStyles = makeStyles(() => ({
  grid: {
    padding: "10px",
    backgroundColor: "white",
    width: "100%",
    margin: "0 auto",
    "& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell": {
      "&:focus": {
        outline: "none",
      },
      "&:focus-within": {
        outline: "none",
      },
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 500,
      color: "#656576",
      fontSize: "14px",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      letterSpacing: "-0.02em",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none !important",
    },
  },
  issueCellTypography: {
    fontSize: "16px",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: "-0.02em",
  },
  notificationEnabledIcon: {
    color: "#177EC2",
    visibility: "visible",
    cursor: "pointer",
    "&:hover": {
      fontSize: "30px !important",
    },
  },
  notificationDisabledIcon: {
    color: "#C4C4C4",
    visibility: "visible",
    cursor: "pointer",
    "&:hover": {
      fontSize: "32px !important",
    },
  },
  editIcon: {
    color: "#C4C4C4",
    visibility: "visible",
    cursor: "pointer",
    fontSize: "16px !important",
    "&:hover": {
      fontSize: "22px !important",
    },
  },
}));

export const IssuesTable = ({
  issues,
  scanners,
  notifications,
  loading,
  selectHandler,
  setNotificationFilter,
  setEditScannerFilter,
  pivotSelection,
  installedIntegrations,
}: IssuesTableComponentProps) => {
  const classes = useStyles();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "projected_impact", sort: "desc" },
  ]);
  const { analytics } = useContext(AnalyticsContext);

  const pivotSelectionMap = {
    "Issue Type": "issue_type",
    Category: "category",
    Integration: "platform",
  };

  const pivot = pivotSelectionMap[pivotSelection];

  const pivotToValuesMap = {};
  // iterate through issues and create a map of issue_type to count
  issues.forEach((issue) => {
    if (issue.status !== "TRIGGERED") return;
    let pivotValue = issue[pivot];
    if (pivot === "platform") {
      if (!installedIntegrations.includes(pivotValue)) {
        if (pivotValue === "instagram") {
          pivotValue = "facebook";
        } else {
          pivotValue = "Other";
        }
      }
    }
    const notificationEnabled = notifications.find(
      (notification) =>
        notification.issue_type.toLowerCase() === issue.issue_type.toLowerCase()
    )?.enabled;
    if (pivotToValuesMap[pivotValue]) {
      const prevEntry = pivotToValuesMap[pivotValue];
      const newCount = prevEntry.count + 1;
      pivotToValuesMap[pivotValue] = {
        count: newCount,
        projected_impact:
          prevEntry.projected_impact + issue.projected_daily_impact,
        id: issue.id,
        notification: notificationEnabled,
      };
    } else {
      pivotToValuesMap[pivotValue] = {
        count: 1,
        projected_impact: issue.projected_daily_impact,
        id: issue.id,
        notification: notificationEnabled,
      };
    }
  });

  // for each key in pivotToValuesMap, create a row with the key, count, and projected_impact
  const rows = Object.keys(pivotToValuesMap).map((key) => ({
    id: key,
    issueDescription: key,
    numIssues: {
      count: pivotToValuesMap[key].count,
    },
    projected_impact: pivotToValuesMap[key].projected_impact * 7,
    notification: pivotToValuesMap[key].notification,
  }));
  if (pivot === "issue_type") {
    // Go through installed integration, get the list of issues for each integration from INTEGRATION_ISSUE_TYPE_MAP, remove duplicates, and add to rows
    installedIntegrations.forEach((integration) => {
      let integrationIssueTypes;
      if (integration === "amazon") {
        integrationIssueTypes = INTEGRATION_ISSUE_TYPE_MAP.amazon_sp || [];
      } else {
        integrationIssueTypes = INTEGRATION_ISSUE_TYPE_MAP[integration] || [];
      }
      integrationIssueTypes.forEach((issueType) => {
        if (!rows.find((row) => row.issueDescription === issueType)) {
          // check if notification is enabled for this issue
          const notification = notifications.find(
            (n) => n.issue_type.toLowerCase() === issueType.toLowerCase()
          );
          let notificationOn = false;
          if (notification) {
            notificationOn = notification.enabled;
          }
          rows.push({
            id: issueType,
            issueDescription: issueType,
            numIssues: { count: 0 },
            projected_impact: 0,
            notification: notificationOn,
          });
        }
      });
    });
  } else if (pivot === "platform") {
    installedIntegrations.forEach((integration) => {
      if (
        !rows.find((row) => row.issueDescription === integration) &&
        integration !== "slack" &&
        integration !== "google_analytics"
      ) {
        rows.push({
          id: integration,
          issueDescription: integration,
          numIssues: { count: 0 },
          projected_impact: 0,
          notification: false,
        });
      }
    });
  } else if (pivot === "category") {
    ISSUE_CATEGORIES.forEach((category) => {
      if (!rows.find((row) => row.issueDescription === category)) {
        rows.push({
          id: category,
          issueDescription: category,
          numIssues: { count: 0 },
          projected_impact: 0,
          notification: false,
        });
      }
    });
  }

  const onSeeIssuesClick = (params) => {
    analytics.track(`See Issues Clicked`, {
      issueDescription: params.issueDescription,
    });
    selectHandler(params.issueDescription);
  };

  const onNotificationClick = (params) => {
    analytics.track(`Notification Modal Opened`, {
      issueDescription: params.issueDescription,
      areNotificationsOn: `${params.notification}`,
    });
    setNotificationFilter(params.issueDescription);
  };

  const seeMore = (params) => (
    <Typography
      variant="body2"
      style={{ color: "#177EC2", cursor: "pointer" }}
      onClick={() => {
        onSeeIssuesClick(params.row);
      }}
    >
      See Issues
    </Typography>
  );

  const setNotifications = (params) =>
    params.row.notification ? (
      <NotificationsIcon
        onClick={() => {
          onNotificationClick(params.row);
        }}
        className={classes.notificationEnabledIcon}
      />
    ) : (
      <NotificationsOutlinedIcon
        onClick={() => {
          onNotificationClick(params.row);
        }}
        className={classes.notificationDisabledIcon}
      />
    );

  const getScanner = (scanner_type: string) => {
    const scr = scanners.filter(
      (scanner) => scanner.scanner === scanner_type
    )[0];
    if (!scr) {
      return {
        progress: 100,
        time_remaining: "N/A",
      };
    }
    return scr;
  };

  const dollarFormatter = (params) => {
    const rowVal = params.row.projected_impact || 0;
    // format rowVal to 2 decimal places and add $ and commas
    // thankyou copilot
    return `$${rowVal.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };
  const projectedDollarImpact = (params) => (
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "5px",
        padding: "0px",
      }}
    >
      <Typography className={classes.issueCellTypography}>
        {dollarFormatter(params)}
      </Typography>
      {pivotSelection === "Issue Type" && (
        <StyledTooltip
          title={
            // eslint-disable-next-line react/destructuring-assignment
            ISSUE_TYPE_DESCRIPTION[params.row.issueDescription]
              .projected_impact_calculation
          }
          placement="top-start"
        >
          {/* padding bc doesn't align perfectly, probably better way to align items */}
          <div
            style={{
              cursor: "pointer",
              height: "16px",
            }}
          >
            <InfoOutlinedIcon sx={{ fontSize: "medium" }} />
          </div>
        </StyledTooltip>
      )}
    </Container>
  );
  const progressBar = (scanner) => (
    <div>
      <Typography variant="body2" style={{ whiteSpace: "initial" }}>
        Scanning will begin once historical data has been loaded.
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            variant="determinate"
            value={scanner.progress}
            style={{ height: "8px" }}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2">{scanner.progress}%</Typography>
        </Box>
      </Box>
      <Typography variant="caption" style={{ whiteSpace: "initial" }}>
        {scanner.time_remaining}
      </Typography>
    </div>
  );

  const editScanner = (params) => {
    const scanner = ISSUE_TYPE_DESCRIPTION[params.row.issueDescription];
    if (!scanner.editable) {
      return "";
    }
    return (
      <EditIcon
        className={classes.editIcon}
        onClick={() => setEditScannerFilter(params.row.issueDescription)}
      />
    );
  };

  let columns: GridColDef[] = [];
  if (!loading) {
    columns = [
      {
        field: "numIssues",
        headerName: "# of issues",
        renderCell: (params) => {
          // eslint-disable-next-line react/destructuring-assignment
          let value = params.value.count;
          // eslint-disable-next-line react/destructuring-assignment
          const scanner = getScanner(params.row.issueDescription);
          if (pivotSelection === "Issue Type") {
            if (scanner.progress < 100) {
              value = "-";
            }
          }
          return <Typography>{value}</Typography>;
        },
        align: "center",
        sortComparator: (v1, v2) => v1.count - v2.count,
      },
      {
        field: "issueDescription",
        headerName: pivotSelection,
        // renderCell -> depends on pivot selection
        renderCell: (params) => {
          const capitalized =
            // eslint-disable-next-line react/destructuring-assignment
            params.value.charAt(0).toUpperCase() + params.value.slice(1);
          let val;
          if (pivotSelection === "Integration") {
            let formattedVal;
            if (params.value.includes("_")) {
              formattedVal = params.value
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
            }
            val = (
              <div style={{ display: "flex", gap: "5px" }}>
                <SvgIcon name={params.value} size={20} />
                <Typography className={classes.issueCellTypography}>
                  {formattedVal || capitalized}
                </Typography>
              </div>
            );
          } else if (pivotSelection === "Issue Type") {
            const scanner = ISSUE_TYPE_DESCRIPTION[params.value];
            val = (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Typography className={classes.issueCellTypography}>
                    {scanner.title}
                  </Typography>
                  {editScanner(params)}
                </div>
                <span style={{ color: "gray", fontWeight: "lighter" }}>
                  {scanner.description}
                </span>
              </div>
            );
          } else {
            val = (
              <Typography className={classes.issueCellTypography}>
                {capitalized}
              </Typography>
            );
          }
          return <div>{val}</div>;
        },
        flex: 3,
      },
      {
        field: "projected_impact",
        headerName: "Projected Weekly Impact",
        align: "right" as const,
        headerAlign: "right" as const,
        colSpan: (params) => {
          const scanner = getScanner(params.row.issueDescription);
          if (pivotSelection !== "Issue Type" || scanner.progress === 100) {
            return 1;
          }
          return 2;
        },
        flex: 1,
        renderCell: (params) => {
          const scanner = getScanner(params.row.issueDescription);
          if (pivotSelection !== "Issue Type" || scanner.progress === 100) {
            return projectedDollarImpact(params);
          }
          return progressBar(scanner);
        },
      },
      {
        field: "notification",
        headerName: "Notify Me?",
        renderCell: (params) => setNotifications(params),
        align: "center" as const,
        headerAlign: "center" as const,
        flex: 0.5,
        width: 50,
        hide: pivotSelection !== "Issue Type",
      },
      {
        field: "see_more",
        headerName: "",
        renderCell: (params) => seeMore(params),
        align: "right" as const,
        headerAlign: "right" as const,
        flex: 0.5,
        width: 50,
      },
    ];
  }

  return (
    <DataGrid
      className={classes.grid}
      style={{
        borderRadius: "5px",
      }}
      loading={loading}
      rows={rows}
      columns={columns}
      disableColumnMenu
      disableSelectionOnClick
      rowsPerPageOptions={[]}
      hideFooter={rows.length < 100}
      sx={{
        "& .MuiDataGrid-columnHeaders": {
          display: rows.length === 0 ? "none" : "flex",
        },
        "& .total-row": {
          fontWeight: "bold",
        },
      }}
      sortModel={sortModel}
      onSortModelChange={(model) => {
        if (!loading) {
          setSortModel(model);
        }
      }}
      autoHeight
      getRowHeight={(params) => {
        const scanner = getScanner(params.model.issueDescription);
        if (scanner && scanner.progress < 100) {
          return "auto";
        }
        return 60;
      }}
      components={{
        LoadingOverlay: LinearProgress,
      }}
    />
  );
};

interface IssuesTableComponentProps {
  issues: IssueType[];
  scanners: ScannerType[];
  notifications: Notification[];
  loading: boolean;
  selectHandler: (issue: any) => void;
  setNotificationFilter: (issue: any) => void;
  setEditScannerFilter: (issue: any) => void;
  pivotSelection: string;
  installedIntegrations: string[];
}
