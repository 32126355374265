import React, { useContext, useEffect } from "react";
import { TextH2, IntegrationCard, Spinner } from "components";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { InstalledIntegrationsResponseType, SetIntegrationParams } from "types";
import {
  installIntegration,
  getInstalledIntegrations,
  setIntegration,
} from "store/app/actions";
import { connect } from "react-redux";
import {
  selectInstalledIntegrations,
  selectIntegrationsLoading,
} from "store/app/selectors";
import { INTEGRATIONS } from "constants/index";
import { Typography } from "@mui/material";
import { AnalyticsContext } from "index";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const IntegrationsBase = ({
  integrationsLoading,
  installedIntegrations,
  onGetInstalledIntegrations,
  onSetIntegration,
}: IntegrationsBaseProps) => {
  const classes = useStyles();
  const { analytics } = useContext(AnalyticsContext);

  useEffect(() => {
    analytics.page("Integrations Page");
    onSetIntegration({ integration_name: "", data: [] });
  }, []);

  useEffect(() => {
    if (installedIntegrations.count == -1) {
      onGetInstalledIntegrations();
    }
  }, [JSON.stringify(installedIntegrations)]);

  const sortedInstalledIntegrations = [
    ...installedIntegrations.integrations,
  ].sort();
  const sortedUninstalledIntegrations = INTEGRATIONS.filter(
    (integration) => !sortedInstalledIntegrations.includes(integration)
  ).sort();

  if (integrationsLoading) {
    return <Spinner center />;
  }
  return (
    <Container maxWidth={false} className={classes.container}>
      <TextH2>Integrations</TextH2>
      <Container
        style={{
          padding: 0,
          margin: 0,
          gap: "15px",
          display: "flex",
          flexDirection: "column",
        }}
        maxWidth={false}
      >
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
            paddingTop: "15px",
            margin: 0,
          }}
          maxWidth={false}
        >
          {installedIntegrations.count > 0 && (
            <>
              <Typography
                style={{
                  fontFamily: "Work Sans",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "24px",
                  letterSpacing: "-0.02em",
                }}
              >
                Linked Integrations
              </Typography>
              <Container
                style={{
                  padding: 0,
                  margin: 0,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
                maxWidth={false}
              >
                {sortedInstalledIntegrations.map((integration) => (
                  <IntegrationCard
                    key={integration}
                    name={integration}
                    installed
                  />
                ))}
              </Container>
            </>
          )}
        </Container>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
            margin: 0,
          }}
          maxWidth={false}
        >
          <Typography
            style={{
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "24px",
              letterSpacing: "-0.02em",
            }}
          >
            Available Integrations
          </Typography>
          <Container
            style={{
              padding: 0,
              margin: 0,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
            maxWidth={false}
          >
            {sortedUninstalledIntegrations.map((integration) => (
              <IntegrationCard
                key={integration}
                name={integration}
                installed={false}
              />
            ))}
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

interface IntegrationsBaseProps {
  integrationsLoading: boolean;
  installedIntegrations: InstalledIntegrationsResponseType;
  onGetInstalledIntegrations: () => void;
  onSetIntegration: (params: SetIntegrationParams) => void;
}

const mapStateToProps = (state) => ({
  integrationsLoading: selectIntegrationsLoading(state),
  installedIntegrations: selectInstalledIntegrations(state),
});

const mapDispatchToProps = {
  onInstallIntegration: installIntegration,
  onGetInstalledIntegrations: getInstalledIntegrations,
  onSetIntegration: setIntegration,
};

export const Integrations = connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrationsBase);
