import React from "react";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
} from "@mui/x-data-grid-pro";
import { makeStyles } from "@material-ui/core/styles";
import {
  formatMetricName,
  SVGS,
  formatMetricValue,
  formatTimeColumn,
  formatMetricNameWithoutHeader,
} from "utils";
import { styled, Typography } from "@material-ui/core";
import moment from "moment";
import { useWindowDimensions } from "App";
import { LinearProgress } from "@mui/material";
import { LicenseInfo } from "@mui/x-license-pro";
import { StyledTooltip } from "components";
import { SvgIcon } from "../../components/atoms/commons/SvgIcon";

LicenseInfo.setLicenseKey(
  "d6f0edbc76f6507690503bd0d4752985Tz00OTc1MyxFPTE2OTMwNzk3ODQzNjIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const useStyles = makeStyles(() => ({
  grid: {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 500,
      color: "#656576",
      fontSize: "14px",
      fontFamily: "Work Sans",
      fontStyle: "normal",
    },
    "& .MuiDataGrid-pinnedRows": {
      borderTop: "1px solid #E5E5E5",
      boxShadow: "none",
    },
  },
}));

const StyledGridOverlay = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  backgroundImage: `url(${SVGS.Watermark2})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  textAlign: "center",
  "& .emptyMessageText": {
    fontSize: 32,
    fontWeight: 600,
    lineHeight: "48px",
  },
});

const generateColumns = (metrics, newAttributes) => {
  const columns: GridColDef[] = [];
  // We only want to create attribute cols when there is a metric(s) selected
  const splitAttributes = metrics.length > 0 ? newAttributes.split(",") : [];
  splitAttributes.forEach((attribute) => {
    const attributeCol = {
      field: attribute.length > 0 ? attribute : "everything",
      // split by "." and take the last elements
      headerName: formatMetricName(attribute),
      flex: 1,
      align: "left" as const,
      headerAlign: "left" as const,
    } as any;
    if (attribute === "platform") {
      attributeCol.renderCell = (params) => {
        if (params && params.value && params.value !== "Total") {
          // eslint-disable-next-line react/destructuring-assignment
          return <SvgIcon name={params.formattedValue} size={24} />;
        }
        return params.value;
      };
    } else if (attribute !== "time") {
      attributeCol.renderCell = (params) => (
        <StyledTooltip
          // eslint-disable-next-line react/destructuring-assignment
          title={<Typography color="inherit">{params.value}</Typography>}
        >
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
          >
            {/* eslint-disable-next-line react/destructuring-assignment */}
            {params.value}
          </span>
        </StyledTooltip>
      );
    }

    columns.push(attributeCol);
  });
  metrics.forEach((metric) => {
    columns.push({
      field: metric,
      // split by "." and take the last elements
      headerName: formatMetricNameWithoutHeader(metric),
      flex: 1,
      align: "right" as const,
      headerAlign: "right" as const,
      valueFormatter: formatMetricValue,
    });
  });
  return columns;
};

const getTotalRow = (explorerTotalData, selectedMetrics, columns) => {
  const totalRow = {} as any;
  totalRow.id = "Total_Row";
  totalRow.label = "Total";
  // first attribute is one with column span set
  totalRow[columns[0].field] = "Total";
  explorerTotalData.forEach((metric) => {
    totalRow[metric.metric_name] =
      metric.data.length > 0 ? metric.data[0].everything : 0;
  });
  selectedMetrics.forEach((metric) => {
    // if totalRow doesn't have a value for metric i.e it's not a summable metric, set to N/A
    if (!totalRow[metric]) {
      totalRow[metric] = "N/A";
    }
  });
  return totalRow;
};

function NoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <Typography className="emptyMessageText">
        Select a Metric or View to Get Started
      </Typography>
    </StyledGridOverlay>
  );
}

function NoDataReturnedOverlay() {
  return (
    <StyledGridOverlay>
      <Typography className="emptyMessageText">
        There is no data for the metric you selected
      </Typography>
    </StyledGridOverlay>
  );
}

export const ExplorerGridComponent = ({
  selectedMetrics,
  selectedAttributes,
  explorerData,
  explorerTotalData,
  isExplorerLoading,
  timeBreakdown,
  isExplorerSavedViewLoading,
  apiRef,
  setGridState,
}: ExplorerGridComponentProps) => {
  const { height } = useWindowDimensions();
  // set up grid listeners
  const handleGridChangeEvent = (event) => {
    // sometimes this handler is triggered when switching between save views. Don't want to update state in that case
    if (!Object.keys(event).length) return;
    const currCols = apiRef.current.getAllColumns();
    const totalRow = getTotalRow(explorerTotalData, selectedMetrics, currCols);
    setGridState(apiRef.current.exportState());
    // TODO: MUI grid will eventually better support updating pinned rows
    apiRef.current.unstable_setPinnedRows({ top: [], bottom: [totalRow] });
  };

  const rows = [] as any;
  // rowDict is a mapping from attribute to a list of {metric: value} pairs
  const rowDict = {} as any;
  const metricTotals = {} as any;
  const classes = useStyles();
  let newAttributes = selectedAttributes;
  if (timeBreakdown && timeBreakdown.length > 0) {
    newAttributes = `${selectedAttributes},time`;
  }
  const columns = generateColumns(selectedMetrics, newAttributes);
  // find time column and set value getter
  const timeColumn = columns.find((col) => col.field === "time");
  if (timeColumn && explorerData.length > 0) {
    const startquery = explorerData[0].start;
    const endquery = explorerData[0].stop;
    timeColumn.valueFormatter = (value) => {
      if (value.value === "Loading...") {
        return value.value;
      }
      return formatTimeColumn(value.value, timeBreakdown, startquery, endquery);
    };
  }
  if (!isExplorerSavedViewLoading && columns.length > 0) {
    explorerData.forEach((metricRows) => {
      // each row represents data returned per metric
      if (!metricRows.data || metricRows.data.length === 0) return;
      let groupByTags = metricRows.group_by_tags;
      if (groupByTags.length > 0) {
        groupByTags = groupByTags.split(",");
      }
      metricRows.data.forEach((row) => {
        // row is a list of key-value pairs representing { "time": time, "comma separated attribute tags" : "value for metric"}
        const attributeValues = Object.keys(row);
        attributeValues.forEach((attribute) => {
          if (attribute === "time") return;
          const metricRow = {} as any;
          const metricVal = row[attribute];
          metricRow[metricRows.metric_name] = metricVal;
          const splitAttributeValues = attribute.split(", ");
          for (let i = 0; i < groupByTags.length; i += 1) {
            metricRow[groupByTags[i]] = splitAttributeValues[i];
          }
          if (groupByTags.length === 0) {
            metricRow.everything = "Total";
          }
          // track metric totals
          if (!metricTotals[metricRows.metric_name]) {
            metricTotals[metricRows.metric_name] = 0;
          }
          metricTotals[metricRows.metric_name] += metricVal;
          let key = "";
          if (timeBreakdown && timeBreakdown.length > 0) {
            const timeValue = row.time;
            metricRow.time = !isExplorerLoading
              ? moment.unix(timeValue)
              : "Loading...";
            key = `${timeValue}, ${attribute}`;
          } else {
            key = attribute;
          }
          const rowDictKey = rowDict[key] ? rowDict[key] : [];
          rowDictKey.push(metricRow);
          rowDict[key] = rowDictKey;
        });
      });
    });
  }

  // iterate through keys of rowDict and flatten
  Object.keys(rowDict).forEach((key) => {
    let currRow = {} as any;
    rowDict[key].forEach((row) => {
      currRow = { ...currRow, ...row };
    });
    // required for MUI grid
    currRow.id = rows.length;
    rows.push(currRow);
  });

  // if attributes, add total row
  let pinnedRows = {};
  if (
    newAttributes &&
    newAttributes.length > 0 &&
    columns.length > 0 &&
    explorerTotalData
  ) {
    const totalRow = getTotalRow(explorerTotalData, selectedMetrics, columns);
    pinnedRows = { top: [], bottom: [totalRow] };
  }
  const getCellClassName: DataGridProProps["getCellClassName"] = ({ row }) => {
    if (row.id === "Total_Row") {
      return "total-row";
    }
    return "";
  };

  return (
    <DataGridPro
      onSortModelChange={handleGridChangeEvent}
      onColumnResize={handleGridChangeEvent}
      onColumnVisibilityChange={handleGridChangeEvent}
      onColumnOrderChange={handleGridChangeEvent}
      onColumnWidthChange={handleGridChangeEvent}
      apiRef={apiRef}
      style={{
        height: height * 0.76,
        padding: "10px",
        backgroundColor: "white",
        borderRadius: "5px",
      }}
      pagination
      className={classes.grid}
      rows={rows}
      experimentalFeatures={{ rowPinning: true }}
      pinnedRows={pinnedRows}
      columns={columns}
      loading={
        isExplorerLoading ||
        (explorerData.length === 0 && selectedMetrics.length !== 0)
      }
      components={{
        NoRowsOverlay: () => {
          if (selectedMetrics.length === 0 && !isExplorerLoading) {
            return <NoRowsOverlay />;
          }
          if (selectedMetrics.length > 0) {
            return <NoDataReturnedOverlay />;
          }
          return <div />;
        },
        LoadingOverlay: LinearProgress,
      }}
      disableColumnMenu
      rowsPerPageOptions={[]}
      hideFooter={rows.length < 100}
      getCellClassName={getCellClassName}
      sx={{
        "& .MuiDataGrid-columnHeaders": {
          display: rows.length === 0 ? "none" : "flex",
        },
        "& .total-row": {
          fontWeight: "bold",
        },
      }}
    />
  );
};

interface ExplorerGridComponentProps {
  selectedMetrics: string[];
  selectedAttributes: string;
  explorerData: any;
  explorerTotalData: any;
  isExplorerLoading: boolean;
  timeBreakdown: string;
  isExplorerSavedViewLoading: boolean;
  apiRef: any;
  setGridState: (boolean) => void;
}
