import produce from "immer";
import * as types from "types";
import { AppAction, AppState } from "types";
import { AppActionType } from "./actions";

const INITIAL_STATE: AppState = {
  loading: false,
  explorerViewsLoading: false,
  integrationsLoading: false,
  integrationLoading: false,
  openIssuesLoading: false,
  resolvedIssuesLoading: false,
  scannersLoading: false,
  trackedItemsCountLoading: false,
  notificationsLoading: false,
  notificationsSaveLoading: false,
  customScannersLoading: false,
  customScannersSaving: false,
  homepageLoading: false,
  explorerDataLoading: false,
  savingExplorerView: false,
  installingIntegration: false,
  isUpdatingIntegration: false,
  isOnboardingInstall: false,
  metricsAllTagsLoading: false,
  explorerData: [],
  explorerTotalData: [],
  explorerViews: [],
  explorerView: {
    id: "",
    name: "",
    selected_filters: "",
    selected_metrics: "",
    selected_attributes: "",
    grid_state: "",
    time_breakdown: "",
    timeframe: "",
    start_time: "",
    end_time: "",
  },
  error: null,
  allTagsForMetrics: {
    tags: {},
  },
  orgUsers: [],
  availableMetrics: [],
  installMessage: "",
  installedIntegrations: {
    count: -1,
    integrations: [],
  },
  channels: [],
  integration: {
    integration_name: "",
    data: [],
  },
  homepage_orders_gross_sales: -1,
  open_issues: [],
  resolved_issues: [],
  scanners: [],
  tracked_items_count: -1,
  notifications: [],
  customScanners: [],
  updateIntegrationMessage: "",
  updateNotificationsMessage: "",
};

export const app = produce((draft, action: AppAction) => {
  switch (action.type) {
    case AppActionType.FETCH_APP_START:
      draft.loading = true;
      break;
    case AppActionType.FETCH_APP_SUCCESS:
      draft.loading = false;
      draft.error = null;
      break;
    case AppActionType.FETCH_APP_FAILURE:
      const { error } = action as types.FetchAppFailure;
      draft.loading = false;
      draft.error = error;
      break;
    case AppActionType.FETCH_EXPLORER_VIEWS_START:
      draft.explorerViewsLoading = true;
      break;
    case AppActionType.FETCH_EXPLORER_VIEWS_SUCCESS:
      draft.explorerViewsLoading = false;
      draft.error = null;
      break;
    case AppActionType.FETCH_EXPLORER_VIEWS_FAILURE:
      draft.explorerViewsLoading = false;
      break;
    case AppActionType.FETCH_INSTALLED_INTEGRATIONS_START:
      draft.integrationsLoading = true;
      break;
    case AppActionType.FETCH_INSTALLED_INTEGRATIONS_SUCCESS:
      draft.integrationsLoading = false;
      draft.error = null;
      break;
    case AppActionType.FETCH_INSTALLED_INTEGRATIONS_FAILURE:
      const { installedIntegrationsError } =
        action as types.FetchInstalledIntegrationsFailure;
      draft.integrationsLoading = false;
      draft.error = installedIntegrationsError;
      break;
    case AppActionType.FETCH_INTEGRATION_START:
      draft.integrationLoading = true;
      break;
    case AppActionType.FETCH_INTEGRATION_SUCCESS:
      draft.integrationLoading = false;
      draft.error = null;
      break;
    case AppActionType.FETCH_INTEGRATION_FAILURE:
      const { integrationError } = action as types.FetchIntegrationFailure;
      draft.integrationLoading = false;
      draft.error = integrationError;
      break;
    case AppActionType.FETCH_OPEN_ISSUES_START:
      draft.openIssuesLoading = true;
      break;
    case AppActionType.FETCH_OPEN_ISSUES_SUCCESS:
      draft.openIssuesLoading = false;
      draft.error = null;
      break;
    case AppActionType.FETCH_OPEN_ISSUES_FAILURE:
      const { openIssuesError } = action as types.FetchOpenIssuesFailure;
      draft.openIssuesLoading = false;
      draft.error = openIssuesError;
      break;
    case AppActionType.FETCH_RESOLVED_ISSUES_START:
      draft.resolvedIssuesLoading = true;
      break;
    case AppActionType.FETCH_RESOLVED_ISSUES_SUCCESS:
      draft.resolvedIssuesLoading = false;
      draft.error = null;
      break;
    case AppActionType.FETCH_RESOLVED_ISSUES_FAILURE:
      const { resolvedIssuesError } =
        action as types.FetchResolvedIssuesFailure;
      draft.resolvedIssuesLoading = false;
      draft.error = resolvedIssuesError;
      break;
    case AppActionType.FETCH_SCANNERS_START:
      draft.scannersLoading = true;
      break;
    case AppActionType.FETCH_SCANNERS_SUCCESS:
      draft.scannersLoading = false;
      draft.error = null;
      break;
    case AppActionType.FETCH_SCANNERS_FAILURE:
      const { scannersError } = action as types.FetchScannersFailure;
      draft.scannersLoading = false;
      draft.error = scannersError;
      break;
    case AppActionType.FETCH_TRACKED_ITEMS_COUNT_START:
      draft.trackedItemsCountLoading = true;
      break;
    case AppActionType.FETCH_TRACKED_ITEMS_COUNT_SUCCESS:
      draft.trackedItemsCountLoading = false;
      draft.error = null;
      break;
    case AppActionType.FETCH_TRACKED_ITEMS_COUNT_FAILURE:
      const { trackedItemsCountError } =
        action as types.FetchTrackedItemsCountFailure;
      draft.trackedItemsCountLoading = false;
      draft.error = trackedItemsCountError;
      break;
    case AppActionType.FETCH_NOTIFICATIONS_START:
      draft.notificationsLoading = true;
      break;
    case AppActionType.FETCH_NOTIFICATIONS_SUCCESS:
      draft.notificationsLoading = false;
      draft.error = null;
      break;
    case AppActionType.FETCH_NOTIFICATIONS_FAILURE:
      const { notificationsError } = action as types.FetchNotificationsFailure;
      draft.notificationsLoading = false;
      draft.error = notificationsError;
      break;
    case AppActionType.SAVE_NOTIFICATIONS_START:
      draft.notificationsSaveLoading = true;
      break;
    case AppActionType.SAVE_NOTIFICATIONS_SUCCESS:
      draft.notificationsSaveLoading = false;
      draft.error = null;
      break;
    case AppActionType.SAVE_NOTIFICATIONS_FAILURE:
      const { notificationsSaveError } =
        action as types.SaveNotificationsFailure;
      draft.notificationsSaveLoading = false;
      draft.error = notificationsSaveError;
      break;
    case AppActionType.FETCH_CUSTOM_SCANNERS_START:
      draft.customScannersLoading = true;
      break;
    case AppActionType.FETCH_CUSTOM_SCANNERS_SUCCESS:
      draft.customScannersLoading = false;
      draft.error = null;
      break;
    case AppActionType.FETCH_CUSTOM_SCANNERS_FAILURE:
      const { customScannersError } =
        action as types.FetchCustomScannersFailure;
      draft.customScannersLoading = false;
      draft.error = customScannersError;
      break;
    case AppActionType.SAVE_CUSTOM_SCANNERS_START:
      draft.customScannersSaving = true;
      break;
    case AppActionType.SAVE_CUSTOM_SCANNERS_SUCCESS:
      draft.customScannersSaving = false;
      draft.error = null;
      break;
    case AppActionType.SAVE_CUSTOM_SCANNERS_FAILURE:
      const { customScannersSaveError } =
        action as types.SaveCustomScannersFailure;
      draft.customScannersSaving = false;
      draft.error = customScannersSaveError;
      break;
    case AppActionType.FETCH_EXPLORER_DATA_START:
      draft.explorerDataLoading = true;
      break;
    case AppActionType.FETCH_EXPLORER_DATA_SUCCESS:
      draft.explorerDataLoading = false;
      draft.error = null;
      break;
    case AppActionType.FETCH_EXPLORER_DATA_FAILURE:
      const { explorerError } = action as types.FetchExplorerDataFailure;
      draft.explorerDataLoading = false;
      draft.error = explorerError;
      break;
    case AppActionType.FETCH_METRICS_ALL_TAGS_START:
      draft.metricsAllTagsLoading = true;
      break;
    case AppActionType.FETCH_METRICS_ALL_TAGS_SUCCESS:
      draft.metricsAllTagsLoading = false;
      draft.error = null;
      break;
    case AppActionType.FETCH_METRICS_ALL_TAGS_FAILURE:
      const { metricsAllTagsError } =
        action as types.FetchMetricsAllTagsFailure;
      draft.metricsAllTagsLoading = false;
      draft.error = metricsAllTagsError;
      break;
    case AppActionType.INSTALLING_INTEGRATION_START:
      draft.installingIntegration = true;
      break;
    case AppActionType.INSTALLING_INTEGRATION_SUCCESS:
      draft.installingIntegration = false;
      draft.error = null;
      break;
    case AppActionType.INSTALLING_INTEGRATION_FAILURE:
      const { installingIntegrationError } =
        action as types.InstallingIntegrationFailure;
      draft.installingIntegration = false;
      draft.error = installingIntegrationError;
      break;
    case AppActionType.UPDATING_INTEGRATION_START:
      draft.isUpdatingIntegration = true;
      break;
    case AppActionType.UPDATING_INTEGRATION_SUCCESS:
      draft.isUpdatingIntegration = false;
      draft.error = null;
      break;
    case AppActionType.UPDATING_INTEGRATION_FAILURE:
      const { updateIntegrationError } =
        action as types.UpdatingIntegrationFailure;
      draft.isUpdatingIntegration = false;
      draft.error = updateIntegrationError;
      break;
    case AppActionType.SAVE_EXPLORER_VIEW_START:
      draft.savingExplorerView = true;
      break;
    case AppActionType.SAVE_EXPLORER_VIEW_SUCCESS:
      draft.savingExplorerView = false;
      draft.error = null;
      break;
    case AppActionType.SAVE_EXPLORER_VIEW_FAILURE:
      const { explorerSaveError } = action as types.SaveExplorerViewFailure;
      draft.savingExplorerView = false;
      draft.error = explorerSaveError;
      break;
    case AppActionType.SET_EXPLORER_VIEWS:
      const { explorerViews } = action as types.SetExplorerViews;
      draft.explorerViews = explorerViews;
      break;
    case AppActionType.SET_EXPLORER_VIEW:
      const { explorerView } = action as types.SetExplorerView;
      draft.explorerView = explorerView;
      break;
    case AppActionType.SET_AVAILABLE_METRICS:
      draft.availableMetrics = (action as types.SetAvailableMetrics).metrics;
      break;
    case AppActionType.SET_EXPLORER_DATA:
      draft.explorerData = (action as types.SetExplorerData).explorerData;
      break;
    case AppActionType.SET_EXPLORER_TOTAL_DATA:
      draft.explorerTotalData = (
        action as types.SetExplorerTotalData
      ).explorerTotalData;
      break;
    case AppActionType.SET_ALL_TAGS_FOR_METRICS:
      draft.allTagsForMetrics = (
        action as types.SetAllTagsForMetrics
      ).allTagsForMetrics;
      break;
    case AppActionType.SET_UNINSTALL_INTEGRATION_MESSAGE:
    case AppActionType.SET_INSTALL_INTEGRATION_MESSAGE:
      draft.installMessage = (
        action as types.SetInstallIntegrationMessage
      ).installMessage;
      break;
    case AppActionType.SET_UPDATE_INTEGRATION_MESSAGE:
      draft.updateIntegrationMessage = (
        action as types.SetUpdateIntegrationMessage
      ).updateIntegrationMessage;
      break;
    case AppActionType.SET_INSTALLED_INTEGRATIONS:
      draft.installedIntegrations = (
        action as types.SetInstalledIntegrations
      ).installedIntegrations;
      break;
    case AppActionType.SET_IS_ONBOARDING_INSTALL:
      draft.isOnboardingInstall = (
        action as types.SetIsOnboardingInstall
      ).isOnboardingInstall;
      break;
    case AppActionType.SET_SLACK_CHANNELS:
      draft.channels = (action as types.SetSlackChannels).channels;
      break;
    case AppActionType.SET_USERS_IN_ORG:
      draft.orgUsers = (action as types.SetOrgUsers).users;
      break;
    case AppActionType.SET_INTEGRATION:
      draft.integration = (action as types.SetIntegrationData).params;
      break;
    case AppActionType.HOMEPAGE_FETCH_START:
      draft.homepageLoading = true;
      break;
    case AppActionType.HOMEPAGE_FETCH_SUCCESS:
      draft.homepageLoading = false;
      draft.error = null;
      break;
    case AppActionType.HOMEPAGE_FETCH_FAILURE:
      const { error: errorHome } = action as types.FetchAppFailure;
      draft.homepageLoading = false;
      draft.error = errorHome;
      break;
    case AppActionType.SET_HOMEPAGE_DATA:
      const { homepageData } = action as types.SetHomepageData;
      homepageData.forEach((query) => {
        if (query.data.length === 0) {
          query.data.push({ everything: 0 });
        }
        switch (query.id) {
          case "orders.gross_sales":
            draft.homepage_orders_gross_sales = query.data[0].everything;
            break;
          default:
            break;
        }
      });
      break;
    case AppActionType.SET_OPEN_ISSUES:
      const { openIssues } = action as types.SetOpenIssues;
      draft.open_issues = openIssues;
      break;
    case AppActionType.SET_RESOLVED_ISSUES:
      const { resolvedIssues } = action as types.SetResolvedIssues;
      draft.resolved_issues = resolvedIssues;
      break;
    case AppActionType.SET_SCANNERS:
      const { scanners } = action as types.SetScanners;
      draft.scanners = scanners;
      break;
    case AppActionType.SET_TRACKED_ITEMS_COUNT:
      const { trackedItemsCount } = action as types.SetTrackedItemsCount;
      draft.tracked_items_count = trackedItemsCount;
      break;
    case AppActionType.SET_NOTIFICATIONS:
      const { notifications } = action as types.SetNotifications;
      draft.notifications = notifications;
      break;
    case AppActionType.SET_UPDATE_NOTIFICATIONS_MESSAGE:
      draft.updateNotificationsMessage = (
        action as types.SetUpdateNotificationsMessage
      ).updateNotificationsMessage;
      break;
    case AppActionType.SET_CUSTOM_SCANNERS:
      const { customScanners } = action as types.SetCustomScanners;
      draft.customScanners = customScanners;
      break;
    default:
      return draft;
  }
}, INITIAL_STATE);
