import React, { useState, useEffect } from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import TodayIcon from "@mui/icons-material/Today";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import styled from "styled-components";
import { TIMEFRAMES } from "constants/index";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";

const Container = styled.div`
  width: auto;
  height: 44px;
  display: flex;
  padding: 0;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  border: 1px solid #656576;
  border-radius: 5px;
  padding: 1px;
  margin-right: 10px;
  position: relative;
`;
const SelectContainer = styled.div`
  width: auto;
  height: 44px;
  display: flex;
  padding: 0;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  border-right: 1px solid #656576;
`;

const DateContainer = styled.div`
  width: auto;
  height: 44px;
  display: flex;
  padding: 0;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  z-index: 101;
`;

const Label = styled.span`
  font-size: 13px;
  color: #656576;
  margin-left: 5px;
  margin-right: 2px;
`;

const styles = () =>
  createStyles({
    formControl: {
      display: "flex",
      width: "auto",
      borderColor: "transparent !important",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px",
      },
      "& .MuiOutlinedInput-root.MuiInputBase-root": {
        height: "44px",
      },
    },
    select: {
      "& .MuiNativeSelect-icon": {
        color: "#656576",
      },
      "& .MuiNativeSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
        color: "#656576",
        paddingLeft: "6px",
        fontSize: "14px",
        fontFamily: "Work Sans",
        fontWeight: "500",
        letterSpacing: "-0.02em",
      },
      "& .MuiNativeSelect-select": {
        height: "44px",
      },
    },
  });

export interface SelectDateProps extends WithStyles<typeof styles> {
  select: any;
  start?: any;
  end?: any;
  onChange: (event: any, startDate?, endDate?) => void;
  onCustomDateChange?: (startDate, endDate) => void;
}

const SelectDateComponent = ({
  select,
  start,
  end,
  onChange,
  onCustomDateChange,
  classes,
}: SelectDateProps) => {
  const to = new Date();
  const [from, setFrom] = useState<Date>(to);
  const [focusedInput, setFocusedInput] = useState<any>("startDate");

  const formatDate = (val) => {
    switch (select) {
      case "-15m":
      case "-1h":
        return `${val.getHours()}:${
          (val.getMinutes() < 10 ? "0" : "") + val.getMinutes()
        }`;
      case "-6h":
        return `${val.getHours()}:${
          (val.getMinutes() < 10 ? "0" : "") + val.getMinutes()
        }`;
      case "-1y":
        return `${val.getMonth() + 1}/${val.getDate()}/${val.getFullYear()}`;
      default:
        return `${val.getMonth() + 1}/${val.getDate()}`;
    }
  };

  const calculateDate = () => {
    let temp = to;
    switch (select) {
      case "-1d":
        temp = new Date(temp.setDate(to.getDate() - 1));
        break;
      case "-1w":
        temp = new Date(temp.setDate(to.getDate() - 6));
        break;
      case "-1mo":
        temp = new Date(temp.setDate(to.getDate() - 29));
        break;
      case "-1y":
        temp = new Date(temp.setFullYear(to.getFullYear() - 1));
        break;
      case "curr_1d":
        temp = new Date(temp.setDate(to.getDate()));
        break;
      case "yd":
        temp = new Date(temp.setDate(to.getDate() - 1));
        break;
      case "curr_1w":
        temp = new Date(temp.setDate(to.getDate() - to.getDay() + 1));
        break;
      case "curr_1mo":
        temp = new Date(to.getFullYear(), to.getMonth(), 1);
        break;
      case "curr_1y":
        temp = new Date(to.getFullYear(), 0, 1);
        break;
      default:
        break;
    }
    setFrom(temp);
  };

  useEffect(() => {
    calculateDate();
  }, [select]);

  const handleDatesChange = ({ startDate, endDate }) => {
    if (onCustomDateChange) {
      if (!startDate || !endDate) return;
      const newStart = moment(startDate.set({ hour: 0, minute: 0, second: 0 }));
      const newEnd = moment(endDate.set({ hour: 23, minute: 59, second: 59 }));
      onCustomDateChange(newStart, newEnd);
    }
  };

  return (
    <Container>
      <SelectContainer>
        <FormControl fullWidth className={classes.formControl}>
          <Select
            native
            value={select}
            onChange={onChange}
            className={classes.select}
          >
            {TIMEFRAMES.map((option) => (
              <option value={option.value} key={option.key}>
                {option.label}
              </option>
            ))}
          </Select>
        </FormControl>
      </SelectContainer>
      <DateContainer>
        <TodayIcon
          style={{ width: "16px", height: "16px", color: "#656576" }}
        />
        {select === "custom" ? (
          <DateRangePicker
            style={{ cursor: "pointer" }}
            startDate={start}
            startDateId="tata-start-date"
            endDate={end}
            endDateId="tata-end-date"
            onDatesChange={({ startDate, endDate }) => {
              handleDatesChange({ startDate, endDate });
            }}
            focusedInput={focusedInput}
            onFocusChange={(focused) => {
              setFocusedInput(focused);
            }}
            small
            isOutsideRange={(day) => day.isAfter(moment())}
            minimumNights={0}
          />
        ) : (
          <Label>
            {formatDate(from)}-{formatDate(to)}
          </Label>
        )}
      </DateContainer>
    </Container>
  );
};

const SelectDate = withStyles(styles)(SelectDateComponent);

export { SelectDate };
