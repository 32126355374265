import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Container,
  makeStyles,
  InputBase,
} from "@material-ui/core";
import { connect } from "react-redux";
import { MuiButton } from "components";
import randomstring from "randomstring";
import { OnboardIntegrationButton } from "components/atoms/buttons/OnboardIntegrationButton";

const nonce = randomstring.generate();

const useStyles = makeStyles(() => ({
  cssLabel: {
    color: "gray",
  },
  inputButton: {
    "& .MuiInputBase-root": {
      borderTopRightRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
    width: "60%",
  },
  placeholderText: {
    width: "30%",
    background: "#d6d2d2",
    paddingLeft: 2.5,
    color: "black !important",
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  onboardContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "0px",
    paddingBottom: "10px",
    paddingTop: "10px",
  },
}));

const ShopifyFormBase = ({
  onSuccess,
  onFailure,
  isOnboarding,
  disabled,
}: ShopifyFormBaseProps) => {
  const [storeName, setStoreName] = useState("");
  const [oauthUrl, setOauthUrl] = useState("");

  const classes = useStyles();

  useEffect(() => {
    const regexTest = /[a-zA-Z0-9][a-zA-Z0-9]*\.myshopify\.com/.test(storeName);
    const prefix = regexTest ? storeName : storeName.concat(".myshopify.com");
    setOauthUrl(
      `https://${prefix}/admin/oauth/authorize?client_id=aaf43bbfdcc8897070959d21ea6af461&scope=read_all_orders,read_orders,read_checkouts,read_content,read_discounts,read_inventory,read_locations,read_price_rules,read_products,read_product_listings,read_script_tags,read_themes&redirect_uri=https://app.trackstarhq.com/integrations/shopify&state=${nonce}`
    );
  }, [storeName]);

  const polling = (popup) => {
    const poll = setInterval(() => {
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(poll);
        onFailure("Popup has been closed by user");
      }

      const closeDialog = () => {
        clearInterval(poll);
        popup.close();
      };

      try {
        if (!popup.location.hostname.includes("shopify")) {
          if (popup.location.search) {
            const query = new URLSearchParams(popup.location.search);
            const authCode = query.get("code");
            const nonceResp = query.get("state");
            const shop = query.get("shop");
            const qs = query.toString();
            let regexTest = true;
            if (shop) {
              regexTest = /[a-zA-Z0-9][a-zA-Z0-9]*\.myshopify\.com/.test(shop);
            }
            closeDialog();
            // TODO READD REGEX CHECK
            console.log(regexTest);
            if (authCode && nonce === nonceResp) {
              onSuccess({ shop, authCode, qs });
            } else if (onFailure) {
              onFailure(query.get("error"));
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 500);
  };

  const handleClick = () => {
    polling(window.open(oauthUrl, "_blank"));
  };

  const onChange = ({ target: { value } }) => setStoreName(value);

  if (isOnboarding) {
    return (
      <Container className={classes.onboardContainer}>
        <Container style={{ padding: 0, display: "flex", gap: "0px" }}>
          <TextField
            id="outlined-basic"
            label="store-name"
            variant="outlined"
            onChange={onChange}
            value={storeName}
            size="small"
            className={classes.inputButton}
            InputLabelProps={{ classes: { root: classes.cssLabel } }}
          />
          <InputBase
            className={classes.placeholderText}
            placeholder=".myshopify.com"
            disabled
          />
        </Container>
        <OnboardIntegrationButton
          onClick={handleClick}
          disabled={disabled || storeName === ""}
        />
      </Container>
    );
  }
  return (
    <Box
      justifyContent="flex-start"
      flexDirection="row"
      alignItems="flex-end"
      display="flex"
      marginBottom={4}
    >
      <TextField
        required
        margin="dense"
        id="store_name"
        label="Store Name"
        type="standard"
        size="small"
        onChange={({ target: { value } }) => setStoreName(value)}
        value={storeName}
        style={{ marginRight: 10 }}
      />
      <MuiButton
        onClick={handleClick}
        variant="main"
        disabled={storeName === ""}
      >
        Login with Shopify
      </MuiButton>
    </Box>
  );
};

interface ShopifyFormBaseProps {
  onSuccess: (response: any) => void;
  onFailure: (response: any) => void;
  isOnboarding?: boolean;
  disabled?: boolean;
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export const ShopifyForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopifyFormBase);
