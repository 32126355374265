import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Navigation } from "components";
import {
  Explorer,
  Integrations,
  Integration,
  IssuesPage,
  LoginAsUser,
} from "pages";
import { theme } from "config/theme";

const useStyles = makeStyles(() => ({
  content: {
    flexGrow: 1,
    marginLeft: "160px",
    height: "auto",
    backgroundColor: theme.colors.backgroundColor,
  },
}));

const CustomRouter = React.memo(() => {
  const classes = useStyles();
  return (
    <BrowserRouter>
      <Navigation />
      <main className={classes.content}>
        <Routes>
          <Route path="/explorer" element={<Explorer />} />
          <Route path="/integrations">
            <Route index element={<Integrations />} />
            <Route path=":integrationName" element={<Integration />} />
          </Route>
          <Route path="/support-login" element={<LoginAsUser />} />
          <Route path="/issues" element={<IssuesPage />} />
          <Route path="/" element={<IssuesPage />} />
        </Routes>
      </main>
    </BrowserRouter>
  );
});
CustomRouter.displayName = "Router";
export { CustomRouter };
