import React from "react";
import { Typography } from "@material-ui/core";
import styled, { css } from "styled-components";

type TypographyProps = React.ComponentProps<typeof Typography>;

const baseFontStyle = css`
  font-family: Work Sans;
  font-style: normal;
  color: ${(props) => props.theme.colors.dark};
`;

export interface TextH2Props {
  children?: React.ReactNode;
}
export const TextH2 = ({ ...props }: TypographyProps) => (
  <Typography variant="h2" {...props} />
);

export interface TextBodyProps {
  children?: React.ReactNode;
}
export const TextBody = ({ ...props }: TypographyProps) => (
  <Typography variant="body1" {...props} />
);
export const TextBody2 = ({ ...props }: TypographyProps) => (
  <Typography variant="body2" {...props} />
);

export interface TextNumeralsProps {
  children?: React.ReactNode;
  color?: React.ReactNode;
}
export const TextNumerals = styled.div`
  ${baseFontStyle}
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${(props) => props.color};
`;

// TODO responsive font sizes
export const TextHighlight = styled.div`
  ${baseFontStyle}
  font-weight: 600;
  font-size: 35px;
  line-height: 25px;
  color: ${(props) => props.theme.colors.dark};
`;

export const TextSmallHeader = styled.div`
  ${baseFontStyle}
  color: #656576 !important;
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
`;
