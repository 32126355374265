import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIcon } from "components";
import { INTEGRATIONS } from "constants/index";
import ClearIcon from "@mui/icons-material/Clear";
import { getTitle } from "utils";
import { useNavigate } from "react-router-dom";
import ExtensionIcon from "@mui/icons-material/Extension";

interface IssuesAddMoreIntegrationsProps {
  installedIntegrations: string[];
  onSetConnectMoreAppsVisible: () => void;
}

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#BFE4FC",
    border: "1px solid #E3E3E3",
    borderRadius: "5px",
    width: "100%",
    maxWidth: "none",
    marginBottom: "20px",
  },
  integrationContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: "65px",
    width: "48%",
    border: "1px solid #E3E3E3",
    borderRadius: "5px",
    gap: "1em",
    background: "white",
    margin: "0px",
    maxWidth: "none",
  },
  ctaText: {
    cursor: "pointer",
    color: "#177EC2",
    fontWeight: 600,
    fontSize: "14px",
  },
}));

export const IssuesAddMoreIntegrations = ({
  installedIntegrations,
  onSetConnectMoreAppsVisible,
}: IssuesAddMoreIntegrationsProps) => {
  const classes = useStyles();
  const uninstalledIntegrations = INTEGRATIONS.sort().filter(
    (integration) => !installedIntegrations.includes(integration)
  );
  const navigate = useNavigate();

  return (
    <Container className={classes.container}>
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          maxWidth: "none",
          padding: "1em",
        }}
      >
        <Container
          style={{
            display: "flex",
            paddingLeft: "0px",
            alignItems: "center",
            gap: "4px",
            maxWidth: "none",
          }}
        >
          <ExtensionIcon style={{ color: "177EC2" }} />
          <Typography variant="h2" style={{ color: "#177EC2" }}>
            Connect more integrations
          </Typography>
        </Container>
        <ClearIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            onSetConnectMoreAppsVisible();
          }}
        />
      </Container>
      <Typography style={{ marginRight: "auto", paddingLeft: "1em" }}>
        The more you connect, the more issues you can track.
      </Typography>
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "1em",
          gap: "1em",
          flexWrap: "wrap",
          maxWidth: "none",
        }}
      >
        {uninstalledIntegrations.map((integration) => (
          <Container className={classes.integrationContainer} key={integration}>
            <Container
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1em",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <SvgIcon name={integration} size={24} />
              <Typography>{getTitle(integration)}</Typography>
            </Container>
            <Container
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                gap: "1em",
              }}
            >
              <Typography
                onClick={() => {
                  navigate(`/integrations/${integration}`);
                }}
                className={classes.ctaText}
              >
                Connect
              </Typography>
            </Container>
          </Container>
        ))}
      </Container>
    </Container>
  );
};
