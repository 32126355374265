import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "styled-components";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";

import { theme, muiTheme } from "config/theme";
import { AnalyticsBrowser } from "@segment/analytics-next";
import App from "./App";
import configureStore from "./configureStore";
import "./index.css";

const store = configureStore({
  /* provide initial state if any */
});

export const AnalyticsContext = React.createContext<any>(undefined!);
type Props = {
  children: React.ReactNode;
};
export const AnalyticsProvider = ({ children }: Props) => {
  const supportUser = localStorage.getItem("supportUser");
  const analyticsInitState = supportUser
    ? {
        track: (event: string, properties: any) => {
          console.log("Support user, not tracking", event, properties);
        },
        identify: (userId: string, traits: any) => {
          console.log("Support user, not making identify call", userId, traits);
        },
        group: (groupId: string, traits: any) => {
          console.log("Support user, not making gropu call", groupId, traits);
        },
        page: (pageName: string) => {
          console.log("Support user not making page call", pageName);
        },
      }
    : AnalyticsBrowser.load({
        writeKey: process.env.REACT_APP_SEGMENTS_WRITE_KEY || "",
      });
  const [analytics, setAnalytics] = useState(analyticsInitState);
  return (
    <AnalyticsContext.Provider
      value={{
        analytics,
        updateAnalytics: (analyticsWrapped: any) => {
          setAnalytics(analyticsWrapped);
        },
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

const root = createRoot(document.getElementById("root")!);
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
  >
    <MuiThemeProvider theme={muiTheme}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <AnalyticsProvider>
            <App />
          </AnalyticsProvider>
        </Provider>
      </ThemeProvider>
    </MuiThemeProvider>
  </Auth0Provider>
);
