import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  ExplorerViewType,
  PostExplorerViewRequestParams,
  PutExplorerViewRequestParams,
} from "types";
import { postExplorerView, putExplorerView } from "store/app/actions";
import { AnalyticsContext } from "index";

const styles = makeStyles(() => ({
  button: {
    height: "44px",
    borderRadius: "5px !important",
    marginRight: "3px !important",
    backgroundColor: "#177EC2".concat(" !important"),
    "&:disabled": {
      backgroundColor: "#177ec252 !important",
      color: "white !important",
    },
  },
}));

const SaveExplorerComponent = ({
  selectedMetrics,
  selectedAttributes,
  selectedFilters,
  onPostExplorerView,
  onPutExplorerView,
  explorerViews,
  selectedView,
  startTime,
  endTime,
  timeframe,
  timeBreakdown,
  gridApi,
  disabled,
}: SaveExplorerComponentProps) => {
  const [open, setOpen] = React.useState(false);
  const [isExistingViewName, setIsExistingViewName] = React.useState(true);
  const [viewName, setViewName] = React.useState("");
  const { analytics } = useContext(AnalyticsContext);
  const classes = styles();

  useEffect(() => {
    const matchedFilter = explorerViews.filter(
      (view) => view.id === selectedView
    )[0];
    const newDefaultName = matchedFilter ? matchedFilter.name : "";
    setViewName(newDefaultName);
  }, [JSON.stringify(explorerViews), selectedView]);

  useEffect(() => {
    const savedExplorerNames = explorerViews.map((view) => view.name);
    if (savedExplorerNames.includes(viewName)) {
      setIsExistingViewName(true);
    } else {
      setIsExistingViewName(false);
    }
  }, [viewName]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSaveNameChange = (event) => {
    setViewName(event.target.value);
  };

  const handleSave = () => {
    analytics.track("Explorer View Saved");
    // TODO: add more items to gridState
    // TODO: store gridState in local state
    const gridState = gridApi.current.exportState();
    // ensure we don't save quickFilter state
    gridState.filter.filterModel.quickFilterValues = [];

    const params: PostExplorerViewRequestParams = {
      name: viewName,
      selected_attributes: selectedAttributes,
      selected_metrics: JSON.stringify(selectedMetrics),
      selected_filters: selectedFilters,
      grid_state: JSON.stringify(gridState) || "",
      start_time: startTime,
      end_time: endTime,
      timeframe,
      time_breakdown: timeBreakdown,
    };
    onPostExplorerView(params);
    setOpen(false);
  };

  const handleUpdate = () => {
    analytics.track("Explorer View Updated", {
      viewName,
    });
    // TODO: same as lines 75/76
    const gridState = gridApi.current.exportState();
    // ensure we don't save quickFilter state
    gridState.filter.filterModel.quickFilterValues = [];

    const params: PutExplorerViewRequestParams = {
      id: selectedView,
      name: viewName,
      selected_attributes: selectedAttributes,
      selected_metrics: JSON.stringify(selectedMetrics),
      selected_filters: selectedFilters,
      grid_state: JSON.stringify(gridState) || "",
      start_time: startTime,
      end_time: endTime,
      timeframe,
      time_breakdown: timeBreakdown,
    };
    onPutExplorerView(params);
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        className={classes.button}
        style={{ textTransform: "none" }}
        disabled={disabled}
      >
        Save
      </Button>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>Save View</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="View Name"
            type="email"
            fullWidth
            variant="standard"
            value={viewName}
            onChange={handleSaveNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            onClick={handleSave}
            disabled={isExistingViewName || viewName === ""}
          >
            Save New View
          </Button>
          <Button
            onClick={handleUpdate}
            disabled={!isExistingViewName || viewName === ""}
          >
            Update View
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

interface SaveExplorerComponentProps {
  selectedMetrics: string[];
  selectedAttributes: string;
  selectedFilters: string;
  onPostExplorerView: (params: PostExplorerViewRequestParams) => void;
  onPutExplorerView: (params: PutExplorerViewRequestParams) => void;
  explorerViews: ExplorerViewType[];
  selectedView: string;
  startTime: string;
  endTime: string;
  timeframe: string;
  timeBreakdown: string;
  gridApi: any;
  disabled: boolean;
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  onPostExplorerView: postExplorerView,
  onPutExplorerView: putExplorerView,
};

export const SaveExplorer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveExplorerComponent);
