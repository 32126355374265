import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  TextH2,
  CustomContainer,
  BackLink,
  TextBody,
  DeleteModal,
  TrashIconButton,
} from "components";
import Container from "@material-ui/core/Container";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core/styles";
import {
  InstallIntegrationRequestParams,
  UninstallIntegrationRequestParams,
  GetIntegrationParams,
  SetIntegrationParams,
  Organization,
  OrganizationNotificationChannel,
  UpdateIntegrationParams,
} from "types";
import {
  installIntegration,
  uninstallIntegration,
  getInstalledIntegrations,
  getIntegration,
  setInstallIntegrationMessage,
  getSlackChannels,
  updateIntegration,
  setIsOnboardingInstall,
} from "store/app/actions";
import { setOrganizationNotificationChannel } from "store/auth/actions";
import { connect } from "react-redux";
import {
  selectIntegrationInstallMessage,
  selectIntegration,
  selectIntegrationLoading,
  selectInstallingIntegration,
  selectChannels,
} from "store/app/selectors";
import { DataGridPro, GridColDef, useGridApiRef } from "@mui/x-data-grid-pro";
import { getTitle, getDescription } from "utils";
import { Switch, Typography, MenuItem } from "@material-ui/core";
import { StyledTooltip } from "components/atoms";
import {
  INTEGRATION_ISSUE_TYPE_MAP,
  ISSUE_TYPE_DESCRIPTION,
} from "constants/index";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AnalyticsContext } from "index";
import { selectOrganization } from "../../store/auth/selectors";
import { IntegrationButton } from "./components/IntegrationButton";
import { DeleteIntegrationButton } from "./components/DeleteIntegrationButton";
import { DeleteIntegrationModal } from "./components/DeleteIntegrationModal";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  topContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    marginLeft: 0,
    marginBottom: 20,
    marginTop: 20,
  },
  grid: {
    padding: "10px",
    backgroundColor: "white",
    borderRadius: "5px !important",
    width: "70%",
    display: "relative",
    float: "left",
    margin: "0",
    "& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell": {
      "&:focus": {
        outline: "none",
      },
      "&:focus-within": {
        outline: "none",
      },
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 500,
      color: "#656576",
      fontSize: "14px",
      fontFamily: "Work Sans",
      fontStyle: "normal",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none !important",
    },
  },
  noRowsContainer: {
    paddingTop: "20px",
    paddingBottom: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: "50px",
    alignItems: "center",
    height: "100%",
    borderTop: "1px solid #E5E5E5",
    flex: 1,
  },
}));

const IntegrationComponent = ({
  installMessage,
  integration,
  integrationLoading,
  installingIntegration,
  onInstallIntegration,
  onUninstallIntegration,
  onGetInstalledIntegrations,
  onGetIntegration,
  onSetInstallIntegrationMessage,
  onGetSlackChannels,
  onSetOrgNotificationChannel,
  channels,
  organization,
  onUpdateIntegration,
  onSetIsOnboardingInstall,
}: IntegrationComponentProps) => {
  const integrationName =
    useParams<{ integrationName: string }>().integrationName || "";
  const classes = useStyles();
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteIntegrationModalOpen, setDeleteIntegrationModalOpen] =
    useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [headers, setHeaders] = useState<GridColDef[]>([]);
  const [fetched, setFetched] = useState(false);
  const [channelValue, setChannelValue] = useState("");
  const { analytics } = useContext(AnalyticsContext);
  const apiRef = useGridApiRef();

  useEffect(() => {
    if (
      organization.defaultNotificationChannel &&
      organization.defaultNotificationChannel.type === "slack"
    ) {
      setChannelValue(organization.defaultNotificationChannel.value);
    }
  }, [organization]);
  useEffect(() => {
    analytics.page(`${integrationName} Integration Page`);
    onSetInstallIntegrationMessage("");
    onGetInstalledIntegrations();
    onGetIntegration({ name: integrationName as string });
    onGetSlackChannels();
  }, []);

  const isMapping = [
    "google",
    "google_analytics",
    "amazon_advertising",
    "tiktok",
  ].includes(integrationName);

  const handleToggle = (rowId, accountId, value) => {
    const updateParams = {
      accounts_to_update: {
        [accountId]: {
          enabled: value,
        },
      },
      integration_name: integrationName,
    } as UpdateIntegrationParams;
    apiRef.current.updateRows([{ id: rowId, enabled: value }]);
    const trackingString = value ? "Enabled" : "Disabled";
    analytics.track(`Integration Account ${trackingString}`, {
      integration_name: integrationName,
      account_id: accountId,
    });
    onUpdateIntegration(updateParams);
  };

  useEffect(() => {
    if (integration.data.length > 0) {
      setRows(integration.data.map((v) => ({ ...v, id: JSON.stringify(v) })));
      // map over the keys, and if key is "enabled", then render a checkbox
      const keys = Object.keys(integration.data[0]);
      const headersHolder: GridColDef[] = keys.map((key) => {
        if (key === "enabled") {
          return {
            headerName: key,
            field: key,
            renderCell: (params: any) => (
              <Switch
                color="primary"
                // eslint-disable-next-line react/destructuring-assignment
                checked={params.formattedValue === true}
                onChange={(e) => {
                  // API call plus update local state
                  handleToggle(
                    params.row.id,
                    params.row.account_id,
                    e.target.checked
                  );
                }}
              />
            ),
          };
        }
        return { field: key, headerName: key.replaceAll("_", " "), width: 200 };
      });
      if (!isMapping) {
        headersHolder.push({
          field: "delete",
          headerName: "",
          flex: 0.5,
          renderCell: (params) => (
            <TrashIconButton
              variant="small"
              onClick={() => {
                const deleteObject = JSON.parse(params.row.id);
                setSelectedAccounts([deleteObject.account_id]);
                setDeleteModalOpen(true);
              }}
            />
          ),
          align: "right" as const,
          headerAlign: "right" as const,
        });
      }
      setHeaders(headersHolder);
    } else {
      if (!fetched) {
        onGetIntegration({ name: integrationName });
      } else {
        setHeaders([]);
        setRows([]);
      }
      setFetched(true);
    }
  }, [integration.data]);

  // When install message changes, and isn't blank, fetch integration again
  useEffect(() => {
    if (installMessage && installMessage !== "") {
      onGetIntegration({ name: integrationName });
    }
  }, [installMessage]);

  const handleCancel = () => {
    setDeleteModalOpen(false);
    setDeleteIntegrationModalOpen(false);
    setSelectedAccounts([]);
  };

  const onDelete = () => {
    let params;
    if (!isMapping) {
      params = {
        data: {
          integration_name: integrationName,
          account_id: selectedAccounts[0],
        },
      };
      setDeleteModalOpen(false);
    } else {
      params = {
        data: {
          integration_name: integrationName,
          user_ids: selectedAccounts,
        },
      };
      setDeleteIntegrationModalOpen(false);
    }
    analytics.track("Integration Uninstalled", {
      integration: integrationName,
    });
    onUninstallIntegration(params);
    setSelectedAccounts([]);
    setRows(rows.filter((item) => !selectedAccounts.includes(item.id)));
  };

  const handleInstallIntegration = (
    params: InstallIntegrationRequestParams
  ) => {
    analytics.track("Integration Installed", {
      integration: params.data.integration_name,
    });
    onInstallIntegration(params);
    onSetIsOnboardingInstall(false);
  };

  const handleDefaultChannelChange = (e) => {
    setChannelValue(e.target.value);
    onSetOrgNotificationChannel({
      type: "slack",
      value: e.target.value,
    });
    onSetInstallIntegrationMessage(
      "success - updated default channel to ".concat(e.target.value)
    );
  };

  return (
    <Container maxWidth={false} className={classes.container}>
      <CustomContainer onClick={() => navigate("/integrations")}>
        <BackLink>Back to Integrations</BackLink>
      </CustomContainer>
      <Container className={classes.topContainer}>
        <TextH2>{getTitle(integrationName)}</TextH2>
      </Container>
      <TextBody style={{ width: "60%" }}>
        {getDescription(integrationName)}
      </TextBody>
      <br />
      {integrationName in INTEGRATION_ISSUE_TYPE_MAP && (
        <Container
          style={{
            flexDirection: "column",
            display: "flex",
            margin: "0px",
            width: "70%",
            padding: "0px",
          }}
        >
          <Typography variant="h6">Issues tracked</Typography>
          {INTEGRATION_ISSUE_TYPE_MAP[integrationName]
            .sort()
            .map((issueType) => (
              <Container
                key={issueType}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: "0px",
                  paddingLeft: "20px",
                  gap: "10px",
                }}
              >
                <Typography style={{ display: "list-item" }}>
                  {ISSUE_TYPE_DESCRIPTION[issueType].title}
                </Typography>
                <StyledTooltip
                  title={ISSUE_TYPE_DESCRIPTION[issueType].description}
                  placement="right"
                >
                  <div style={{ height: "16px", cursor: "pointer" }}>
                    <InfoOutlinedIcon sx={{ fontSize: "medium" }} />
                  </div>
                </StyledTooltip>
              </Container>
            ))}
        </Container>
      )}
      <Typography
        style={{
          fontFamily: "Work Sans",
          fontSize: 18,
          fontWeight: 500,
          letterSpacing: "-0.02em",
          paddingBottom: 5,
        }}
      >
        Connected Accounts
      </Typography>
      <DataGridPro
        apiRef={apiRef}
        className={classes.grid}
        columns={headers}
        rows={rows}
        loading={integrationLoading || installingIntegration}
        autoHeight
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        components={{
          Footer: () => (
            <Container className={classes.noRowsContainer}>
              <Typography
                style={{
                  fontFamily: "Work Sans",
                  fontSize: 16,
                  fontWeight: 500,
                  letterSpacing: "-0.02em",
                }}
              >
                Add Account:
              </Typography>
              <IntegrationButton
                name={integrationName}
                onInstallIntegration={handleInstallIntegration}
              />
              {isMapping && (
                <DeleteIntegrationButton
                  accounts={rows}
                  onClick={setDeleteIntegrationModalOpen}
                />
              )}
            </Container>
          ),
          NoRowsOverlay: () => (
            <Container
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography>No accounts connected yet</Typography>
            </Container>
          ),
        }}
      />
      {integrationName === "slack" && (
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <TextBody> Select Default Channel </TextBody>
          <Select
            value={channelValue}
            onChange={(val) => handleDefaultChannelChange(val)}
            variant="outlined"
          >
            {channels.map((val) => (
              <MenuItem key={val} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>
        </Container>
      )}
      <DeleteModal
        open={deleteModalOpen}
        title="Uninstall Integration"
        handleCancel={handleCancel}
        handleClose={onDelete}
      />
      <DeleteIntegrationModal
        open={deleteIntegrationModalOpen}
        selectedAccounts={selectedAccounts}
        setSelectedAccounts={setSelectedAccounts}
        accounts={rows}
        handleCancel={handleCancel}
        handleClose={onDelete}
      />
    </Container>
  );
};

interface IntegrationComponentProps {
  installMessage?: string;
  integration: SetIntegrationParams;
  integrationLoading: boolean;
  installingIntegration: boolean;
  channels: string[];
  organization: Organization;
  onInstallIntegration: (params: InstallIntegrationRequestParams) => void;
  onUninstallIntegration: (params: UninstallIntegrationRequestParams) => void;
  onGetInstalledIntegrations: () => void;
  onGetIntegration: (parms: GetIntegrationParams) => void;
  onSetInstallIntegrationMessage: (message: string) => void;
  onGetSlackChannels: () => void;
  onSetOrgNotificationChannel: (
    params: OrganizationNotificationChannel
  ) => void;
  onUpdateIntegration: (params: UpdateIntegrationParams) => void;
  onSetIsOnboardingInstall: (isOnboardingInstall: boolean) => void;
}

const mapStateToProps = (state) => ({
  installMessage: selectIntegrationInstallMessage(state),
  integration: selectIntegration(state),
  integrationLoading: selectIntegrationLoading(state),
  installingIntegration: selectInstallingIntegration(state),
  channels: selectChannels(state),
  organization: selectOrganization(state),
});

const mapDispatchToProps = {
  onInstallIntegration: installIntegration,
  onUninstallIntegration: uninstallIntegration,
  onGetInstalledIntegrations: getInstalledIntegrations,
  onGetIntegration: getIntegration,
  onSetInstallIntegrationMessage: setInstallIntegrationMessage,
  onGetSlackChannels: getSlackChannels,
  onSetOrgNotificationChannel: setOrganizationNotificationChannel,
  onUpdateIntegration: updateIntegration,
  onSetIsOnboardingInstall: setIsOnboardingInstall,
};

export const Integration = connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrationComponent);
