import axios from "axios";
import * as types from "types";

const ApiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const getExplorerViews = () => axios.get(`${ApiEndpoint}/explorerViews`);
export const postExplorerView = (params: types.PostExplorerViewRequestParams) =>
  axios.post<types.ExplorerViewType>(`${ApiEndpoint}/explorerViews`, params);
export const putExplorerView = (params: types.PutExplorerViewRequestParams) =>
  axios.put<types.ExplorerViewType>(
    `${ApiEndpoint}/explorerViews/${params.id}`,
    params
  );
export const deleteExplorerView = (
  params: types.DeleteExplorerViewRequestParams
) => {
  axios.delete(`${ApiEndpoint}/explorerViews/${params.id}`);
};

export const getOrganization = () =>
  axios.get<types.Organization>(`${ApiEndpoint}/org`);

export const setOrganizationTimezone = (timezone: string) =>
  axios.put<types.Organization>(`${ApiEndpoint}/org`, { timezone });

export const setOrganizationNotificationChannel = (
  defaultNotificationChannel: types.OrganizationNotificationChannel
) =>
  axios.put<types.Organization>(`${ApiEndpoint}/org`, {
    defaultNotificationChannel,
  });

export const getUsersInOrg = () =>
  axios.get(`${ApiEndpoint}/org/getUsersForOrg`);

export const getAvailableMetrics = () =>
  axios.get(`${ApiEndpoint}/kuiper/get_available_metrics`);

export const getAllTagsForMetrics = (
  params: types.GetAllTagsForMetricsRequestParams
) => axios.post(`${ApiEndpoint}/kuiper/batch_get_tags_for_metrics`, params);

// TODO - This is a post request, but it seems like it should be a GET request -JS
export const postBatchQuery = (params: types.PostBatchQueryRequestParams) =>
  axios.post(`${ApiEndpoint}/kuiper/batch_query`, params);

export const postAmazonOauthState = (
  params: types.PostAmazonOauthStateRequestParams
) =>
  axios.post(`${process.env.REACT_APP_AMAZON_SERVICE_URI}/oauth_state`, params);

export const installIntegration = (
  params: types.InstallIntegrationRequestParams
) => axios.post(`${ApiEndpoint}/integration/install`, params);

export const uninstallIntegration = (
  params: types.UninstallIntegrationRequestParams
) => axios.post(`${ApiEndpoint}/integration/uninstall`, params);

export const getInstalledIntegrations = () =>
  axios.get<types.InstalledIntegrationsResponseType>(
    `${ApiEndpoint}/integration/installed_list`
  );

export const updateIntegration = (params: types.UpdateIntegrationParams) =>
  axios.post(`${ApiEndpoint}/integration/update_integration`, params);

export const getSlackChannels = () =>
  axios.get(`${ApiEndpoint}/integration/slack/list_channels`);

export const getIntegration = (params: types.GetIntegrationParams) =>
  axios.get(`${ApiEndpoint}/integration/get?integration_name=${params.name}`);

export const getIssues = (filters) => {
  // iterate through filters and add them to the query string
  const baseURL = `${ApiEndpoint}/webb/issues`;
  const queryString =
    Object.keys(filters).length > 0
      ? `?${Object.keys(filters)
          .map((key) => `${key}=${filters[key]}`)
          .join("&")}`
      : "";
  return axios.get(`${baseURL}${queryString}`);
};

export const getScanners = () => axios.get(`${ApiEndpoint}/webb/scanners`);

export const getTrackedItemsCount = () =>
  axios.get(`${ApiEndpoint}/saturn/items_tracked`);

export const toggleDismissIssue = (
  params: types.ToggleDismissIssueRequestParams
) => axios.post(`${ApiEndpoint}/webb/toggle_dismiss_issue/${params.id}`);

export const updateUserActions = (
  params: types.UpdateUserActionsRequestParams
) => axios.post(`${ApiEndpoint}/webb/user_actions_taken`, params);

export const getNotifications = () =>
  axios.get(`${ApiEndpoint}/webb/notifications`);

export const putNotifications = (params: types.PutNotificationRequestParams) =>
  axios.put(`${ApiEndpoint}/webb/notifications`, params);

export const getCustomScanners = () =>
  axios.get(`${ApiEndpoint}/webb/custom_scanners`);

export const putCustomScanners = (scanners: types.CustomScannerType[]) =>
  axios.put(`${ApiEndpoint}/webb/custom_scanners`, scanners);

export const setConnectMoreAppsVisible = () =>
  axios.post(`${ApiEndpoint}/org/setConnectMoreAppsVisible`);
